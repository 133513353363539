import { createAction, asyncAction } from '@nike/redux-action-utils'

import { recoverExpiredState } from './state.js'
import { hasAuthCheckStarted } from './reducer.js'
import { inIframe, iframeSilentLogin, trySilentLogin } from './silentLogin.js'
import {
  login,
  logout,
  getPreviousLocation,
  getTokenFromRedirectOrStore,
  watchForTokenExpiration
} from './authorizer.js'

export const loginUser = createAction('LOGIN_USER', { noPayload: true })
export const logoutUser = createAction('LOGOUT_USER', { noPayload: true })
export const initializeUserAuth = createAction('AUTH_CHECK_INITIALIZE')

export const authCheckStart = createAction('AUTH_CHECK_START')
export const authCheckComplete = createAction('AUTH_CHECK_COMPLETE')
export const setSilentAuthCheck = createAction('AUTH_CHECK_SILENT')
export const setLoginError = createAction('LOGIN_SILENT_ERROR')
export const userLoginSuccess = createAction('USER_LOGIN_SUCCESS')
export const setAuthCheckError = createAction('AUTH_CHECK_ERROR')
export const setPreviousLocation = createAction('SET_PREVIOUS_LOCATION')

export const innerLogoutUser = asyncAction({
  baseName: 'USER_LOGOUT',
  action: action => auth => (dispatch, getState) => {
    dispatch(action.start())

    try {
      logout()

      auth.emit('logoutSuccess', dispatch, getState)

      dispatch(action.success())
    } catch (error) {
      auth.emit('logoutError', dispatch, getState)

      dispatch(action.error(error))
    }
  }
})

const finishLogin = (config, token, auth) => async (dispatch, getState) => {
  if (!token) {
    dispatch(setLoginError('Error: Unable to retrieve a token, you will be logged out.'))

    auth.emit('loginError', dispatch, getState)

    dispatch(setAuthCheckError())

    await innerLogoutUser(auth)(dispatch, getState)

    return dispatch(authCheckComplete())
  }

  watchForTokenExpiration(config, token, auth)(dispatch, getState)

  dispatch(userLoginSuccess(token))

  try {
    let previousLocation = getPreviousLocation()

    dispatch(setPreviousLocation(previousLocation))

    auth.emit('loginSuccess', dispatch, getState)

    dispatch(authCheckComplete())

    return token
  } catch (error) {
    dispatch(
      setLoginError(`Error: Unable to restore location, you will be logged out. ${error.message}`)
    )

    auth.emit('loginError', dispatch, getState)

    await innerLogoutUser(auth)(dispatch, getState)

    return dispatch(authCheckComplete())
  }
}

export const innerLoginUser = (config, auth) => async (dispatch, getState) => {
  try {
    await innerLogoutUser(auth)(dispatch, getState)

    dispatch(authCheckStart())

    await trySilentLogin(config)(getState)
      .catch(async error => {
        console.error(error)
        return await login(config)(getState)
      })
      .then(async token => {
        return token
          ? finishLogin(config, token, auth)(dispatch, getState)
          : await login(config)(getState)
      })
  } catch (error) {
    console.error(error)
    return finishLogin(config, null, auth)(dispatch, getState)
  }
}

export const innerInitializeUserAuth = (config, auth) => async (dispatch, getState) => {
  if (inIframe()) {
    return iframeSilentLogin(config)
  }

  if (hasAuthCheckStarted(getState())) {
    return Promise.resolve()
  }

  dispatch(authCheckStart())

  try {
    await recoverExpiredState()(getState)

    const token = await getTokenFromRedirectOrStore(config)
    return token
      ? finishLogin(config, token, auth)(dispatch, getState)
      : innerLoginUser(config, auth)(dispatch, getState)
  } catch (error) {
    dispatch(setLoginError(error))

    auth.emit('loginError', dispatch, getState)

    dispatch(authCheckComplete())
  }
}
