import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Scrollspy, Section, Spinner, Button, Icon } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import { FilterableSortTable } from "../components/table/index.js";
import ComplianceGraph from "./TaggingComplianceGraph";
import TaggingComplianceTagSummary from "./TaggingComplianceTagSummary.js";
import { navigate } from "raviger";

const TaggingCompliance = props => {
  const [errorMessageQuery, setErrorMessageQuery] = useState(null);
  const [incomplete, setIncomplete] = useState(null);
  const [valid, setValid] = useState(null);
  const [invalid, setInvalid] = useState(null);
  const [buckets, setBuckets] = useState([]);
  const [filter, setFilter] = useState(props.data.filter);
  const [friendlyNames, setFriendlyNames] = useState({});
  const [hasBarData, setHasBarData] = useState(false);
  const [nameLookup, setNameLookup] = useState({});
  const [message, setMessage] = useState("");
  const [requiredTags, setRequiredTags] = useState([]);
  const standard = "Latest";

  const panels = [];
  let barData = [];

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  const getRequiredTags = rules => {
    const localTags = [];
    rules.forEach(rule => {
      if (rule.required) {
        localTags.push(rule.tag_key);
      }
    });
    setRequiredTags(localTags);
  };

  useEffect(() => {
    const fetchRules = async () => {
      let response = await fetch(config.apiUrl + "/tagging/rules/" + standard, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        }
      });
      let result = await response.json();
      if (!response.ok) {
        setMessage(
          <div>
            <APIError
              title="Failure in fetching rules"
              showTitle={true}
              error={result}
            />
          </div>
        );
        return false;
      } else {
        getRequiredTags(result.rules.key_rules);
      }
    };

    fetchRules();
  }, [props.accessToken]);

  useEffect(() => {
    if (filter === "SYSTEM_ID" || typeof filter === "undefined") {
      fetch(config.apiUrl + "/ssot/systems/", {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        },
        method: "GET"
      })
        .then(response => response.json())
        .then(response => {
          var friendlyMap = {};
          for (var i in response) {
            var entry = response[i];
            friendlyMap[entry.key] = entry.name;
          }
          setFriendlyNames(friendlyMap);
        })
        .catch(error => {
          console.log(error);
          setErrorMessageQuery(error);
        });
    } else if (filter === "ACCOUNT") {
      fetch(config.apiUrl + "/ssot/reference/accessible-accounts-v2", {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        },
        method: "GET"
      })
        .then(response => response.json())
        .then(response => {
          var friendlyMap = {};
          for (var i in response) {
            var entry = response[i];
            friendlyMap[entry.accountId] =
              entry.name + " - " + entry.accountType;
          }
          setFriendlyNames(friendlyMap);
        })
        .catch(error => {
          console.log(error);
          setErrorMessageQuery(error);
        });
    }
  }, [filter, props.accessToken]);

  useEffect(() => {
    var localFilter = "";
    if (typeof filter === "undefined") {
      localFilter = "SYSTEM_ID";
    } else {
      localFilter = filter;
    }

    fetch(
      config.apiUrl + "/cloud-resources/compliance/summary/" + localFilter,
      {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        }
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setErrorMessageQuery(response);
        } else if (!response.hasOwnProperty("ValidResources")) {
          throw Error("Failure in resources API");
        } else {
          setErrorMessageQuery(null);
          const nameHash = {};
          for (const obj of response.BreakdownByFilter) {
            let name = "Undefined";
            let key = obj.Key;
            // console.log(friendlyNames);
            if (friendlyNames.hasOwnProperty(key)) {
              // console.log(key);
              name = friendlyNames[key];
              // console.log(name);
            } else if (filter === "RESOURCE_TYPE") {
              name = key
                .replace("aws_", "")
                .replace("azure_", "")
                .replace(/_/g, " ");
            } else {
              name = key;
            }
            nameHash[key] = name;
          }
          setBuckets(response.BreakdownByFilter);
          setValid(response.ValidResources);
          setInvalid(response.InvalidResources);
          setIncomplete(response.IncompleteResources);
          setNameLookup(nameHash);
          setHasBarData(true);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageQuery(error);
      });
  }, [filter, props.accessToken, friendlyNames]);

  const attributes = {
    data: buckets.map(data => ({
      Key: data.Key,
      Name: nameLookup[data.Key],
      Percentage: Math.round(parseFloat(data.CompliancePercentage)) + "%",
      Resources: data.TotalResources,
      Resources_Fail: data.InvalidResources,
      Resources_Pass: data.ValidResources
    })),
    fields: ["Key", "Name"],
    columns: [
      {
        Header: "Filter",
        accessor: "Key"
      },
      {
        Header: "Name",
        accessor: "Name"
      },
      {
        Header: "Percentage",
        accessor: "Percentage"
      },
      {
        Header: "Total Resources",
        accessor: "Resources"
      },
      {
        Header: (
          <>
            Non-Compliant Resources
            <br />
          </>
        ),
        accessor: "Resources_Fail"
      },
      {
        Header: (
          <>
            Compliant Resources
            <br />
          </>
        ),
        accessor: "Resources_Pass"
      },
      {
        Header: "Non-Compliance Details",
        id: "details",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <a
              href={
                typeof filter === "undefined"
                  ? basePath + "/compliance/SYSTEM_ID/" + row.original.Key
                  : basePath + "/compliance/" + filter + "/" + row.original.Key
              }
            >
              <Button
                onClick={
                  typeof filter === "undefined"
                    ? () =>
                        navigate(
                          basePath + "/compliance/SYSTEM_ID/" + row.original.Key
                        )
                    : () =>
                        navigate(
                          basePath +
                            "/compliance/" +
                            filter +
                            "/" +
                            row.original.Key
                        )
                }
              >
                <Icon type="walkthrough" />
              </Button>
            </a>
          </div>
        )
      }
    ]
  };

  barData = [
    {
      label: "Count",
      data: [
        ["Incomplete", incomplete],
        ["Non-Compliant", invalid],
        ["Compliant", valid]
      ]
    }
  ];

  let system_data = [];
  for (const entry in buckets.sort(function(a, b) {
    if (a.InvalidResources > b.InvalidResources) {
      return -1;
    }
    if (a.InvalidResources < b.InvalidResources) {
      return 1;
    }
    return 0;
  })) {
    if (buckets[entry].InvalidResources > 0 && system_data.length < 20) {
      system_data.push([
        nameLookup[buckets[entry].Key],
        buckets[entry].InvalidResources
      ]);
    }
  }

  let system_compliance = [
    {
      label: "Count",
      data: system_data
    }
  ];

  const showTable = () => {
    return (
      <div>
        <FilterableSortTable {...attributes} />
      </div>
    );
  };

  const handleClick = newFilter => {
    if (filter !== newFilter) {
      setFilter(newFilter);
      setBuckets([]);
      setFriendlyNames({});
      setNameLookup({});
      setHasBarData(false);
      navigate(basePath + "/compliance/" + newFilter);
    }
  };

  const generatePanels = () => {
    panels.splice(0, panels.length);

    panels.push({
      text: "Filters",
      hash: "filters",
      component: (
        <Section
          key="filters"
          id="filters"
          title="Filters"
          collapsible="true"
          collapsibleConfig={{ open: true }}
        >
          <div>
            <b>Summarize Compliance by:&nbsp;&nbsp;</b>
            <Button
              style={
                filter === "SYSTEM_ID" || typeof filter === "undefined"
                  ? { backgroundColor: "#c7c7c7", color: "#5197d6" }
                  : ""
              }
              onClick={() => handleClick("SYSTEM_ID")}
            >
              System ID
            </Button>
            <Button
              style={
                filter === "ACCOUNT"
                  ? { backgroundColor: "#c7c7c7", color: "#5197d6" }
                  : ""
              }
              onClick={() => handleClick("ACCOUNT")}
            >
              Account
            </Button>
            <Button
              style={
                filter === "RESOURCE_TYPE"
                  ? { backgroundColor: "#c7c7c7", color: "#5197d6" }
                  : ""
              }
              onClick={() => handleClick("RESOURCE_TYPE")}
            >
              Resource Type
            </Button>
            <Button
              style={
                filter === "FinOps_MAPPING.Domain"
                  ? { backgroundColor: "#c7c7c7", color: "#5197d6" }
                  : ""
              }
              onClick={() => handleClick("FinOps_MAPPING.Domain")}
            >
              Domain
            </Button>
            <Button
              style={
                filter === "FinOps_MAPPING.Subomain"
                  ? { backgroundColor: "#c7c7c7", color: "#5197d6" }
                  : ""
              }
              onClick={() => handleClick("FinOps_MAPPING.Subdomain")}
            >
              Subdomain
            </Button>
            <Button
              style={
                filter === "FinOps_MAPPING.Subdepartment "
                  ? { backgroundColor: "#c7c7c7", color: "#5197d6" }
                  : ""
              }
              onClick={() => handleClick("FinOps_MAPPING.Subdepartment")}
            >
              Subdepartment
            </Button>
            <Button
              style={
                filter === "nike-owner"
                  ? { backgroundColor: "#c7c7c7", color: "#5197d6" }
                  : ""
              }
              onClick={() => handleClick("nike-owner")}
            >
              nike-owner
            </Button>
          </div>
        </Section>
      )
    });
    panels.push({
      text: "Summary",
      hash: "summary",
      component: (
        <Section
          key="summary"
          id="summary"
          title="Summary"
          collapsible="True"
          collapsibleConfig={{ open: true }}
        >
          <div style={{ marginBottom: "1em", color: "black" }}>
            <p style={{ marginLeft: "0em" }}>
              <li>
                <b> Compliant : </b>
                The resources have all the required tags and the values are
                compliant.
              </li>
            </p>
            <p style={{ marginLeft: "0em" }}>
              <li>
                <b>Non-Compliant : </b>
                The resources have tags that do not pass validation.
              </li>
            </p>
            <p style={{ marginLeft: "0em" }}>
              <li>
                <b>Incomplete : </b> The resources are incomplete due to API
                rate limits or permissions issues.
              </li>
            </p>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {!errorMessageQuery ? (
              !hasBarData ? (
                <div style={{ margin: "auto" }}>
                  <Spinner overlay={false} show={true} />
                </div>
              ) : (
                <>
                  <ComplianceGraph
                    data={barData}
                    margin={"20px 80px 20px 20px"}
                    header={"Overall Tagging Compliance"}
                  />
                  <ComplianceGraph
                    data={system_compliance}
                    margin={"20px 30px"}
                    header={"Top 20 Items by Count of Non-Compliant Resources"}
                  />
                </>
              )
            ) : (
              <APIError showTitle={true} error={errorMessageQuery} />
            )}
          </div>
          <div style={{ margin: "50px 0px 200px" }}>
            {!errorMessageQuery ? (
              showTable()
            ) : (
              <APIError showTitle={true} error={errorMessageQuery} />
            )}
          </div>
        </Section>
      )
    });

    requiredTags.forEach(tag => {
      panels.push({
        text: `${tag} charts`,
        hash: `${tag}-charts`,
        component: (
          <TaggingComplianceTagSummary
            key={`${filter}-${tag}`}
            filter={filter}
            subFilter={tag}
            friendlyNames={friendlyNames}
            {...props}
          />
        )
      });
      panels.push({
        text: `${tag} data`,
        hash: `${tag}-data`
      });
    });
  };
  generatePanels();

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
          .epic__NextGenSelect__dea .NextGenSelect__control .NextGenSelect__indicators {
            color: #e5e5e5;
            padding: 5px;
          }
          .epic__sectionContent__947 {
            margin-top: 0;
          }
          .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
            height: 56px;
            text-align: center;
          }
          .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
            height: 56px;
            text-align: center;
          }
          .epic__container__d0a.epic__disabled__f8e {
              opacity: 1;
          }`}
      </style>
      {message}
      {!errorMessageQuery ? (
        <div className="scrollspy-container">
          <Scrollspy
            title="Tagging Compliance"
            items={panels}
            uppercaseTitle={true}
            uppercaseItems={true}
            className="scrollspy-menu"
          />
          <div className="scrollspy-content">
            {panels.map(data => {
              return data.component;
            })}
          </div>
        </div>
      ) : (
        <div>
          <h1
            className="epic-font-brand"
            style={{ marginTop: "80px", fontSize: "18px" }}
          >
            The Tagging Compliance page is currently unavailable
          </h1>
          {/* <APIError showTitle={true} error={errorMessageQuery} /> */}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(TaggingCompliance);
