import React from "react";
import { Section } from "@nike/epic-react-ui";
import { SearchFormattedData } from "./SearchFormattedData";

export const SearchDetails = props => {
  //console.log(props);
  const displayDetails = () => {
    return (
      <Section
        id={props.id}
        title="Resource Details"
        collapsible="True"
        collapsibleConfig={{ open: false }}
        align="left"
      >
        <SearchFormattedData {...props} />
      </Section>
    );
  };

  return <div>{displayDetails()}</div>;
};
