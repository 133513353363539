import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Resources,
  Alerts,
  Costing,
  WaffleDetails,
  Associations,
  ComplianceMetrics
} from "../system/SystemDetails";
import { Scrollspy, Spinner } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import { clearIndexes } from "../system/SystemActions";

const AccountScreen = props => {
  const key = props.data.account;
  const system = null;
  const [errorMessage, setErrorMessage] = useState(null);
  // const [errorMessageAlerts, setErrorMessageAlerts] = useState(null);
  const [account, setAccount] = useState(null);
  // const [maintAlerts, setMaintAlerts] = useState(null);
  const [panels] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearIndexes());
  });

  console.log(props);

  useEffect(() => {
    fetch(config.apiUrl + "/ssot/systems/account/" + key, {
      // fetch("http://127.0.0.1:8000/yo/" + key, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        // console.log(response);
        if (response.status === "fail") {
          console.log(response);
          setErrorMessage(response);
        } else {
          setErrorMessage(null);
          panels.push({
            text: "Account Details",
            hash: "accountDetails",
            component: (
              <WaffleDetails
                key="accountDetails"
                id="accountDetails"
                system={system}
                account={response}
                errorMessage="failure"
                {...props}
              />
            )
          });
          panels.push({
            text: "Account Specific Compliance",
            hash: "account_specific_compliance",
            component: (
              <ComplianceMetrics
                key="account_specific_compliance"
                id="account_specific_compliance"
                system={system}
                account={response}
                {...props}
              />
            )
          });
          panels.push({
            text: "Costing",
            hash: "costing",
            component: (
              <Costing
                key="costing"
                id="costing"
                system={system}
                account={response}
                errorMessage="failure"
                {...props}
              />
            )
          });
          panels.push({
            text: "Associated Systems",
            hash: "associations",
            component: (
              <Associations
                title="Associated Systems"
                key="associations"
                id="associations"
                system={system}
                account={response}
                {...props}
              />
            )
          });
          panels.push({
            text: "Resources",
            hash: "resources",
            component: (
              <Resources
                title="Resources"
                key="resources"
                id="resources"
                system={system}
                account={response}
                {...props}
              />
            )
          });
          panels.push({
            text: "Alerts",
            hash: "alerts",
            component: (
              <Alerts
                key="alerts"
                id="alerts"
                system={system}
                account={response}
                {...props}
              />
            )
          });
          setAccount(response);
        }
      })
      .catch(error => {
        setErrorMessage(error);
        console.log(error);
      });
  }, [key, panels, props]);

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
          .epic__NextGenSelect__dea .NextGenSelect__control .NextGenSelect__indicators {
            color: #e5e5e5;
            padding: 5px;
        }`}
      </style>
      {!account ? (
        !errorMessage ? (
          <div>
            <Spinner overlay={false} show={true} />
          </div>
        ) : (
          <APIError showTitle={true} paddingLeft="1em" error={errorMessage} />

          // <APIError error={errorMessage} />
        )
      ) : (
        <div className="scrollspy-container">
          {errorMessage}
          <Scrollspy
            title="Account Nav"
            items={panels}
            uppercaseTitle={true}
            uppercaseItems={true}
            className="scrollspy-menu"
          />
          <div className="scrollspy-content">
            {panels.map(data => {
              return data.component;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(AccountScreen);
