import React from "react";
import { Button, Section } from "@nike/epic-react-ui";
import { navigate } from "raviger";

export { Costing } from "./CollectionCosting.js";
export { Compliance } from "./CollectionCompliance.js";

export const Summary = props => {
  const key = props.collection.key;
  const name = props.collection.name;
  const owner = props.collection.owner;
  const description = props.collection.description;
  // const systems = props.collection.systems;
  const breakout = props.breakout;

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  return (
    <div>
      <Section
        id={props.id}
        title="Summary"
        subtitle={name}
        collapsible="True"
        collapsibleConfig={{ open: false }}
        align="center"
      >
        <div className="row">
          <div className="columnLeftContainer">
            <div className="columnLeft">
              <label>
                <span className="summaryLabelText">Name:</span>
                <div className="summaryText">{name}</div>
              </label>
              <label>
                <span className="summaryLabelText">Owner:</span>
                <div className="summaryText">{owner}</div>
              </label>
              <label>
                <span className="summaryLabelText">
                  Number of Applications:
                </span>
                <div className="summaryText">
                  {breakout
                    ? breakout.applications
                      ? breakout.applications.length
                      : 0
                    : 0}
                </div>
              </label>
              <label>
                <span className="summaryLabelText">
                  Number of GCP Projects:
                </span>
                <div className="summaryText">
                  {breakout
                    ? breakout.gcp_projects
                      ? breakout.gcp_projects.length
                      : 0
                    : 0}
                </div>
              </label>
              <label>
                <span className="summaryLabelText">
                  Number of Azure Subscriptions:
                </span>
                <div className="summaryText">
                  {breakout
                    ? breakout.azure_subscriptions
                      ? breakout.azure_subscriptions.length
                      : 0
                    : 0}
                </div>
              </label>
            </div>
          </div>
          <div className="columnRightContainer">
            <div className="columnRight">
              <label>
                <span className="summaryLabelText">Description:</span>
                <div className="summaryText">{description}</div>
              </label>
              <label>
                <span className="summaryLabelText">Systems Collection ID:</span>
                <div className="summaryText">{key}</div>
              </label>
              <label>
                <span className="summaryLabelText">Number of Accounts:</span>
                <div className="summaryText">
                  {breakout
                    ? breakout.accounts
                      ? breakout.accounts.length
                      : 0
                    : 0}
                </div>
              </label>
              <label>
                <span className="summaryLabelText">
                  Number of Azure Resource Groups:
                </span>
                <div className="summaryText">
                  {breakout
                    ? breakout.azure_rgs
                      ? breakout.azure_rgs.length
                      : 0
                    : 0}
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className="summaryButton">
          {key ? (
            <Button
              small
              title="Edit"
              onClick={() => navigate(basePath + "/collection/" + key)}
            >
              SC Details
            </Button>
          ) : (
            <div />
          )}
        </div>
      </Section>
    </div>
  );
};
