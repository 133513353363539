import React, { useState, useRef } from "react";
import config from "../config.js";
import { Button, TextInput, Section, Icon, Spinner } from "@nike/epic-react-ui";
import { navigate } from "raviger";

export { Systems } from "./CollectionSystems.js";
export { Settings } from "./CollectionSettings.js";

export const Details = props => {
  const key = props.data.key;
  const [admin] = useState(props.collection.is_admin);
  const [name, setName] = useState(props.collection.name);
  const [owner, setOwner] = useState(props.collection.owner);
  const [description, setDescription] = useState(props.collection.description);
  const [formStatus, setFormStatus] = useState("");
  // const [message, setMessage] = useState("");
  const transientName = useRef(props.collection.name);
  const transientDescription = useRef(props.collection.description);
  const transientOwner = useRef(props.collection.owner);
  const timerMessage = useRef(null);
  const [updateSpinner, setUpdateSpinner] = useState(false);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  const handleBlur = e => {
    if (
      transientName.current !== name ||
      transientDescription.current !== description ||
      transientOwner.current !== owner
    ) {
      setFormStatus("");
      setFormStatus(
        <div className="spinnerParent">
          <div className="spinnerChild">
            <Spinner />
          </div>
        </div>
      );
      setUpdateSpinner(true);
      clearTimeout(timerMessage.current);
      // fetch("http://127.0.0.1:8000/" + key, {
      fetch(config.apiUrl + "/ssot/ous/" + key, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache",
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
          [e.target.name]: e.target.value
        })
      })
        .then(response => response.json())
        .then(response => {
          if (!response.systems) {
            throw Error(response.message[0].message[0]);
          } else {
            setOwner(response.owner);
            transientName.current = name;
            transientDescription.current = description;
            transientOwner.current = owner;
            setUpdateSpinner(false);
            setFormStatus(
              <div className="epic-color-success system-success-message">
                <Icon className="system-info-status" type="checkmark" />
                <div>{"Change saved."}</div>
              </div>
            );
            timerMessage.current = setTimeout(() => {
              setFormStatus("");
            }, 3000);
            return () => clearTimeout(timerMessage.current);
          }
        })
        .catch(error => {
          setUpdateSpinner(false);
          setName(transientName.current);
          setDescription(transientDescription.current);
          setOwner(transientOwner.current);
          setFormStatus(
            <div className="epic-color-error system-info-message">
              <Icon className="system-info-status" type="warning" />
              <div>{error.toString()}</div>
            </div>
          );
          timerMessage.current = setTimeout(() => {
            setFormStatus("");
          }, 3000);
          return () => clearTimeout(timerMessage.current);
        });
    }
  };

  return (
    <div>
      <Section id={props.id} title="Details">
        <div>{formStatus}</div>
        <TextInput
          label="Name"
          onChange={e => (admin ? setName(e.target.value) : null)}
          onBlur={e => (admin ? handleBlur(e) : null)}
          onKeyPress={e => (e.key === "Enter" ? handleBlur(e) : null)}
          className={
            !admin
              ? "readOnlyText"
              : updateSpinner
              ? "readOnlyText"
              : "textInput"
          }
          value={name}
          name="name"
          full
          disabled={updateSpinner}
        />
        <div style={{ marginBottom: "30px" }}></div>

        <TextInput
          label="Description"
          onChange={e => (admin ? setDescription(e.target.value) : null)}
          onBlur={e => (admin ? handleBlur(e) : null)}
          onKeyPress={e => (e.key === "Enter" ? handleBlur(e) : null)}
          className={
            !admin
              ? "readOnlyText"
              : updateSpinner
              ? "readOnlyText"
              : "textInput"
          }
          value={description}
          name="description"
          full
          disabled={updateSpinner}
        />
        <div style={{ marginBottom: "30px" }}></div>

        <TextInput
          onChange={e => (admin ? setOwner(e.target.value) : null)}
          onBlur={e => (admin ? handleBlur(e) : null)}
          onKeyPress={e => (e.key === "Enter" ? handleBlur(e) : null)}
          label="Owner"
          className={
            !admin
              ? "readOnlyText"
              : updateSpinner
              ? "readOnlyText"
              : "textInput"
          }
          value={owner}
          full
          name="owner"
          disabled={updateSpinner}
        />
        <div style={{ marginBottom: "30px" }}></div>

        <TextInput
          label="Systems Collection ID"
          className="readOnlyText"
          value={key}
          full
          name="key"
          disabled={true}
        />
        <div style={{ marginBottom: "30px" }}></div>

        <Button
          small
          title="Dashboard"
          onClick={() => navigate(basePath + "/collection/dashboard/" + key)}
        >
          Go to Dashboard
        </Button>
      </Section>
    </div>
  );
};
