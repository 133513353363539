import "@nike/epic-react-ui/dist/styles.css";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRoutes } from "raviger";
import {
  initializeUserAuth,
  hasAuthCheckFinished,
  selectLoginError,
  loginUser,
  logoutUser
} from "@nike/oauth-client";
import { Button } from "@nike/epic-react-ui";
//import PlatformConsole from "./PlatformConsole.js";
import routes from "./routes";
import NotFoundPage from "./NotFoundPage";
import OfflineBanner from "../offline/OfflineBanner";
import LoginRequired from "../auth/LoginRequired";
import Spinner from "../components/Spinner";
import Error from "../components/Error";
import CloudRedNavBar from "../components/CloudRedNavBar";

const App = ({ initializeUserAuth, loginError, loginFinished, loginUser }) => {
  // eslint-disable-next-line
  useEffect(() => {
    initializeUserAuth();
  }, []);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  const routeResult = useRoutes(routes, { basePath }) || <NotFoundPage />;

  if (loginError) {
    return (
      <div>
        <Error error={loginError} />
        <Button onClick={loginUser}>Login</Button>
      </div>
    );
  }

  if (!loginFinished) {
    return <Spinner large />;
  }

  /*
  return (
    <div>
      <OfflineBanner />
      <div>
        <PlatformConsole>
          <CloudRedNavBar />
          <LoginRequired>
            <div>{routeResult}</div>
          </LoginRequired>
        </PlatformConsole>
      </div>
    </div>
  );
  */

  return (
    <div>
      <OfflineBanner />
      <div>
        <CloudRedNavBar />
        <LoginRequired>
          <div>{routeResult}</div>
        </LoginRequired>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    loginFinished: hasAuthCheckFinished(state),
    loginError: selectLoginError(state)
  }),
  { initializeUserAuth, loginUser, logoutUser }
)(App);
