import React, { useState, useEffect } from "react";
import config from "../config.js";
import { SortableTable, StaticTable } from "../components/table/index.js";
import {
  TextInput,
  Section,
  Collapsible,
  Icon,
  Button,
  Modal,
  Select,
  Spinner,
  Tooltip,
  ToggleSwitch
} from "@nike/epic-react-ui";
//import APIError from "../components/APIError.js";
import { boolean } from "./SystemData.js";

export const Tags = props => {
  //console.log(props);
  const systemid = props.data.key;
  const account = props.account;
  const [isLoading] = useState(false);
  let tagData = [];
  const [showOverlay, setShowOverlay] = useState(false);
  const [message, setMessage] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [admin] = useState(props.system.is_admin);
  const [systemState] = useState(props.system.state);
  const [systemUpdated, setSystemUpdated] = useState(props.system.date_updated);
  const [addTagModal, setAddTagModal] = useState(false);
  const [editTagModal, setEditTagModal] = useState(false);
  const [moveModal, setMoveModal] = useState(false);
  const [tagKey, setTagKey] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [standard] = useState({
    value: "Latest",
    label: "Latest"
  });
  const [rules, setRules] = useState([]);
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [deleteSpinner, setDeleteSpinner] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  const [altenabled, setalt] = useState(false);
  const [tagOverwrite, setTagOverwrite] = useState(false);
  const tags = props.tags;
  const setTags = props.setTags;
  const metadata = props.metadata;
  const setMetadata = props.setMetadata;
  const metadataConfig = props.metadataConfig;
  const setMetadataConfig = props.setMetadataConfig;

  // set boolean value for use in selective rendering to see if system is editable
  var editable = false;
  if (admin && systemState === "active") {
    editable = true;
  }

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    applyStandard();
  }, [tags.length, systemUpdated, props.accessToken]);

  const createNewTag = e => {
    const sendTags = { ...tags };
    sendTags[tagKey] = typeof tagValue !== "object" ? tagValue : tagValue.value;
    updateTags(sendTags);
  };

  const deleteTag = key => {
    setDeleteSpinner([key]);
    const sendTags = { ...tags };
    delete sendTags[key];
    updateTags(sendTags);
  };

  const editTag = key => {
    setTagKey(key);
    setTagValue(tags[key]);
    setEditTagModal(true);
  };

  const getTagDesc = (tagKey, tagValue) => {
    let rule = null;
    if (tagKey in rules) {
      rule = rules[tagKey];
      if ("option_list" in rule) {
        for (var i = 0; i < rule["option_list"].length; i++) {
          if (rule["option_list"][i].id === tagValue) {
            return rule["option_list"][i].value;
          }
        }
      }
    }
    return "";
  };

  const moveTag = () => {
    const sendMetadata = { ...metadata };
    sendMetadata[tagKey] = tagValue;
    const sendMetadataConfig = { ...metadataConfig };
    sendMetadataConfig[tagKey] = { category: "General", visible: false };
    if (tagKey in metadata) {
      setMoveModal(false);
      setFormStatus(
        <div
          className="epic-color-error system-info-message"
          style={{ top: "40%", width: "300px" }}
        >
          <Icon className="system-info-status" type="warning" />
          <div>Key already exists in metadata</div>
        </div>
      );
      setTimeout(() => {
        setFormStatus("");
      }, 4000);
      return;
    }

    updateMetadata(sendMetadata, sendMetadataConfig);
  };

  const moveTagModal = key => {
    setTagKey(key);
    setTagValue(tags[key]);
    setMoveModal(true);
  };

  // add metadata and remove tag
  const updateMetadata = (sendMetadata, sendMetadataConfig) => {
    setUpdateSpinner(true);
    fetch(config.apiUrl + "/ssot/systems/" + systemid, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        metadata: sendMetadata,
        metadata_config: sendMetadataConfig
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          throw Error(response.message[0].message);
        } else if (!response.key) {
          throw Error("Failure in Systems API");
        } else {
          setMetadata(response.metadata);
          setMetadataConfig(response.metadata_config);
          setSystemUpdated(response.date_updated);
          deleteTag(tagKey);
          setMoveModal(false);
        }
      })
      .catch(error => {
        console.log(error);
        setFormStatus(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 6000);

        setUpdateSpinner(false);
        setMoveModal(false);
      });
  };

  for (var key in tags) {
    tagData.push({
      key: key,
      value: tags[key]
    });
  }

  const buttonState = data => {
    if (admin && systemState === "active") {
      return (
        <div style={{ textAlign: "center" }}>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => editTag(data.key)}
          >
            {/* <Icon type="edit" /> */}
            Edit
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => moveTagModal(data.key)}
          >
            Demote
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => deleteTag(data.key)}
          >
            {/* <Icon type="delete" /> */}
            Delete
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <Button small disabled>
            {/* <Icon type="edit" /> */}
            Edit
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button small disabled>
            Demote
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button small disabled>
            {/* <Icon type="delete" /> */}
            Delete
          </Button>
        </div>
      );
    }
  };

  tagData = tagData.map(data => ({
    key: data.key,
    value: data.value,
    desc: getTagDesc(data.key, data.value),
    actions: buttonState(data)
  }));

  var tblProps = {
    data: tagData,
    columns: [
      {
        Header: "Tag Key",
        accessor: "key"
      },
      {
        Header: "Tag Value",
        accessor: "value"
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {deleteSpinner.includes(row.original.key) ? (
              <Spinner />
            ) : (
              row.original.actions
            )}
          </div>
        )
      }
    ]
  };

  var hasDesc = false;
  for (var entry in tagData) {
    if (tagData[entry].desc) {
      hasDesc = true;
    }
  }

  if (hasDesc) {
    tblProps = {
      data: tagData,
      columns: [
        {
          Header: "Tag Key",
          accessor: "key"
        },
        {
          Header: "Tag Value",
          accessor: "value"
        },
        {
          Header: "Value Description",
          accessor: "desc"
        },
        {
          Header: "Actions",
          accessor: "actions",
          Cell: ({ row }) => (
            <div style={{ textAlign: "center" }}>
              {deleteSpinner.includes(row.original.key) ? (
                <Spinner />
              ) : (
                row.original.actions
              )}
            </div>
          )
        }
      ]
    };
  }

  const updateTags = sendTags => {
    setUpdateSpinner(true);
    fetch(config.apiUrl + "/ssot/systems/" + systemid, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        tags: sendTags
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          throw Error(response.message[0].message);
        } else if (!response.key) {
          throw Error("Failure in System API");
        } else {
          setTagKey("");
          setTagValue("");
          setPrefix("");
          setSuffix("");
          setUpdateSpinner(false);
          setDeleteSpinner([]);
          setEditTagModal(false);
          setAddTagModal(false);
          // setMoveModal(false);
          setTags(response.tags);
          setSystemUpdated(response.date_updated);
          setFormStatus(
            <div className="epic-color-success system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Change saved."}</div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 6000);
        }
      })
      .catch(error => {
        setFormStatus(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 6000);

        setTagKey("");
        setTagValue("");
        setPrefix("");
        setSuffix("");
        setUpdateSpinner(false);
        setDeleteSpinner([]);
        setEditTagModal(false);
        setAddTagModal(false);
      });
  };

  const addBtn = editable ? (
    <Button
      style={{ marginBottom: "1em" }}
      onClick={() => {
        setTagKey("");
        setTagValue("");
        setAddTagModal(true);
      }}
    >
      Add Tag
    </Button>
  ) : (
    ""
  );

  const formatRules = rules => {
    const formattedRules = [];
    for (var key in rules) {
      formattedRules[key] = rules[key];
    }
    setRules(formattedRules);
  };

  const applyStandard = () => {
    if (standard === "") return;
    fetch(config.apiUrl + "/tagging/guided_validation", {
      // fetch("http://127.0.0.1:8002/guided_validation", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      },
      method: "POST",
      body: JSON.stringify({
        standard: standard.value,
        tags: tags
      })
    })
      .then(response => response.json())
      .then(response => {
        if (!response.status) {
          throw Error("Failure in validate API");
        } else if (response.status === "fail") {
          throw Error(response.message);
        } else if (response.status === "incomplete compliance") {
          //console.log(response);
          formatRules(response.rule_data);
          var compliance_warnings = [];
          for (var entry of response.reasons) {
            if (
              entry.includes("environment") &&
              props.system.type === "application" &&
              entry.includes("required")
            ) {
              compliance_warnings.push({
                warning: entry,
                recommendation:
                  "If application is deployed to more than one aws account or deployed by the BMX Pipelines then define the tag in the pipeline configuration / infrastructure as code"
              });
            } else if (
              entry.includes("environment") &&
              props.system.type === "application" &&
              entry.includes("Prefix")
            ) {
              compliance_warnings.push({
                warning: entry,
                recommendation:
                  "Update value in system tags. If this tag is being managed in BMX Pipelines / infrastructure as code then this key should be deleted"
              });
            } else if (entry.includes("required")) {
              compliance_warnings.push({
                warning: entry,
                recommendation: "Add key / value to system tags"
              });
            } else {
              compliance_warnings.push({
                warning: entry,
                recommendation:
                  "Update value in system tags to be compliant with the standard"
              });
            }
          }
          var compliance_data = {
            data: compliance_warnings,
            columns: [
              {
                Header: "Warning",
                accessor: "warning"
              },
              {
                Header: "Recommendation",
                accessor: "recommendation"
              }
            ]
          };

          setMessage(
            <div>
              <h2
                className="epic-font-brand"
                style={{
                  textAlign: "left",
                  fontSize: "14",
                  color: "#ffbf00"
                }}
              >
                Tag Configuration Validation Warnings:
              </h2>
              <StaticTable {...compliance_data} />
            </div>
          );
        } else {
          /*setFormStatus(
            <div className="system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Validation Successful!"}</div>
            </div>
          );*/
          formatRules(response.rule_data);
          setMessage(
            <div>
              <h2
                className="epic-font-brand"
                style={{
                  textAlign: "left",
                  fontSize: "14",
                  color: "green"
                }}
              >
                Tag configuration is compliant
              </h2>
              No validation issues were found for the tags that are configured
              for the system
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 6000);
        }
      })
      .catch(error => {
        setMessage(
          <div>
            Failure in validation:
            <ul>
              <li key={error.message}>
                <pre>{error.message}</pre>
              </li>
            </ul>
          </div>
        );
        console.log(error);
        setShowOverlay(true);
      });
  };

  useEffect(() => {
    var updateTag = "";
    if (prefix.value && suffix) {
      updateTag = prefix.value + "-" + suffix;
    } else if (prefix.value && !suffix) {
      updateTag = prefix;
    } else if (!prefix && suffix) {
      updateTag = suffix;
    }
    setTagValue(updateTag);
  }, [prefix, suffix]);

  useEffect(() => {
    //console.log(account);
    if (account !== null) {
      if (typeof account.tagging.accountTagging.enabled !== "undefined") {
        setalt(account.tagging.accountTagging.enabled);
      }
      if (typeof account.tagging.accountTagging.tagOverwrite !== "undefined") {
        setTagOverwrite(account.tagging.accountTagging.tagOverwrite);
      }

      delete account.tagging;
      delete account.niketech;
    }
  }, [account, props.accessToken, systemid]);

  const getTagRuleKeys = rules => {
    const keyList = [];
    for (var rule in rules) {
      //console.log(tags.rule);
      if (!(rule in tags)) {
        keyList.push({ value: rule, label: rule });
      }
    }
    return (
      <div>
        <Tooltip
          text="Choose from a list of tags that have rules associated with them"
          inverse={true}
        >
          Managed Tag Key&nbsp; <Icon type="walkthrough" size="small"></Icon>
        </Tooltip>
        <Select
          label=""
          className="textInput"
          name="managedKey"
          options={keyList}
          value={{ value: tagKey, label: tagKey }}
          onChange={selected => setTagKey(selected.value)}
        />
        <br />
        <Tooltip
          text="Define a custom tag key. See tagging rules for key name restrictions"
          inverse={true}
        >
          Custom Tag Key&nbsp; <Icon type="walkthrough" size="small"></Icon>
        </Tooltip>
        <br />
        <TextInput
          label=""
          className="textInput"
          hasErrors={tagData.find(tag => tag.key === tagKey) ? true : false}
          errorMessage="Tag Key already exists"
          name="tagkey"
          full
          value={tagKey}
          onChange={e => setTagKey(e.target.value)}
          onKeyPress={e =>
            e.key === "Enter" ? setTagKey(e.target.value) : null
          }
        />
      </div>
    );
  };

  const getTagRule = tag_key => {
    let rule = rules[tag_key];

    if ("option_list" in rule) {
      const valueList = [];
      rule.option_list.forEach((item, index) => {
        valueList.push({
          value: item.id,
          label: item.value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports a set list friendly names and their associated values.
            Selecting an option will set the value to the associated value"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Value"
            className="textInput"
            name="tagvalue"
            options={valueList}
            value={tagValue}
            onChange={selected => setTagValue(selected)}
          />
        </div>
      );
    }

    if ("value_list" in rule) {
      const valueList = [];
      rule.value_list.forEach((value, index) => {
        valueList.push({
          value: value,
          label: value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports a list of managed values"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Value"
            className="textInput"
            name="tagvalue"
            options={valueList}
            value={tagValue}
            onChange={selected => setTagValue(selected)}
          />
        </div>
      );
    }

    if ("prefix_list" in rule) {
      const valueList = [];
      rule.prefix_list.forEach((value, index) => {
        valueList.push({
          value: value,
          label: value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports prefixes from a list of managed values and an optional suffix, the result of setting both will be hypenated"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Prefix"
            className="textInput"
            name="prefix"
            options={valueList}
            value={prefix}
            onChange={selected => setPrefix(selected)}
          />
          <TextInput
            label="Suffix (Optional)"
            className="textInput"
            full
            name="suffix"
            value={suffix}
            onChange={e => {
              let suffix = e.target.value;
              if ("case_rule" in rule) {
                if (rule.case_rule === "lower") suffix = suffix.toLowerCase();
                else if (rule.case_rule === "upper")
                  suffix = suffix.toUpperCase();
              }

              if ("illegal_strings" in rule) {
                rule.illegal_strings.forEach((item, index) => {
                  suffix = suffix.replace(item, "");
                });
              }

              setSuffix(suffix);
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <Tooltip
          text="This tag key supports free form text but may have case or other restrictions"
          inverse={true}
        >
          Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
        </Tooltip>
        <TextInput
          label="Value"
          className="textInput"
          full
          name="tagvalue"
          value={tagValue}
          onChange={e => {
            let tag_value = e.target.value;
            if ("case_rule" in rule) {
              if (rule.case_rule === "lower")
                tag_value = tag_value.toLowerCase();
              else if (rule.case_rule === "upper")
                tag_value = tag_value.toUpperCase();
            }

            if ("illegal_strings" in rule) {
              rule.illegal_strings.forEach((item, index) => {
                tag_value = tag_value.replace(item, "");
              });
            }

            setTagValue(tag_value);
          }}
        />
      </div>
    );
  };

  const accountView = account ? (
    <Collapsible
      key="taggingSettings"
      isOpen={true}
      title="Account Tagging Settings"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <b>
            To update these setting go to the :&nbsp;
            <a
              style={{ fontSize: "18px", margin: "1em" }}
              className="dashLink"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://ui.waffleiron-prod.nikecloud.com/account/" +
                account.accountId
              }
            >
              Waffle Iron Account
            </a>
            <p>
              These settings can only be updated if you are in the admin role
              for the account. If you are unsure if you are in the admin role
              you can check the permissions tab in Waffle Iron.
            </p>
          </b>
          <div style={{ margin: "3em" }}></div>
          <ToggleSwitch
            label="Enable Account Level Tagging"
            options={boolean}
            value={altenabled}
            name="altenabled"
          />
          <div style={{ margin: "1em" }}></div>
          <ToggleSwitch
            label="Enable Account Tagging Overwrite"
            options={boolean}
            value={tagOverwrite}
            name="tagOverwrite"
          />
        </div>
      )}
    </Collapsible>
  ) : (
    <div></div>
  );

  /*
  const validateButton = (
    <Button
      onClick={e => applyStandard(e)}
      disabled={validating ? true : standard ? false : true}
    >
      Select Standard
    </Button>
  );

  const applyStandard = e => {
     return;
   }
  */

  return (
    <Section id={props.id} title="Tags">
      {accountView}
      <div className="epic-font-base-md">Standards</div>
      <p style={{ padding: "1em", fontStyle: "italic" }}>
        CCoE requires that all resources in the cloud have a set of standard
        tags. For more information, please visit this link: &nbsp;
        <a
          style={{ fontSize: "18px" }}
          className="dashLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://confluence.nike.com/x/kGFDMg"
        >
          Nike Cloud Tagging Standard documentation
        </a>
        <br />
        To view the rules for the latest Nike Cloud Tagging Standard visit:
        &nbsp;
        <a
          style={{ fontSize: "18px" }}
          className="dashLink"
          target="_blank"
          rel="noopener noreferrer"
          href={basePath + "/tagging/explorer"}
        >
          Tagging Rules
        </a>
      </p>
      <Modal
        onClose={() => setAddTagModal(false)}
        closeButton
        title=""
        show={addTagModal}
      >
        <div>
          <div style={{ marginBottom: "30px" }}>
            {standard ? (
              getTagRuleKeys(rules)
            ) : (
              <TextInput
                label="Key"
                className="textInput"
                hasErrors={
                  tagData.find(tag => tag.key === tagKey) ? true : false
                }
                errorMessage="Tag Key already exists"
                name="tagkey"
                full
                value={tagKey}
                onChange={e => setTagKey(e.target.value)}
                onKeyPress={e =>
                  e.key === "Enter" ? setTagKey(e.target.value) : null
                }
              />
            )}

            {tagKey in rules ? (
              getTagRule(tagKey)
            ) : (
              <TextInput
                label="Value"
                className="textInput"
                full
                name="tagvalue"
                value={tagValue}
                onChange={e => setTagValue(e.target.value)}
                onKeyPress={e =>
                  e.key === "Enter" ? setTagValue(e.target.value) : null
                }
              />
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={
                updateSpinner
                  ? true
                  : tagData.find(tag => tag.key === tagKey)
                  ? true
                  : false
              }
              onClick={e => createNewTag(e)}
            >
              Submit
            </Button>
          </div>
          <div style={{ margin: "30px" }}></div>
          {updateSpinner ? (
            <div>
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <Modal
        onClose={() => setEditTagModal(false)}
        closeButton
        title=""
        show={editTagModal}
      >
        <div>
          <div style={{ marginBottom: "30px" }}>
            <TextInput
              key="keyInput"
              disabled={true}
              label="Key"
              className="readOnlyText"
              name="tagkey"
              full
              value={tagKey}
            />
            {tagKey in rules ? (
              getTagRule(tagKey)
            ) : (
              <TextInput
                label="Value"
                className="textInput"
                full
                name="tagvalue"
                value={tagValue}
                onChange={e => setTagValue(e.target.value)}
                onKeyPress={e =>
                  e.key === "Enter" ? setTagValue(e.target.value) : null
                }
              />
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button disabled={updateSpinner} onClick={e => createNewTag(e)}>
              Submit
            </Button>
          </div>
          <div style={{ margin: "30px" }}></div>
          {updateSpinner ? (
            <div>
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <Modal
        onClose={() => setMoveModal(false)}
        closeButton
        title=""
        show={moveModal}
      >
        <div>
          <div key="inputs" style={{ marginBottom: "30px" }}>
            <TextInput
              label="Key"
              className="textInput"
              full
              value={tagKey}
              disabled
            />
            <div key="spacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              label="Value"
              className="textInput"
              full
              value={tagValue}
              disabled
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              fontSize: "16px"
            }}
          >
            Move from Tag Set to Metadata?
          </div>
          <div
            key="submit"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button disabled={updateSpinner} onClick={() => moveTag()}>
              Submit
            </Button>
          </div>
          <div key="spacerBottom" style={{ margin: "30px" }}></div>
          {updateSpinner ? (
            <div>
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <div style={{ margin: "30px" }}></div>
      {addBtn}
      {message ? <div style={{ margin: "20px" }}>{message}</div> : <div></div>}
      {formStatus}
      <SortableTable {...tblProps} />
    </Section>
  );
};
