import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import http from "../util/http.js";
import { Button, Icon, Scrollspy, Section, Spinner } from "@nike/epic-react-ui";
import APIError from "../components/APIError";
import { FilterableSortTable } from "../components/table/index.js";
import { Details } from "../system/SystemDetails";
import { Metadata } from "../system/SystemDetails";
import { Settings } from "../system/SystemDetails";
import { Tags } from "../system/SystemDetails";
import { navigate } from "raviger";

const ManagementScreen = user => {
  const [apps, setApps] = useState([]);
  const [appsProcessed, setAppsProcessed] = useState(false);
  const [accts, setAccts] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageApps, setErrorMessageApps] = useState(null);
  const [errorMessageAccts, setErrorMessageAccts] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [key, setKey] = useState(null);
  const [system, setSystem] = useState(null);
  const [tags, setTags] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [metadataConfig, setMetadataConfig] = useState(null);
  const [systemKey, setSystemKey] = useState("");
  const [systemUpdated, setSystemUpdated] = useState("");
  const [panels, setPanels] = useState([
    {
      text: "My Systems",
      hash: "systems",
      component: ""
    }
  ]);
  const filter = user.email;

  // set base url path for api calls and page redirects
  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    var args = "";
    if (filter !== null) {
      args = "?owner=" + filter;
    }
    http
      .get(config.apiUrl + "/ssot/systems/" + args)
      // .get("http://127.0.0.1:8000/")
      // fetch("http://127.0.0.1:8000/")
      // .then(resources => resources.json())
      .then(response => {
        // console.log(response);
        if (response.status === "fail") {
          setErrorMessageApps(response);
        } else {
          setErrorMessageApps(null);
          setApps(response);
          setAppsProcessed(true);
        }
      })
      .catch(error => {
        setErrorMessageApps(error);
        console.log(error);
      });
  }, [user.accessToken, filter]);

  useEffect(() => {
    http
      .get(config.apiUrl + "/ssot/systems/account")
      // .get("http://127.0.0.1:8000/account")
      .then(response => {
        if (response.status === "fail") {
          setErrorMessageAccts(response);
        } else {
          setErrorMessageAccts(null);
          setAccts(response);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageAccts(error);
      });
  }, [user.accessToken]);

  useEffect(() => {
    if (!systemKey) return;

    generatePanels();
  }, [system, tags, metadata, metadataConfig, user.accessToken]);

  useEffect(() => {
    if (!key) return;

    fetch(config.apiUrl + "/ssot/systems/" + key, {
      headers: {
        Authorization: "Bearer " + user.accessToken,
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          console.log(response);
          setErrorMessage(response);
        } else if (!response.type) {
          throw Error("Failure in system retrieval");
        } else {
          setErrorMessage(null);
          setSystem(response);
          setSystemKey(response.key);
          setTags(response.tags);
          setMetadata(response.metadata);
          setMetadataConfig(response.metadata_config);
          setSystemUpdated(response.date_updated);
        }
      })
      .catch(error => {
        setErrorMessage(error);
        console.log(error);
      });
  }, [key, systemUpdated, user.accessToken]);

  const generatePanels = () => {
    const tempPanels = [];
    tempPanels.push({
      text: "Details",
      hash: "details",
      component: (
        <Details
          key={"Details_" + key}
          id="details"
          data={{ key: key }}
          system={system}
          {...user}
        />
      )
    });
    tempPanels.push({
      text: "Tags",
      hash: "tags",
      component: (
        <Tags
          key={"Tags_" + key}
          id="tags"
          tags={tags}
          setTags={setTags}
          metadata={metadata}
          setMetadata={setMetadata}
          metadataConfig={metadataConfig}
          setMetadataConfig={setMetadataConfig}
          data={{ key: systemKey }}
          system={system}
          account={null}
          {...user}
        />
      )
    });
    tempPanels.push({
      text: "Metadata",
      hash: "metadata",
      component: (
        <Metadata
          key={"Metadata_" + key}
          id="metadata"
          tags={tags}
          setTags={setTags}
          metadata={metadata}
          setMetadata={setMetadata}
          metadataConfig={metadataConfig}
          setMetadataConfig={setMetadataConfig}
          data={{ key: systemKey }}
          system={system}
          {...user}
        />
      )
    });
    tempPanels.push({
      text: "Settings",
      hash: "settings",
      component: (
        <Settings
          key={"Settings_" + key}
          id="settings"
          data={{ key: systemKey }}
          system={system}
          {...user}
        />
      )
    });

    setPanels(tempPanels);
  };

  const getAccountItem = (key, type, item) => {
    if (type !== "aws_account") return "N/A";

    for (var acct in accts) {
      if (accts[acct].tagging && accts[acct].tagging.tagGuid === key)
        return accts[acct][item];
    }
    return "N/A";
  };

  const props = {
    data: apps.map(data => ({
      Key: data.key,
      Type: data.type,
      Name: data.name,
      Owner: data.owner,
      AccountType: getAccountItem(data.key, data.type, "accountType"),
      AccountId: getAccountItem(data.key, data.type, "accountId")
    })),
    fields: ["Type", "AccountType", "AccountId"],
    columns: [
      {
        Header: "System ID",
        accessor: "Key"
      },
      {
        Header: "Name",
        accessor: "Name"
      },
      {
        Header: "Link to System",
        id: "link",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.Key !== "None" ? (
              <a href={basePath + "/system/" + row.original.Key}>
                <Button
                  onClick={() =>
                    navigate(basePath + "/system/" + row.original.Key)
                  }
                >
                  <Icon type="edit" />
                </Button>
              </a>
            ) : (
              <>Unmanaged</>
            )}
          </div>
        )
      },
      {
        Header: "Owner",
        accessor: "Owner"
      },
      {
        Header: "System Type",
        accessor: "Type"
      },
      {
        Header: "Account Type",
        accessor: "AccountType",
        Cell: props =>
          props.cell.value === "N/A" ? (
            <div
              style={{
                fontStyle: "italic",
                color: "gray"
              }}
            >
              {props.cell.value}
            </div>
          ) : (
            props.cell.value
          )
      },
      {
        Header: "Account Id",
        accessor: "AccountId",
        Cell: props =>
          props.cell.value === "N/A" ? (
            <div
              style={{
                fontStyle: "italic",
                color: "gray"
              }}
            >
              {props.cell.value}
            </div>
          ) : (
            props.cell.value
          )
      },
      {
        Header: "Edit",
        id: "edit",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <Button
              title="Select"
              onClick={() => handleClick(row.original.Key)}
            >
              <Icon type="SingleSelect" />
            </Button>
          </div>
        )
      }
    ]
  };

  const handleClick = key => {
    setKey(key);
    setIsCollapsed(true);
  };

  const showTable = () => {
    return (
      <div>
        <style>
          {`.epic__sectionContent__947 {
            margin-top: 0px
          }
          .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
            height: 56px;
            text-align: center;
          }
          .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
            height: 56px;
            text-align: center;
        }
          .epic__NextGenSelect__dea .NextGenSelect__control .NextGenSelect__indicators {
            color: #e5e5e5;
            padding: 5px;
          }`}
        </style>
        <Section
          key={"Systems_" + key}
          id="systems"
          title="My Systems"
          collapsible={isCollapsed}
        >
          <FilterableSortTable {...props} />
        </Section>
      </div>
    );
  };

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
        }
        .epic__NextGenSelect__dea .NextGenSelect__control .NextGenSelect__indicators {
          color: #e5e5e5;
          padding: 5px;
        }`}
      </style>
      {!apps ? (
        !errorMessage ? (
          <div>
            <Spinner overlay={false} show={true} />
          </div>
        ) : (
          <APIError showTitle={true} paddingLeft="1em" error={errorMessage} />
        )
      ) : !system && errorMessage ? (
        <APIError showTitle={true} paddingLeft="1em" error={errorMessage} />
      ) : (
        <div className="scrollspy-container">
          <Scrollspy
            title="Management Nav"
            items={panels}
            uppercaseTitle={true}
            uppercaseItems={true}
            className="scrollspy-menu"
          />
          <div className="scrollspy-content">
            {showTable()}
            {panels.map(data => {
              return data.component;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(ManagementScreen);
