import deepEqual from 'deep-equal'

import store from './store.js'
import { RECOVERY_KEY } from './constants.js'

const isAnyRequestPending = state => {
  return Object.keys(state.request).some(key => {
    let action = state.request[key] || {}
    return action.isPending
  })
}

const waitUntilPendingCompleted = () => getState => {
  return new Promise((resolve, reject) => {
    let retryUntil = new Date(Date.now() + 20000)

    let watcher = setInterval(() => {
      if (retryUntil - Date.now() < 0) {
        clearInterval(watcher)

        reject(new Error('error recovering expired login'))
      }
      if (!isAnyRequestPending(getState())) {
        clearInterval(watcher)
        resolve()
      }
    }, 20)
  })
}

const hasRecoverableState = state => {
  let hasFormChanges = false

  if (state.form) {
    hasFormChanges = Object.keys(state.form).some(key => {
      let form = state.form[key]

      return form.anyTouched && !deepEqual(form.initial, form.values)
    })
  }

  return hasFormChanges
}

export const recoverExpiredState = () => async getState => {
  let recoveredState = JSON.parse(store.getItem(RECOVERY_KEY))

  if (!recoveredState || !hasRecoverableState(recoveredState)) {
    store.removeItem(RECOVERY_KEY)
    return
  }

  try {
    await waitUntilPendingCompleted()(getState)
    store.removeItem(RECOVERY_KEY)
  } catch (error) {
    console.error('error waiting for pending', error)
  }
}
