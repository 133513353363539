import React from "react";
import { Section, Button, Icon } from "@nike/epic-react-ui";
import { SearchDetails } from "./SearchResultsData";
import { navigate } from "raviger";
import { StaticTable } from "../components/table/index.js";

const SearchResults = ({ data, size }) => {
  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  const columns = [
    {
      Header: "Field Name",
      accessor: "key"
    },
    {
      Header: "Field Value",
      accessor: "value"
    }
  ];

  const renderResults = () => {
    let displayed = [];
    for (let i = 0; i <= size - 1; i++) {
      let matches = [];
      let highlights = [];
      function createData(key, value) {
        return { key, value };
      }
      if (data[i]) {
        for (const [key, value] of Object.entries(data[i]["highlight"])) {
          if (!key.includes(".keyword")) {
            matches.push(
              <li>
                <b>{key}</b> {value}
              </li>
            );
            highlights.push(key);
          } else {
            matches.push(
              <li>
                <b>{key.replace(".keyword", "")}</b> {value}
              </li>
            );
          }
        }
        let systemID = data[i]["SYSTEM_ID"];

        if (systemID) {
          let sectionData = [
            createData("ID", data[i]["ID"]),
            createData("Type", data[i]["doc_type"]),
            createData("Account", data[i]["ACCOUNT"]),
            createData("System ID", data[i]["SYSTEM_ID"]),
            createData("Fields With Matches", <ul>{matches}</ul>),
            createData(
              "Link to System",
              <a href={basePath + "/system/" + systemID}>
                <Button
                  onClick={() => navigate(basePath + "/system/" + systemID)}
                >
                  <Icon type="edit" />
                </Button>
              </a>
            )
          ];
          displayed.push(
            <Section
              id={i}
              collapsible="True"
              collapsibleConfig={{ open: true }}
              align="left"
            >
              <StaticTable
                columns={columns}
                data={sectionData.map(data => ({
                  key: data.key,
                  value: data.value
                }))}
              />
              <br />
              <SearchDetails resource={data[i]} highlights={highlights} />
            </Section>
          );
        } else {
          let sectionData = [
            createData("ID", data[i]["ID"]),
            createData("Type", data[i]["doc_type"]),
            createData("Account", data[i]["ACCOUNT"]),
            createData("System ID", data[i]["SYSTEM_ID"]),
            createData("Fields With Matches", <ul>{matches}</ul>)
          ];
          displayed.push(
            <Section
              id={i}
              collapsible="True"
              collapsibleConfig={{ open: true }}
              align="left"
            >
              <StaticTable
                columns={columns}
                data={sectionData.map(data => ({
                  key: data.key,
                  value: data.value
                }))}
              />
              <br />
              <SearchDetails resource={data[i]} />
            </Section>
          );
        }
      }
    }

    //console.log(displayed);

    return displayed;
  };
  /* eslint-disable */
  // We disable this because react-table handles all the key logic
  return <div>{renderResults()}</div>;
};

export default SearchResults;
