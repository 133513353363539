import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Scrollspy,
  Select,
  Spinner,
  TextInput,
  Button,
  Collapsible,
  Icon,
  Label,
  Modal,
  Section
} from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import { FilterableSortTable } from "../components/table/index.js";
import ComplianceGraph from "./TaggingComplianceGraph";
import { navigate } from "raviger";
import { ComplianceDetails } from "./TaggingComplianceModal";
import { StatusTable } from "./TaggingComplianceStatusTable";

const TaggingComplianceDetails = props => {
  const [errorMessageQuery, setErrorMessageQuery] = useState(null);
  const [showModal, setModal] = useState(false);
  const [resource, setResource] = useState(null);
  const [system_compliance, setSystemCompliance] = useState([]);
  const [incomplete, setIncomplete] = useState(null);
  const [valid, setValid] = useState(null);
  const [invalid, setInvalid] = useState(null);
  const [partial, setPartial] = useState(null);
  const [tableContent, setTableContent] = useState([]);
  const [nonCompliantCount, setNonCompliantCount] = useState(0);
  const [resourceTypeData, setResourceTypeData] = useState([]);
  const [resourceCount, setResourceCount] = useState(100);
  const [friendlyName, setFriendlyName] = useState(null);
  const [resourceTypeFilter, setResourceTypeFilter] = useState("All");
  const [resourceTypeList, setResourceTypeList] = useState([]);
  const [tagFilter, setTagFilter] = useState("All");
  const [hasBarData, setHasBarData] = useState(false);
  const [requiredTags, setRequiredTags] = useState([]);
  const [message, setMessage] = useState("");
  const standard = "Latest";
  const filter = props.data.filter;
  const key = props.data.key;
  const panels = [];

  let barData = [];
  //console.log(JSON.stringify(props.data));

  // set base url path for api calls and page redirects
  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  const apiLookup = {
    SYSTEM_ID: "tagguid",
    ACCOUNT: "account",
    RESOURCE_TYPE: "resource_type",
    "FinOps_MAPPING.Domain": "domain",
    "FinOps_MAPPING.Subdomain": "subdomain",
    "FinOps_MAPPING.Subdepartment": "subdepartment",
    "nike-owner": "owner"
  };
  const apiFilter = apiLookup[filter];

  const filterTags = rules => {
    const required = [];
    rules.forEach(rule => {
      if (rule.required) {
        required.push(rule.tag_key);
      }
    });
    setRequiredTags(required);
  };

  useEffect(() => {
    const fetchRules = async () => {
      let response = await fetch(config.apiUrl + "/tagging/rules/" + standard, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        }
      });
      let result = await response.json();
      if (!response.ok) {
        setMessage(
          <div>
            <APIError
              title="Failure in fetching rules"
              showTitle={true}
              error={result}
            />
          </div>
        );
        return false;
      } else {
        filterTags(result.rules.key_rules);
      }
    };

    fetchRules();
  }, [props.accessToken]);

  // api call to get the tagging compliance status aggregation for the selected filter and key
  useEffect(() => {
    let filterPath = "/cloud-resources/compliance/" + apiFilter + "/" + key;
    let filterParams = "?breakdown=true&breakdown_count=15&invalid_types=true";
    fetch(config.apiUrl + filterPath + filterParams, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          // console.log(indicies);
          setErrorMessageQuery(response);
        } else if (!response.hasOwnProperty("Total")) {
          throw Error("Failure in resources API");
        } else {
          setErrorMessageQuery(null);
          setPartial(response.PartialCompliant);
          setValid(response.Compliant);
          setInvalid(response.NonCompliant);
          setIncomplete(response.Incomplete);
          setNonCompliantCount(
            response.PartialCompliant + response.NonCompliant
          );
          setResourceTypeList([...response.InvalidTypeList].sort());
          setResourceTypeData([
            {
              label: "Compliant Count",
              data: response.ResourceTypeBreakdown.CompliantByType
            },
            {
              label: "Non-Compliant Count",
              data: response.ResourceTypeBreakdown.InvalidByType
            },
            {
              label: "Incomplete Count",
              data: response.ResourceTypeBreakdown.IncompleteByType
            }
          ]);
          setHasBarData(true);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageQuery(error);
      });
  }, [props.accessToken, key, apiFilter]);

  barData = [
    {
      label: "Count",
      data: [
        ["Incomplete", incomplete],
        ["Non-Compliant", invalid],
        ["Partial Compliant", partial],
        ["Compliant", valid]
      ]
    }
  ];

  // api call to get the tag key specific metrics for tagging compliance
  useEffect(() => {
    let filterPath = apiFilter + "/" + key + "/complianceByKey";
    fetch(config.apiUrl + "/cloud-resources/compliance/" + filterPath, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          // console.log(indicies);
          setErrorMessageQuery(response);
        } else if (!response.hasOwnProperty("Total Resources")) {
          throw Error("Failure in resources API");
        } else {
          setErrorMessageQuery(null);
          let valid_data = [];
          let invalid_data = [];
          let missing_data = [];
          let tagSummary = response["Summary by tag key"];
          Object.keys(tagSummary).forEach(key => {
            valid_data.push([key, tagSummary[key]["Compliant"]]);
            invalid_data.push([key, tagSummary[key]["Non Compliant"]]);
            missing_data.push([key, tagSummary[key]["Missing"]]);
          });
          setSystemCompliance([
            {
              label: "Compliant",
              data: valid_data
            },
            {
              label: "Non Compliant",
              data: invalid_data
            },
            {
              label: "Missing",
              data: missing_data
            }
          ]);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageQuery(error);
      });
  }, [props.accessToken, key, apiFilter]);

  // api call to get the data for the table
  useEffect(() => {
    let filterPath =
      "/cloud-resources/compliance/" + apiFilter + "/" + key + "/resources";

    let filterParams = "?size=" + resourceCount;
    var mappedResources = [];
    if (resourceTypeFilter !== "All") {
      filterParams += "&resource_type=" + resourceTypeFilter;
    }
    if (tagFilter !== "All") {
      filterParams += "&tag=" + tagFilter;
    }
    fetch(config.apiUrl + filterPath + filterParams, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          // console.log(indicies);
          setErrorMessageQuery(response);
        } else if (!response.hasOwnProperty("Total Resources")) {
          throw Error("Failure in resources API");
        } else {
          setErrorMessageQuery(null);
          for (var e in response["Resources"]) {
            var resourceData = response["Resources"][e];
            var key_errors = [];
            for (var key of resourceData["validation_errors"]) {
              var errorKey = key.split(":")[0];
              if (!(errorKey in key_errors)) {
                key_errors.push(errorKey);
              }
            }
            resourceData["errorKeys"] = key_errors;
            mappedResources.push(resourceData);
          }
          setTableContent(mappedResources);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageQuery(error);
      });
  }, [
    props.accessToken,
    key,
    resourceCount,
    resourceTypeFilter,
    apiFilter,
    tagFilter
  ]);

  // api call to get the friendly names for the systems or the accounts
  useEffect(() => {
    if (filter === "SYSTEM_ID") {
      fetch(config.apiUrl + "/ssot/systems/" + key, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        },
        method: "GET"
      })
        .then(response => response.json())
        .then(response => {
          const friendlyMap = {
            name: response.name,
            type: response.type,
            systemId: key
          };
          setFriendlyName(friendlyMap);
        })
        .catch(error => {
          console.log(error);
          setErrorMessageQuery(error);
        });
    } else if (filter === "ACCOUNT") {
      fetch(config.apiUrl + "/ssot/reference/accessible-accounts-v2", {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        },
        method: "GET"
      })
        .then(response => response.json())
        .then(response => {
          const result = response.filter(account => account.accountId === key);
          const entry = result[0];
          let accountType =
            entry.cloudServiceProvider === "AWS" ? "aws_account" : " ";
          const friendlyMap = {
            name: entry.name + " - " + entry.accountType,
            systemId: entry.tagging.tagGuid,
            type: accountType,
            account: key
          };
          setFriendlyName(friendlyMap);
        })
        .catch(error => {
          console.log(error);
          setErrorMessageQuery(error);
        });
    } else if (filter === "RESOURCE_TYPE") {
      var friendly = key
        .replace("aws_", "")
        .replace("azure_", "")
        .replace(/_/g, " ");
      setFriendlyName(friendly);
    } else if (filter === "FinOps_MAPPING.Domain") {
      friendly = key;
      setFriendlyName(friendly);
    } else if (filter === "FinOps_MAPPING.Subdomain") {
      friendly = key;
      setFriendlyName(friendly);
    } else if (filter === "FinOps_MAPPING.Subdepartment") {
      friendly = key;
      setFriendlyName(friendly);
    } else if (filter === "nike-owner") {
      friendly = key;
      setFriendlyName(friendly);
    }
  }, [props.accessToken, filter, key]);

  // get account number for a system
  useEffect(() => {
    if (
      filter === "SYSTEM_ID" &&
      friendlyName &&
      friendlyName.type === "aws_account" &&
      !friendlyName.account
    ) {
      fetch(config.apiUrl + "/ssot/systems/account/tagguid/" + key, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        },
        method: "GET"
      })
        .then(response => response.json())
        .then(response => {
          const fn = { ...friendlyName };
          fn.account = response.accountId;
          setFriendlyName(fn);
        })
        .catch(error => {
          console.log(error);
          setErrorMessageQuery(error);
        });
    }
  }, [key, filter, friendlyName, props.accessToken]);

  const attributes = {
    data: tableContent.map(data => ({
      Resource_ID: data.id,
      System_ID: data.SYSTEM_ID,
      Resource_Type: data.RESOURCE_TYPE,
      Account: data.ACCOUNT,
      Status: <StatusTable resource={data} />,
      Raw: data,
      Component: <ComplianceDetails resource={data} {...props} />
    })),
    fields: ["Resource_ID", "System_ID", "Resource_Type", "Account", "Status"],
    columns: [
      {
        Header: "Resource ID",
        accessor: "Resource_ID"
      },
      {
        Header: "System ID",
        accessor: "System_ID"
      },
      {
        Header: "Link to System",
        id: "link",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.System_ID !== "None" ? (
              <a href={basePath + "/system/" + row.original.System_ID}>
                <Button
                  onClick={() =>
                    navigate(basePath + "/system/" + row.original.System_ID)
                  }
                >
                  <Icon type="edit" />
                </Button>
              </a>
            ) : (
              <>Unmanaged</>
            )}
          </div>
        )
      },
      {
        Header: "Resource Type",
        accessor: "Resource_Type"
      },
      {
        Header: "Account",
        accessor: "Account"
      },
      {
        Header: "Status",
        accessor: "Status"
      },
      {
        Header: "Details",
        id: "details",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center", marginRight: "50px" }}>
            <Button
              title="Show resource details"
              small
              onClick={() => {
                setResource(row.original.Component);
                setModal(true);
              }}
            >
              <Icon type="walkthrough" />
            </Button>
          </div>
        )
      }
    ]
  };

  const showTable = () => {
    if (nonCompliantCount === 0) {
      return (
        <div style={{ textAlign: "center" }}>
          <h1>No Non Compliant Resources</h1>
        </div>
      );
    } else {
      return (
        <div>
          <Modal
            onClose={() => {
              setModal(false);
              setResource(null);
            }}
            closeButton
            show={showModal}
          >
            <h2>Resource Data</h2>
            <div
              style={{
                margin: "30px",
                overflow: "scroll",
                maxHeight: "400px",
                width: "90%"
              }}
            >
              {resource}
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  setModal(false);
                  setResource(null);
                }}
              >
                Close
              </Button>
            </div>
          </Modal>
          <FilterableSortTable {...attributes} />
        </div>
      );
    }
  };

  const filterMap = {
    SYSTEM_ID: "System ID",
    ACCOUNT: "Account",
    RESOURCE_TYPE: "Resource Type",
    "FinOps_MAPPING.Domain": "domain",
    "FinOps_MAPPING.Subdomain": "subdomain",
    "FinOps_MAPPING.Subdepartment": "subdepartment",
    "nike-owner": "owner"
  };

  const typeMap = {
    aws_account: "AWS Account",
    application: "Application",
    azure_rg: "Azure Resource Group",
    azure_subscription: "Azure Subscription"
  };

  const querySelect = () => {
    const resourceOptions = [{ label: "All", value: "All" }];
    resourceTypeList.forEach(rt => {
      resourceOptions.push({ label: rt, value: rt });
    });

    const tagOptions = [{ label: "All", value: "All" }];
    requiredTags.forEach(tag => {
      tagOptions.push({ label: tag, value: tag });
    });

    return (
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <div style={{ width: "300px", margin: "10px" }}>
          <Label label="Non-Compliant Resource Type">
            <Select
              placeholder="All"
              value={{
                label: resourceTypeFilter,
                value: resourceTypeFilter
              }}
              onChange={e => {
                setResourceTypeFilter(e.value);
              }}
              options={resourceOptions}
            />
          </Label>
        </div>
        <div style={{ width: "200px", margin: "10px" }}>
          <Label label="Non-Compliant Tag">
            <Select
              placeholder="All"
              value={{ label: tagFilter, value: tagFilter }}
              onChange={e => {
                setTagFilter(e.value);
              }}
              options={tagOptions}
            />
          </Label>
        </div>
        <div style={{ width: "110px", margin: "10px" }}>
          <Label label="Table Results">
            <Select
              value={{ label: resourceCount, value: resourceCount }}
              onChange={e => {
                setResourceCount(e.value);
              }}
              options={[
                { label: "10", value: 10 },
                { label: "100", value: 100 },
                { label: "250", value: 250 },
                { label: "500", value: 500 },
                { label: "1000", value: 1000 }
              ]}
            />
          </Label>
        </div>
      </div>
    );
  };

  const generatePanels = () => {
    panels.splice(0, panels.length);
    let subtitleInfo = "";

    if (!friendlyName) return;
    if (filter === "ACCOUNT" || filter === "SYSTEM_ID") {
      subtitleInfo = ` (for ${typeMap[friendlyName.type]} ${friendlyName.name}`;
      subtitleInfo += friendlyName.account
        ? ` / ${friendlyName.account})`
        : ")";
    }

    panels.push({
      text: "Summary",
      hash: "summary",
      component: (
        <Section
          key="summary"
          id="summary"
          title="Summary"
          subtitle={"Summarized by " + filterMap[filter] + subtitleInfo}
          collapsible="true"
          collapsibleConfig={{ open: true }}
        >
          <div style={{ marginBottom: "1em", color: "black" }}>
            <p style={{ marginLeft: "0em" }}>
              <li>
                <b> Compliant : </b>
                The resources have all the required tags and the values are
                compliant.
              </li>
            </p>
            <p style={{ marginLeft: "0em" }}>
              <li>
                <b>Non-Compliant : </b>
                The resources do not have any tags that met validation.
              </li>
            </p>
            <p style={{ marginLeft: "0em" }}>
              <li>
                <b>Partial Compliant : </b>
                The resources have at least one tag that met validation.
              </li>
            </p>
            <p style={{ marginLeft: "0em", marginBottom: "2em" }}>
              <li>
                <b>Incomplete : </b>
                The resources are incomplete due to API rate limits or
                permissions issues.
              </li>
            </p>
          </div>
          <div
            style={{
              textAlign: "left",
              fontSize: "18px",
              margin: "0px 0px 20px 50px"
            }}
          >
            {filter === "SYSTEM_ID" || filter === "ACCOUNT" ? (
              <div>
                <div>
                  <a href={basePath + "/system/" + friendlyName.systemId}>
                    <Button>System Details</Button>
                  </a>
                  <span style={{ padding: "1px" }}></span>
                  {friendlyName.account &&
                  friendlyName.type === "aws_account" ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        "https://ui.waffleiron-prod.nikecloud.com/account/" +
                        friendlyName.account
                      }
                    >
                      <Button>Waffle Iron Account</Button>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                {filter === "SYSTEM_ID" ? (
                  <div
                    key="inputs"
                    style={{ marginTop: "20px", width: "600px" }}
                  >
                    <TextInput
                      key="systemId"
                      label="System ID"
                      className="textInput"
                      value={key}
                      disabled
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {!errorMessageQuery ? (
              !hasBarData ? (
                <Spinner overlay={false} show={true} />
              ) : (
                <Collapsible
                  title="Compliance Status"
                  subtitle="Summary of resources by compliance status"
                  isOpen
                >
                  <div style={{ display: "flex" }}>
                    <ComplianceGraph data={barData} />
                  </div>
                </Collapsible>
              )
            ) : (
              <APIError showTitle={true} error={errorMessageQuery} />
            )}
            {!errorMessageQuery ? (
              system_compliance.length === 0 ? (
                <Spinner overlay={false} show={true} />
              ) : (
                <Collapsible
                  title="Tag Key Compliance"
                  subtitle="Summary of resources by Tag Keys"
                  isOpen
                >
                  <div style={{ display: "flex" }}>
                    <ComplianceGraph data={system_compliance} />
                  </div>
                </Collapsible>
              )
            ) : (
              <APIError showTitle={true} error={errorMessageQuery} />
            )}
            {!errorMessageQuery ? (
              props.data.filter === "RESOURCE_TYPE" ? (
                <br></br>
              ) : !hasBarData ? (
                <Spinner overlay={false} show={true} />
              ) : (
                <Collapsible
                  title="Resource Type Breakdown"
                  subtitle="Summary of resources by Resource Type"
                  isOpen
                >
                  <div style={{ display: "flex" }}>
                    <ComplianceGraph data={resourceTypeData} />
                  </div>
                </Collapsible>
              )
            ) : (
              <APIError showTitle={true} error={errorMessageQuery} />
            )}
          </div>
        </Section>
      )
    });
    panels.push({
      text: "Data",
      hash: "data",
      component: (
        <Section
          key="data"
          id="data"
          title="Data"
          subtitle={"Summarized by " + filterMap[filter]}
        >
          <div>{nonCompliantCount !== 0 ? querySelect() : ""}</div>
          {!errorMessageQuery ? (
            showTable()
          ) : (
            <APIError showTitle={true} error={errorMessageQuery} />
          )}
        </Section>
      )
    });
  };
  generatePanels();

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
          background-color: #f7f7f7;
          border-radius: 1em;
          margin-bottom: 30px;
          padding: 20px 1%;
          position: relative;
        }
        .epic__NextGenSelect__dea .NextGenSelect__control .NextGenSelect__indicators {
          color: #e5e5e5;
          padding: 5px;
        }
        .epic__sectionContent__947 {
          margin-top: 0px;
        }
        .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__container__d0a.epic__disabled__f8e {
            opacity: 1;
        }
        .epic__section__671 .epic__header__12f h3 {
          color: #404040
        }`}
      </style>
      {message}
      {!errorMessageQuery ? (
        <div className="scrollspy-container">
          <Scrollspy
            title="Tagging Compliance Details"
            items={panels}
            uppercaseTitle={true}
            uppercaseItems={true}
            className="scrollspy-menu"
          />
          <div className="scrollspy-content">
            {panels.map(data => {
              return data.component;
            })}
          </div>
        </div>
      ) : (
        <APIError showTitle={true} error={errorMessageQuery} />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(
  TaggingComplianceDetails
);
