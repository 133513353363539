import React, { useState } from "react";
import config from "../config.js";
import { Section, Button, Icon, Spinner, Modal } from "@nike/epic-react-ui";
import { navigate } from "raviger";

export const Settings = props => {
  const collectionid = props.data.key;
  const [admin] = useState(props.collection.is_admin);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClick = e => {
    setIsDeleting(true);
    e.preventDefault();
    // fetch("http://127.0.0.1:8000/" + collectionid, {
    fetch(config.apiUrl + "/ssot/ous/" + collectionid, {
      headers: {
        Authorization: "Bearer " + props.accessToken
      },
      method: "DELETE"
    })
      .then(response => response.json())
      .then(response => {
        if (response.state !== "inactive") throw Error(response.detail.message);
        else {
          setDeleteMessage(
            <div className="epic-color-success system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>Systems Collection deleted successfully!</div>
            </div>
          );
          setTimeout(() => {
            navigate("/collection/index");
          }, 3000);
        }
      })
      .catch(error => {
        setIsDeleting(false);
        setMessage(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.message}</div>
          </div>
        );
        setTimeout(() => {
          setMessage("");
        }, 3000);
        console.log(error);
      });
  };

  // const confirmation = (
  //   <div style={{ textAlign: "center" }}>
  //     <p>
  //       This will de-register the SC from CloudRed. No dependent resources will
  //       be affected by this. Press "Confirm" to continue.
  //     </p>
  //     <Button
  //       disabled={!admin}
  //       onClick={e => handleClick(e)}
  //       style={{ margin: "1em" }}
  //       theme={{ primary: "red", secondary: "white" }}
  //     >
  //       Confirm
  //     </Button>
  //   </div>
  // );

  const deleteCollection = (
    <div style={{ textAlign: "center" }}>
      <Button
        onClick={() => setDeleteModal(true)}
        style={{ margin: "1em" }}
        theme={{ primary: "red", secondary: "white" }}
        disabled={!admin}
      >
        Delete Systems Collection
      </Button>
    </div>
  );

  return (
    <div>
      <Section id={props.id} title="Settings">
        {message}
        {deleteCollection}
        <Modal
          onClose={() => setDeleteModal(false)}
          closeButton
          title=""
          show={deleteModal}
        >
          <div style={{ textAlign: "Center", padding: "20px" }}>
            {deleteMessage}
            This will de-register the Systems Collection from CloudRed and
            suspend services. No active resources will be affected by this.
            Press "Confirm" to continue.
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                onClick={e => handleClick(e)}
                style={{ margin: "1em" }}
                theme={{ primary: "red", secondary: "white" }}
                disabled={!isDeleting ? (admin ? false : true) : true}
              >
                Confirm
              </Button>
            </div>
            {isDeleting ? (
              <div>
                <Spinner />
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>
      </Section>
    </div>
  );
};
