import React, { useState } from "react";
import config from "../config.js";
import { Button, Section, Spinner, TextArea } from "@nike/epic-react-ui";

export const TaggingCheckTextarea = props => {
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [standard] = useState({
    value: "Latest",
    label: "Latest"
  });
  const exampleTags = JSON.stringify(
    {
      "nike-distributionlist": "lst-techops.example",
      "nike-environment": "dev",
      "nike-owner": "first.last@nike.com"
    },
    undefined,
    2
  );

  const isJson = () => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const isValid = () => {
    if (isJson()) {
      let valueObj = JSON.parse(value);

      const isEmpty = Object.keys(valueObj).length === 0;
      const isString = Object.values(valueObj).every(
        val => typeof val === "string"
      );
      const isArray = Array.isArray(valueObj);

      if (isEmpty) {
        setMessage(
          <div>
            <pre>Missing key-value pairs</pre>
          </div>
        );
        return false;
      }

      if (isArray) {
        setMessage(
          <div>
            <pre>Tags must be a dictionary</pre>
          </div>
        );
        return false;
      }

      if (!isString) {
        setMessage(
          <div>
            <pre>Each value must be a string</pre>
          </div>
        );
        return false;
      }
      return true;
    } else {
      if (value === "") {
        return false;
      } else {
        setMessage(
          <div>
            <pre>Invalid JSON</pre>
          </div>
        );
        return false;
      }
    }
  };

  const applyStandard = tags => {
    setUpdateSpinner(true);
    if (standard === "") return;
    fetch(config.apiUrl + "/tagging/guided_validation", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      },
      method: "POST",
      body: JSON.stringify({
        tags: tags,
        standard: "latest"
      })
    })
      .then(response => response.json())
      .then(response => {
        if (!response.status) {
          throw Error("Failure in validate API");
        } else if (response.status === "fail") {
          throw Error(response.message);
        } else if (response.status === "incomplete compliance") {
          setUpdateSpinner(false);
          setMessage(
            <div>
              <ul>
                {response.reasons.map((error, index) => (
                  <li key={index}>
                    <pre>{error}</pre>
                  </li>
                ))}
              </ul>
            </div>
          );
        } else {
          setUpdateSpinner(false);
          setMessage(
            <div>
              <h2
                className="epic-font-brand"
                style={{
                  textAlign: "left",
                  fontSize: "14",
                  color: "green"
                }}
              >
                Tag configuration is compliant
              </h2>
            </div>
          );
        }
      })
      .catch(error => {
        console.log(error);
        setUpdateSpinner(false);
      });
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleClick = () => {
    setMessage("");
    if (isValid()) {
      let valueObj = JSON.parse(value);
      applyStandard(valueObj);
      setValue(JSON.stringify(valueObj, null, 2));
    }
  };

  return (
    <div style={{ width: "50%", margin: "1em" }}>
      <Section title="Tag Check" subtitle="JSON format">
        <p>Enter tags in JSON format as key-value pairs:</p>
        <div style={{ margin: "1em" }}>
          <code>
            <pre>{exampleTags}</pre>
          </code>
        </div>
        <TextArea
          label="Tags"
          style={{ height: "300px" }}
          placeholder={exampleTags}
          onChange={e => handleChange(e)}
          value={value}
        />
        <Button onClick={handleClick}>Validate</Button>
        <div style={{ margin: "1em 1em 1em", lineHeight: "150%" }}>
          {updateSpinner ? <Spinner /> : ""}
          {message ? message : ""}
        </div>
      </Section>
    </div>
  );
};
