import store from './store.js'
import { makeToken, decodeToken } from './token.js'
import { refreshSilentLogin } from './silentLogin.js'
import { innerLogoutUser, setLoginError } from './actions.js'
import { selectIsSilentAuthRunning, selectIsLoggedIn } from './reducer.js'
import {
  TOKEN_KEY,
  LOCATION_KEY,
  RECOVERY_KEY,
  RANDOM_CHAR_SET,
  CODE_VERIFIER_KEY
} from './constants.js'
import { CODE_CHALLENGE_METHOD, computeChallenge, getToken, makeVerifier } from './pkce.js'

export const logout = () => store.removeItem(TOKEN_KEY)

const genRandomString = length => {
  let random = ''

  for (let c = 0, cl = RANDOM_CHAR_SET.length; c < length; ++c) {
    random += RANDOM_CHAR_SET[Math.floor(Math.random() * cl)]
  }

  return random
}

const generateNonce = () => genRandomString(64)

const tokenIfNotExpired = token => (!token || token.isExpired ? null : token)

export const getTokenFromStore = () => {
  let token = JSON.parse(store.getItem(TOKEN_KEY))

  if (token === null || token === undefined) {
    return null
  }

  return tokenIfNotExpired(makeToken(token))
}

const getTokenFromCode = (config, params) => {
  config.code_verifier = store.getItem(CODE_VERIFIER_KEY)
  config.code = params.get('code')

  if (!config.code) return null

  store.removeItem(CODE_VERIFIER_KEY)

  return getToken(config)
}

const getParamsFromUrl = url => new URLSearchParams(url.slice(1))

const getParamObject = params => {
  let result = {}
  params.forEach((value, key) => (result[key] = value))
  return result
}

export const getTokenFromRedirect = async config => {
  let url = new URL(window.location.href)

  if (!url.hash && !url.search) return null

  const hash = getParamsFromUrl(url.hash)
  const search = getParamsFromUrl(url.search)

  const responseParams = url.hash ? hash : search

  if (responseParams.get('error')) {
    throw new Error(`${responseParams.get('error')}: ${responseParams.get('error_description')}`)
  }

  let token
  switch (config.response_type) {
    case 'authorization_code':
      token = await getTokenFromCode(config, responseParams)
      break
    case 'id_token':
    case 'token':
    case 'id_token token':
      token = getParamObject(responseParams)
      break
    default:
      throw new Error('response_type is required')
  }
  let decodedToken = decodeToken(token)

  if (!decodedToken) return null

  if (config.keepToken) store.setItem(TOKEN_KEY, JSON.stringify(decodedToken))

  return tokenIfNotExpired(decodedToken)
}

export const getTokenFromRedirectOrStore = async config =>
  getTokenFromStore() || (await getTokenFromRedirect(config))

export const getLoginUrl = async ({ config, skipPrompt, state } = {}) => {
  state = state || 1

  if (config.scope && !config.scope.includes('openid')) {
    throw new Error('Error logging in: the list of scopes must include openid')
  }

  let params = new URLSearchParams()

  params.set('response_type', config.response_type)
  params.set('client_id', config.client_id)
  params.set('scope', config.scope || 'profile openid email idpType')
  params.set('redirect_uri', config.redirect_uri)
  params.set('nonce', generateNonce())
  params.set('state', state)

  if (skipPrompt) params.set('prompt', 'none')

  if (config.response_type === 'authorization_code') {
    params.set('response_type', 'code')

    let verifier = makeVerifier()
    store.setItem(CODE_VERIFIER_KEY, verifier || '')

    params.set('code_challenge_method', CODE_CHALLENGE_METHOD)
    params.set('code_challenge', await computeChallenge(verifier))
  }

  return new URL('?' + params.toString(), config.authorization).href
}

export const login = config => async getState => {
  let route = {
    pathname: window.location.pathname,
    query: window.location.search ? getParamObject(getParamsFromUrl(window.location.search)) : null,
    hash: window.location.hash || null
  }

  store.setItem(LOCATION_KEY, JSON.stringify(route))
  store.setItem(RECOVERY_KEY, JSON.stringify(getState()))

  return window.location.assign(
    await getLoginUrl({
      config,
      state: RECOVERY_KEY
    })
  )
}

export const getPreviousLocation = () => {
  let location = JSON.parse(store.getItem(LOCATION_KEY))

  if (location) {
    store.removeItem(LOCATION_KEY)

    return location
  }

  return null
}

let tokenExpirationWatcher = null
export const watchForTokenExpiration = (config, token, auth) => (dispatch, getState) => {
  if (tokenExpirationWatcher) clearInterval(tokenExpirationWatcher)

  tokenExpirationWatcher = setInterval(async () => {
    if (selectIsSilentAuthRunning(getState()) || !selectIsLoggedIn(getState())) {
      return
    }

    if (!token.isExpired && token.shouldRefresh) {
      return await refreshSilentLogin(config, auth)(dispatch, getState)
    }

    if (token.isExpired) {
      try {
        return await refreshSilentLogin(config, auth)(dispatch, getState)
      } catch (error) {
        console.error('error refreshing login', error)
        store.setItem(RECOVERY_KEY, JSON.stringify(getState()))
        dispatch(setLoginError('Login Expired. You must log in again'))
        await innerLogoutUser(auth)(dispatch, getState)
        clearInterval(tokenExpirationWatcher)
        tokenExpirationWatcher = null
      }
    }
  }, 1000)
}
