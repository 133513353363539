import React, { useState, useEffect } from "react";
import config from "../config.js";
import { connect } from "react-redux";
import { Spinner, Scrollspy } from "@nike/epic-react-ui";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import APIError from "../components/APIError.js";
import { RulesTable } from "./TaggingRulesTables.js";
import { RulesSummary } from "./TaggingSummary.js";

const TaggingRules = props => {
  const [rules, setRules] = useState([]);
  const [baseRules, setBaseRules] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [panels, setPanels] = useState([]);
  const [rulesComplete, setRulesComplete] = useState(false);
  const [baseComplete, setBaseComplete] = useState(false);

  useEffect(() => {
    fetch(config.apiUrl + "/tagging/rules", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        var base = [];
        var local = response.rules;
        base.push(local);
        //console.log(base);
        setBaseRules(base);
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
  }, [props.accessToken]);

  useEffect(() => {
    const getData = async () => {
      const res = await fetch(
        config.apiUrl + "/tagging/rules/FY23-tagging-standard",
        {
          headers: {
            Authorization: "Bearer " + props.accessToken,
            "Cache-Control": "no-cache"
          }
        }
      );
      const response = await res.json();
      if (response.status === "fail") {
        // console.log(indicies);
        setErrorMessage(response);
      } else {
        //formatRules(response.rules.key_rules);
        //setRules(local);
        var formattedRules = [];
        for (var i in response.rules.key_rules) {
          var rule = response.rules.key_rules[i];
          //console.log(rule);
          formattedRules.push(rule);
          //console.log(rule);
        }
        setRules(formattedRules);
      }
    };
    getData();
  }, [props.accessToken]);

  useEffect(() => {
    let localPanels = panels;
    let exists = false;
    if (baseRules.length > 0) {
      for (var panel in localPanels) {
        console.log(localPanels[panel]);
        if (localPanels[panel].text === "Summary") {
          exists = true;
        }
      }
      if (!exists) {
        localPanels.push({
          text: "Summary",
          hash: "summary",
          component: (
            <RulesSummary key="summary" id="summary" rules={baseRules} />
          )
        });
        setPanels(localPanels);
      }
    }
    //console.log(localPanels);
    setBaseComplete(true);
  }, [baseRules, panels, baseComplete, rules]);

  useEffect(() => {
    let localPanels = panels;
    if (localPanels.length > 0 && localPanels.length < rules.length) {
      if (rules.length > 0) {
        //console.log(rules);
        for (var i in rules) {
          localPanels.push({
            text: rules[i].tag_key,
            hash: rules[i].tag_key,
            component: (
              <RulesTable
                key={rules[i].tag_key}
                id={rules[i].tag_key}
                rule={rules[i]}
              />
            )
          });
        }
      }
      setPanels(localPanels);
      //console.log(localPanels);
      setRulesComplete(true);
    }
  }, [rules, panels, rulesComplete]);

  // const applyStandard = e => {
  //   return;
  // }

  //console.log(panels);
  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
        }
        .epic__sectionContent__947 {
          margin-top: 0px
        }
        .epic__container__d0a.epic__disabled__f8e {
          opacity: 1;
        }
        .epic__NextGenSelect__dea .NextGenSelect__control .NextGenSelect__indicators {
          color: #e5e5e5;
          padding: 5px;
        }`}
      </style>
      {!baseComplete && !rulesComplete ? (
        !errorMessage ? (
          <div>
            <Spinner overlay={false} show={true} />
          </div>
        ) : (
          <APIError showTitle={true} paddingLeft="1em" error={errorMessage} />

          // <APIError error={errorMessage} />
        )
      ) : (
        <div className="scrollspy-container">
          {errorMessage}
          <Scrollspy
            title="Tagging Rules"
            items={panels}
            uppercaseTitle={true}
            uppercaseItems={true}
            className="scrollspy-menu"
          />
          <div className="scrollspy-content">
            {panels.map(data => {
              //console.log(data);
              return data.component;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(TaggingRules);
