import { createAction } from "@nike/redux-action-utils";
import { asyncAction } from "../util/redux-actions";

export const setIndex = asyncAction({
  baseName: "RESOURCE_INDEX_SET",
  action: action => index => dispatch => {
    dispatch(action.start(index));
  }
});

export const clearIndexes = createAction("INDEXES_CLEAR");
