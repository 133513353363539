import React, { useState, useEffect } from "react";
import {
  Section,
  Spinner,
  Select,
  Button,
  Icon,
  Modal
} from "@nike/epic-react-ui";
import config from "../config.js";
import { navigate } from "raviger";
import { SortableTable } from "../components/table/index.js";
import APIError from "../components/APIError.js";

let basePath =
  window.location.href.indexOf("platforms.nike.com") !== -1 ? "/cloudred" : "";
let waffleUrl = "https://ui.waffleiron-prod.nikecloud.com";

export const Costing = props => {
  const key = props.data.key;
  const systems = props.collection.systems;
  const breakout = props.breakout;
  const [errorMessageAct, setErrorMessageAct] = useState(null);
  const [errorMessageApp, setErrorMessageApp] = useState(null);
  const [errorMessageOvr, setErrorMessageOvr] = useState(null);
  // const [costingBreakout, setCostingBreakout] = useState(null);
  const [costingOverall, setCostingOverall] = useState(null);
  const [costingAccts, setCostingAccts] = useState(null);
  const [costingApps, setCostingApps] = useState(null);
  // const [accountBreakout, setAccountBreakout] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    value: "prev",
    label: "Previous Month"
  });
  const [showModal, setModal] = useState(false);
  const [showDisclaimer, setDisclaimer] = useState(false);
  // const [accountFocus, setAccountFocus] = useState(null);

  let dateList = [
    { value: "mtd", label: "Month to date" },
    { value: "prev", label: "Previous month" }
  ];

  // get costing for the SC
  // useEffect(() => {
  //   setCostingBreakout(null);
  //   setCostingAccounts(null);
  //   setCostingApps(null);
  //   setcostingOverall(null);
  //   let url =
  //     config.apiUrl +
  //     "/ssot/ous/costing/" +
  //     key +
  //     "?date_range=" +
  //     selectedDate.value;

  //   fetch(url, {
  //     headers: {
  //       Authorization: "Bearer " + props.accessToken,
  //       "Cache-Control": "no-cache"
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.status !== "success") {
  //         throw Error(response.message);
  //       } else {
  //         setCostingBreakout(response);
  //       }
  //     });
  //   // sample data
  //   // let response = {
  //   //   status: "success",
  //   //   total_cost: [
  //   //     {
  //   //       Month: "2021-02",
  //   //       Cost: "23452"
  //   //     }
  //   //   ],
  //   //   accounts: [
  //   //     {
  //   //       Month: "2021-02",
  //   //       Cost: "",
  //   //       Account: "508391548488"
  //   //     }
  //   //   ],
  //   //   applications: [
  //   //     {
  //   //       NikeTagguid: "866d23ee-87a6-4b64-b143-770a9b8c6828",
  //   //       TagGuid: "",
  //   //       AppDefined02: "",
  //   //       Cost: "333.33532423",
  //   //       Month: ""
  //   //     },
  //   //     {
  //   //       NikeTagguid: "9f1a83de-a0af-4a48-b147-2c3c61192526",
  //   //       TagGuid: "",
  //   //       AppDefined02: "",
  //   //       Cost: "21134.3334",
  //   //       Month: ""
  //   //     },
  //   //     {
  //   //       NikeTagguid: "a832062d-f526-477f-9dc4-4a65709f5844",
  //   //       TagGuid: "",
  //   //       AppDefined02: "",
  //   //       Cost: "",
  //   //       Month: ""
  //   //     }
  //   //   ]
  //   // };
  //   // setCostingBreakout(response);
  // }, [selectedDate, key, props.accessToken]);

  useEffect(() => {
    if (systems.length > 0) {
      setCostingOverall(null);
      let url =
        config.apiUrl +
        "/ssot/ous/costing/" +
        key +
        "?group=overall&date_range=" +
        selectedDate.value;

      return; // disabled until we move to the Entities API
      /*fetch(url, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.status === "fail") {
            console.log(response);
            setErrorMessageOvr(response);
          } else if (!response.status) {
            throw Error("Failure in Cost Retrieval");
          } else {
            setCostingOverall(response);
            setErrorMessageOvr(null);
          }
        })
        .catch(error => {
          console.log(error);
          setErrorMessageOvr(error);
        });*/
    }
  }, [selectedDate, key, props.accessToken, systems]);

  useEffect(() => {
    if (systems.length > 0) {
      if (breakout.applications.length > 0) {
        setCostingApps(null);
        let url =
          config.apiUrl +
          "/ssot/ous/costing/" +
          key +
          "?group=application&date_range=" +
          selectedDate.value;

        return; // disabled until we move to the Entities API
        /*fetch(url, {
          headers: {
            Authorization: "Bearer " + props.accessToken,
            "Cache-Control": "no-cache"
          }
        })
          .then(response => response.json())
          .then(response => {
            if (response.status === "fail") {
              console.log(response);
              setErrorMessageApp(response);
            } else if (!response.status) {
              throw Error("Failure in Cost Retrieval");
            } else {
              setCostingApps(response);
              setErrorMessageApp(null);
            }
          })
          .catch(error => {
            console.log(error);
            setErrorMessageApp(error);
          });*/
      } else {
        setCostingApps({ applications: [] });
      }
    }
  }, [selectedDate, key, props.accessToken, systems, breakout]);

  useEffect(() => {
    if (systems.length > 0) {
      if (breakout.accounts.length > 0) {
        setCostingAccts(null);
        let url =
          config.apiUrl +
          "/ssot/ous/costing/" +
          key +
          "?group=aws_account&date_range=" +
          selectedDate.value;

        return; // disabled until we move to the Entities API
        /*fetch(url, {
          headers: {
            Authorization: "Bearer " + props.accessToken,
            "Cache-Control": "no-cache"
          }
        })
          .then(response => response.json())
          .then(response => {
            if (response.status === "fail") {
              console.log(response);
              setErrorMessageAct(response);
            } else if (!response.status) {
              throw Error("Failure in Cost Retrieval");
            } else {
              setCostingAccts(response);
              setErrorMessageAct(null);
            }
          })
          .catch(error => {
            console.log(error);
            setErrorMessageAct(error);
          });*/
      } else {
        setCostingAccts({ accounts: [] });
      }
    }
  }, [selectedDate, key, props.accessToken, systems, breakout]);

  // get cost for account broken out by managed/unmanaged
  // const getAccount = () => {
  //     setAccountBreakout(null);
  //     let url = config.apiUrl + "/ssot/ous/costing/account/" + key;

  //   fetch(url, {
  //     headers: {
  //       Authorization: "Bearer " + props.accessToken,
  //       "Cache-Control": "no-cache"
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.status === "fail") {
  //         setCosting(response);
  //         throw Error(response.message);
  //       } else {
  //         const accountAryBreakout = [];
  //         let report = response.data.asyncPreviewFlexReportResponseStatus.preview.csv.split(
  //           "\n"
  //         );
  //         // --------------------------------------------
  //         // fancy magic to convert report into managed, unamanged, total
  //         // --------------------------------------------
  //         setAccountBreakout(accountAryBreakout);
  //       }
  //     })
  //     .catch(error => console.log(error));

  //   dummy data and sleep until SC cost endpoint is ready
  //   setTimeout(() => {
  //     const accountAryBreakout = [];

  //     const managed = Math.random() * (10000 - 1) + 1;
  //     const unmanaged = Math.random() * (10000 - 1) + 1;
  //     accountAryBreakout.push({
  //       managed: formatter.format(managed),
  //       unmanaged: formatter.format(unmanaged),
  //       total: formatter.format(managed + unmanaged)
  //     });
  //     setAccountBreakout(accountAryBreakout);
  //   }, 2000);
  //   end dummy data
  // };

  // formatting function for currency
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  const tblPropsOverall = {
    data: costingOverall
      ? costingOverall["total_cost"].length > 0 &&
        costingOverall["total_cost"][0]["Cost"]
        ? costingOverall["total_cost"].map(data => ({
            total: data.Cost
          }))
        : [
            {
              total: (
                <span>
                  No Data
                  <Icon
                    type="warning"
                    fontSize="20px"
                    className="noChDataIcon"
                  />
                </span>
              )
            }
          ]
      : [],
    columns: [
      {
        Header: "Total",
        accessor: "total",
        Cell: props => (
          <div>
            {props.cell.value.props
              ? props.cell.value
              : formatter.format(props.cell.value)}
          </div>
        )
      }
    ]
  };

  const costingApplications = [];
  if (costingApps && costingApps["applications"].length > 0) {
    costingApps["applications"].forEach(a => {
      let realTagguid = "";
      if (a.NikeTagguid) {
        realTagguid = a.NikeTagguid;
      } else if (a.TagGuid) {
        realTagguid = a.TagGuid;
      } else {
        realTagguid = a.AppDefined02;
      }
      if (!breakout.applications.find(h => h.system_id === realTagguid)) {
        return;
      }
      costingApplications.push({
        id: (
          <a
            className="dashLink"
            href={basePath + "/system/" + realTagguid}
            target="_blank"
            rel="noopener noreferrer"
          >
            {realTagguid}
          </a>
        ),
        name: (
          <a
            className="dashLink"
            href={basePath + "/system/" + realTagguid}
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              breakout.applications.find(h => h.system_id === realTagguid)
                .system_name
            }
          </a>
        ),
        owner: breakout.applications.find(h => h.system_id === realTagguid)
          .system_owner,
        cost: a.Cost ? (
          a.Cost
        ) : (
          <span>
            No Data
            <Icon type="warning" fontSize="20px" className="noChDataIcon" />
          </span>
        )
      });
    });
  }

  const tblPropsApplications = {
    data: costingApps
      ? costingApps["applications"].length > 0
        ? costingApplications.map(data => ({
            id: data.id,
            cost: data.cost,
            name: data.name,
            owner: data.owner
          }))
        : []
      : [],
    columns: [
      {
        Header: "Name",
        accessor: "name"
      },
      // {
      //   Header: "ID",
      //   accessor: "id"
      // },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: props => (
          <div
            style={
              props.cell.value.length > 30 ? { wordBreak: "break-word" } : {}
            }
          >
            {props.cell.value}
          </div>
        )
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell: props => (
          <div>
            {props.cell.value.props
              ? props.cell.value
              : formatter.format(props.cell.value)}
          </div>
        )
      }
    ]
  };

  const costingAccounts = [];
  if (costingAccts && costingAccts["accounts"].length > 0) {
    costingAccts["accounts"].forEach(a => {
      costingAccounts.push({
        account_id: (
          <a
            href={waffleUrl + "/account/" + a.Account}
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            {a.Account}
          </a>
        ),
        id: (
          <a
            className="dashLink"
            href={
              basePath +
              "/system/" +
              breakout.accounts.find(h => h.account_id === a.Account).system_id
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {breakout.accounts.find(h => h.account_id === a.Account).system_id}
          </a>
        ),
        name: (
          <a
            className="dashLink"
            href={
              basePath +
              "/system/" +
              breakout.accounts.find(h => h.account_id === a.Account).system_id
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              breakout.accounts.find(h => h.account_id === a.Account)
                .system_name
            }
          </a>
        ),
        owner: breakout.accounts.find(h => h.account_id === a.Account)
          .account_owner
          ? breakout.accounts.find(h => h.account_id === a.Account)
              .account_owner
          : breakout.accounts.find(h => h.account_id === a.Account)
              .system_owner,
        account_type: breakout.accounts.find(h => h.account_id === a.Account)
          .account_type,
        cost: a.Cost ? (
          a.Cost
        ) : (
          <span>
            No Data
            <Icon type="warning" fontSize="20px" className="noChDataIcon" />
          </span>
        )
      });
    });
  }

  const tblPropsAccounts = {
    data: costingAccts
      ? costingAccts["accounts"].length > 0
        ? costingAccounts.map(data => ({
            account_id: data.account_id,
            id: data.id,
            cost: data.cost,
            name: data.name,
            owner: data.owner,
            account_type: data.account_type
            // actions: (
            //   <div style={{ textAlign: "center" }}>
            //     <Button
            //       small
            //       onClick={() => {
            //         setModal(true);
            //         getAccount();
            //         setAccountFocus(data.Account);
            //       }}
            //     >
            //       <Icon type="walkthrough" />
            //     </Button>
            //   </div>
            // )
          }))
        : []
      : [],
    columns: [
      {
        Header: "Name",
        accessor: "name"
      },
      // {
      //   Header: "ID",
      //   accessor: "id"
      // },
      {
        Header: "Account ID",
        accessor: "account_id"
      },
      {
        Header: "Account Type",
        accessor: "account_type"
      },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: props => (
          <div
            style={
              props.cell.value.length > 30 ? { wordBreak: "break-word" } : {}
            }
          >
            {props.cell.value}
          </div>
        )
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell: props => (
          <div>
            {props.cell.value.props
              ? props.cell.value
              : formatter.format(props.cell.value)}
          </div>
        )
      }
      // {
      //   Header: "",
      //   accessor: "actions"
      // }
    ]
  };

  const tblPropsAccount = {
    // data: accountBreakout ? accountBreakout : [],
    data: [],
    columns: [
      {
        Header: "Total",
        accessor: "total"
      },
      {
        Header: "Unmanaged",
        accessor: "unmanaged"
      },
      {
        Header: "Managed",
        accessor: "managed"
      }
    ]
  };

  const disclaimerModal = () => {
    return (
      <Modal
        onClose={() => setDisclaimer(false)}
        closeButton
        show={showDisclaimer}
        swoosh
      >
        <div style={{ padding: "20px" }}>
          Costing data shown here is queried directly from{" "}
          <a
            className="dashLink"
            href="https://apps.cloudhealthtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CloudHealth.
          </a>
          <br></br>
          <br></br>
          To learn more please see the{" "}
          <a
            className="dashLink"
            href="https://confluence.nike.com/pages/viewpage.action?spaceKey=CBO&title=CloudHealth+FAQ+v1.0"
            target="_blank"
            rel="noopener noreferrer"
          >
            CloudHealth FAQ
          </a>{" "}
          on Confluence.
        </div>
      </Modal>
    );
  };

  const createTableOverall = () => {
    return <SortableTable {...tblPropsOverall} />;
  };

  const createTableApplications = () => {
    return <SortableTable {...tblPropsApplications} />;
  };

  const createTableAccounts = () => {
    return <SortableTable {...tblPropsAccounts} />;
  };

  const createTableAccount = () => {
    return <SortableTable {...tblPropsAccount} />;
  };

  const finopsInfo = () => {
    return (
      <div
        style={{
          width: "70%",
          borderRadius: "1em",
          padding: "20px 1%",
          marginBottom: "30px",
          backgroundColor: "white"
        }}
      >
        <p>
          For the most accurate cloud cost data, please see the&nbsp;
          <a
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://dash.finops.nikecloud.com/"
          >
            FinOps Dashboard&nbsp;
          </a>
          and&nbsp;
          <a
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://developer.niketech.com/docs/projects/Entities%20API?tab=readme"
          >
            Entities API.&nbsp;
          </a>
          Both are superseding CloudHealth which is now deprecated. CloudRed
          will show System cost data from the Entities API in the future.
        </p>
        <br />
        <p>
          More information and support can be found below:&nbsp;
          <ul>
            <li>
              Entities API:&nbsp;
              <a
                className="dashLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://confluence.nike.com/display/CBO/Entities+API"
              >
                https://confluence.nike.com/display/CBO/Entities+API
              </a>
            </li>
            <li>
              FinOps Dashboard:&nbsp;
              <a
                className="dashLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://confluence.nike.com/display/CBO/Cloud+FinOps+Dashboard"
              >
                https://confluence.nike.com/display/CBO/Cloud+FinOps+Dashboard
              </a>
            </li>
            <li>
              Slack:&nbsp;
              <a
                className="dashLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://nikedigital.slack.com/archives/CFXB22A0N"
              >
                #cloud-finops
              </a>
            </li>
          </ul>
        </p>
      </div>
    );
  };

  return systems.length === 0 ? (
    <Section
      id={props.id}
      title="Costing"
      align="center"
      collapsible="True"
      collapsibleConfig={{ open: true }}
    >
      {"No systems in this SC"}
      <div style={{ marginBottom: "10px" }}></div>
      <Button
        title="Edit"
        onClick={() =>
          navigate(basePath + "/collection/" + props.collection.key)
        }
      >
        Edit SC
      </Button>
    </Section>
  ) : (
    <Section
      id={props.id}
      title="Costing"
      collapsible="True"
      collapsibleConfig={{ open: true }}
    >
      {disclaimerModal()}
      {finopsInfo()}
      <div style={{ width: "200px" }}>
        <Select
          className={"textInput"}
          options={dateList}
          value={selectedDate}
          onChange={selected => setSelectedDate(selected)}
        />
      </div>

      <div style={{ marginBottom: "30px" }}></div>
      <Section style={{ padding: "0px" }} id={props.id} title="Overall Cost">
        <div style={{ textAlign: "center" }}>
          {!costingOverall ? (
            !errorMessageOvr ? (
              // <Spinner />
              ""
            ) : (
              <APIError error={errorMessageOvr} showTitle={true} />
            )
          ) : costingOverall.length < 1 ? (
            <div className="epic-font-brand" style={{ fontSize: 18 }}>
              No costing data was found for this system.
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>{createTableOverall()}</div>
          )}
          <div
            className="disclaimerButton"
            align="center"
            style={{ display: "none" }}
          >
            <button onClick={() => setDisclaimer(true)}>
              <Icon
                type="information"
                fontSize="20px"
                className="disclaimerIcon"
              />
              Where does costing data come from?
            </button>
          </div>
        </div>
      </Section>
      <Section style={{ padding: "0px" }} id={props.id} title="Applications">
        <div style={{ textAlign: "center" }}>
          {!costingApps ? (
            !errorMessageApp ? (
              // <Spinner />
              ""
            ) : (
              <APIError error={errorMessageApp} showTitle={true} />
            )
          ) : costingApps.applications.length < 1 ? (
            "No applications in this SC"
          ) : (
            <div style={{ textAlign: "center" }}>
              {createTableApplications()}
            </div>
          )}
        </div>
      </Section>
      <Section style={{ padding: "0px" }} id={props.id} title="Accounts">
        <div style={{ textAlign: "center" }}>
          {!costingAccts ? (
            !errorMessageAct ? (
              // <Spinner />
              ""
            ) : (
              <APIError error={errorMessageAct} showTitle={true} />
            )
          ) : costingAccts.accounts.length < 1 ? (
            "No accounts in this SC"
          ) : (
            <div>
              <Modal
                onClose={() => setModal(false)}
                closeButton
                show={showModal}
              >
                {/* <h2>
                  {accountFocus
                    ? "Account: " + accountFocus
                    : "Account Details"}
                </h2> */}
                <h2>Account Details</h2>
                <p>Cost of Managed vs Unmanaged Resources</p>
                <div
                  style={{
                    margin: "30px"
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    {/* {!accountBreakout ? <Spinner /> : createTableAccount()} */}
                    {createTableAccount()}
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button onClick={() => setModal(false)}>Close</Button>
                </div>
              </Modal>
              <div style={{ textAlign: "center" }}>{createTableAccounts()}</div>
            </div>
          )}
        </div>
      </Section>
    </Section>
  );
};
