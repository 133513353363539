import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Summary, Costing, Compliance } from "./CollectionSummary";
import { Scrollspy, Spinner, Section } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import { clearIndexes } from "./CollectionActions";

const CollectionDynamicDashboard = props => {
  const key = props.data.key;
  const [collection, setCollection] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageBreakout, setErrorMessageBreakout] = useState(null);
  // const [systems, setSystems] = useState(null);
  const [panels] = useState([]);
  const dispatch = useDispatch();
  const [systemsBreakout, setSystemsBreakout] = useState(null);

  useEffect(() => {
    dispatch(clearIndexes());
  });

  useEffect(() => {
    if (collection && collection.systems.length > 0) {
      setSystemsBreakout(null);
      // fetch("http://127.0.0.1:5000/breakout", {
      fetch(config.apiUrl + "/ssot/systems/breakout", {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          systems: collection.systems
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.status === "fail") {
            console.log(response);
            setErrorMessageBreakout(response);
          } else if (!response.applications) {
            throw Error("Failure in SC System retrieval");
          } else {
            // response.accounts.forEach((acct, index) => {
            //   if (acct.account_owner) {
            //     fetch(config.apiUrl + "/identity/user/" + acct.account_owner, {
            //       headers: {
            //         Authorization: "Bearer " + props.accessToken
            //       }
            //     })
            //       .then(response => response.json())
            //       .then(response => {
            //         acct.account_owner = response[0].email;
            //       })
            //       .catch(error => console.log(error));
            //   }
            // });
            setSystemsBreakout(response);
            setErrorMessageBreakout(null);
          }
        })
        .catch(error => {
          console.log(error);
          setErrorMessageBreakout(error);
        });
    }
  }, [collection, props.accessToken]);

  useEffect(() => {
    // fetch("http://127.0.0.1:8000/" + key, {
    fetch(config.apiUrl + "/ssot/systems/?owner=" + key, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          console.log(response);
          setErrorMessage(response);
        } else {
          /*
          collection data model
          {
              "state": "active",
              "key": "1268e83d-da43-4cf5-88c1-3facb9ca548c",
              "name": "a",
              "description": "b",
              "owner": "Lst-EAP.CloudTools",
              "date_created": "1635373282.849107",
              "date_updated": "1663614803.547964",
              "is_admin": true,
              "systems": [
                  "aeb72ec5-c6f9-4ee6-8308-47880642f6d9",
                  "c03ef7c2-42cb-49c4-845b-dbb733b5744d",
                  "9f1a83de-a0af-4a48-b147-2c3c61192526",
                  "58f4eb23-dc5d-477c-900b-167f8ed93ac3",
                  "9d5b6fab-53a2-4e04-9199-b5a0ebb5f77f"
              ]
          }
          */
          var localCollection = {
            state: "active",
            key: null,
            name: "Dynamic Collection for " + key,
            description:
              "A dynamic collection of all of the systems where " +
              key +
              " is an owner",
            owner: null,
            is_admin: false,
            systems: []
          };
          for (var entry in response) {
            localCollection.systems.push(response[entry].key);
          }

          setCollection(localCollection);
          setErrorMessage(null);
          if (localCollection.systems.length === 0) {
            setSystemsBreakout({
              applications: [],
              accounts: [],
              gcp_rojects: [],
              azure_rgs: [],
              azure_subscriptions: []
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
  }, [key, panels, props]);

  if (collection) {
    panels.splice(0, panels.length);

    panels.push({
      text: "Summary",
      hash: "summary",
      component: (
        <Summary
          key="summary"
          id="summary"
          collection={collection}
          breakout={systemsBreakout}
          {...props}
        />
      )
    });
    panels.push({
      text: "Costing",
      hash: "costing",
      component: systemsBreakout ? (
        <Costing
          key="costing"
          id="costing"
          collection={collection}
          breakout={systemsBreakout}
          {...props}
        />
      ) : (
        <div key="123">
          <Section
            id="costingSection"
            title="Costing"
            align="center"
            collapsible="True"
            collapsibleConfig={{ open: true }}
          >
            {!errorMessageBreakout ? (
              <Spinner />
            ) : (
              <APIError error={errorMessageBreakout} showTitle={true} />
            )}
          </Section>
        </div>
      )
    });
    panels.push({
      text: "Tag Compliance",
      hash: "compliance",
      component: systemsBreakout ? (
        <Compliance
          key="compliance"
          id="compliance"
          collection={collection}
          breakout={systemsBreakout}
          {...props}
        />
      ) : (
        <div key="456">
          <Section
            id="complianceSection"
            title="Tag Compliance"
            align="center"
            collapsible="True"
            collapsibleConfig={{ open: true }}
          >
            {!errorMessageBreakout ? (
              <Spinner />
            ) : (
              <APIError error={errorMessageBreakout} showTitle={true} />
            )}
          </Section>
        </div>
      )
    });
  }

  return (
    // <div>
    //   <div className="scrollspy-container">
    //     <Scrollspy
    //       title="SC Dashboard"
    //       items={panels}
    //       uppercaseTitle={true}
    //       uppercaseItems={true}
    //       className="scrollspy-menu"
    //     />
    //     <div className="scrollspy-content">
    //       {systemsBreakout && collection ? (
    //         panels.map(data => {
    //           return data.component;
    //         })
    //       ) : (
    //         <Spinner overlay={false} show={true} />
    //       )}
    //     </div>
    //   </div>
    // </div>
    <div>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
        .epic__sectionContent__947 {
          margin-top: 0px;
        }
        .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__container__d0a.epic__disabled__f8e {
            opacity: 1;
        }`}
      </style>
      {!collection ? (
        !errorMessage ? (
          <div>
            <Spinner overlay={false} show={true} />
          </div>
        ) : (
          <APIError showTitle={true} paddingLeft="1em" error={errorMessage} />
        )
      ) : (
        <div className="scrollspy-container">
          <Scrollspy
            title="SC Dashboard"
            items={panels}
            uppercaseTitle={true}
            uppercaseItems={true}
            className="scrollspy-menu"
          />
          <div className="scrollspy-content">
            {panels.map(data => {
              return data.component;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(
  CollectionDynamicDashboard
);
