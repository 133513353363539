import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
// import classnames from "classnames";

// import styles from "./styles.styl";

// Static Value to ensure uniqueness
let ID = 0;

const Checkbox = forwardRef(function Checkbox(
  {
    className,
    labelClassName,
    checked,
    label,
    success,
    hasError,
    disabled,
    onValueChange,
    ...rest
  },
  ref
) {
  // useState initial-value function is only called on first-render
  const [_id] = useState(() => `ep-checkbox-${++ID}`);
  const id = rest.id || _id;

  // styles
  //   let labelClass = classnames(styles.label, labelClassName)
  //   let checkboxContainerClass = classnames(styles.checkboxContainer, className)
  //   let checkboxClass = styles.checkbox

  //   let colorStyle
  //   if (success) colorStyle = styles.success
  //   if (hasError) colorStyle = styles.hasError
  //   if (disabled) colorStyle = styles.disabled

  //   let colorStyle;
  //   if (success) colorStyle = styles.success;
  //   if (hasError) colorStyle = styles.hasError;
  //   if (disabled) colorStyle = styles.disabled;

  return (
    <div className="checkboxContainer">
      <input
        ref={ref}
        type="checkbox"
        // className="checkboxContainer"
        id={id}
        checked={checked}
        value={!!checked}
        disabled={disabled}
        onChange={e => {
          onValueChange && onValueChange(e.target.checked);
          return rest.onChange && rest.onChange(e);
        }}
        {...rest}
      />
      <label className="label" htmlFor={id}>
        {/* <span className="circle"></span> */}
        <svg
          className="checkboxContainer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            // className={classnames(styles.box, colorStyle)}
            className="box"
            x="0.75"
            y="0.75"
            width="22.5"
            height="22.5"
            rx="3.25"
            strokeWidth="1.5"
          />
          <path
            className="checkmark"
            d="M6.5 11.4999L10.2508 15.2507L17.5 8"
            strokeWidth="1.5"
          />
          <path
            className="indeterminate"
            d="M6.87296 12.127L17.1259 12.1259"
            strokeWidth="1.5"
          />
        </svg>
        {label ? (
          <span className="checkboxContainer textLabel">{label}</span>
        ) : null}
      </label>
      <br />
    </div>
  );
});

Checkbox.propTypes = {
  /** Sets className on container of Checkbox element */
  className: PropTypes.string,
  /** Sets the checkbox as checked or not by default */
  checked: PropTypes.bool,
  /** Sets label for element */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]),
  /** Sets the checkbox to a success state */
  success: PropTypes.bool,
  /** Sets whether input is in error state */
  hasError: PropTypes.bool,
  /** Sets the checkbox to a disabled state */
  disabled: PropTypes.bool,
  /** Handles Checkbox change event, invokes with new value */
  onValueChange: PropTypes.func,
  /** Handles Checkbox change event, invokes with React SyntheticEvent */
  onChange: PropTypes.func,
  /** Style the label */
  labelClassName: PropTypes.string
};

Checkbox.defaultProps = {
  label: "",
  className: ""
};

export default Checkbox;
