import React, { useState, useEffect } from "react";
import config from "../config.js";
import {
  Section,
  Collapsible,
  Icon,
  Select,
  Spinner,
  Button
} from "@nike/epic-react-ui";
import { ResourceItems } from "./SystemResourceItems.js";
import APIError from "../components/APIError.js";
import {
  returnAccountIndicesQuery,
  returnResourceIndiciesQuery,
  returnAzureIndicesQuery
} from "./SystemData.js";

export const Resources = props => {
  const key = props.data.key;
  const account = props.account;
  const type = props.system.type;
  const [indicies, setIndicies] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [standard, setStandard] = useState("");
  const [validating, setValidating] = useState(false);
  const [standards, setStandards] = useState([]);
  const [rules, setRules] = useState([]);
  const [errorMessageAgg, setErrorMessageAgg] = useState(null);
  const [errorMessageStd, setErrorMessageStd] = useState(null);
  const [formStatus, setFormStatus] = useState("");
  const [errorMessageAlerts, setErrorMessageAlerts] = useState(null);
  const [maintAlerts, setMaintAlerts] = useState(null);

  const formatRules = rules => {
    setRules(rules);
  };

  useEffect(() => {
    fetch(config.apiUrl + "/alerts/?status=open_upcoming&service=EC2_RDS", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setErrorMessageAlerts(response);
        } else if (!Array.isArray(response)) {
          console.log(response);
          throw Error("Failure in loading alerts");
        } else {
          setErrorMessageAlerts(null);
          setMaintAlerts(response);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageAlerts(error);
      });
  }, [props.accessToken]);

  useEffect(() => {
    fetch(config.apiUrl + "/tagging/standards", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setErrorMessageStd(response);
        } else if (!response.status) {
          throw Error("Failure in tag standards API");
        } else {
          setErrorMessageStd(null);
          const standardlst = [];
          response.standards.forEach((std, index) => {
            standardlst.push({
              value: std,
              label: std
            });
          });
          setStandards(standardlst);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageStd(error);
      });
  }, [props.accessToken]);

  useEffect(() => {
    let post_body = JSON.stringify(returnResourceIndiciesQuery(key));

    if (account)
      post_body = JSON.stringify(
        returnAccountIndicesQuery(account["accountId"])
      );
    else if (type.includes("azure"))
      post_body = JSON.stringify(returnAzureIndicesQuery(key));
    fetch(config.apiUrl + "/cloud-resources/customAggregates", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      },
      method: "POST",
      body: post_body
    })
      .then(indicies => indicies.json())
      .then(indicies => {
        if (indicies.status === "fail") {
          // console.log(indicies);
          setErrorMessageAgg(indicies);
        } else if (!indicies.hits) {
          throw Error("Failure in resources API");
        } else {
          setErrorMessageAgg(null);
          indicies = indicies.aggregations.groupbyindex.buckets;
          setIndicies(indicies);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageAgg(error);
      });
  }, [key, props.accessToken, account, type]);

  const applyStandard = e => {
    setValidating(true);
    e.preventDefault();
    if (standard.value === "") return;
    fetch(config.apiUrl + "/tagging/rules/" + standard.value, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          console.log(response);
          setFormStatus(
            <div className="epic-color-error system-info-message">
              <Icon className="system-info-status" type="warning" />
              <div>
                {
                  <APIError
                    error={response}
                    showTitle={true}
                    title="Validation failed"
                  />
                }
              </div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 2000);
          setValidating(false);
        } else if (!response.status) {
          throw Error("Unknown Error");
        } else {
          formatRules(response.rules.key_rules);
          setTimeout(() => {
            // console.log(alerts);
            // setAlerts([...alerts]);
            setValidating(false);
            setFormStatus(
              <div className="epic-color-success system-success-message">
                <Icon className="system-info-status" type="checkmark" />
                <div>{"Validation run successfully."}</div>
              </div>
            );
            setTimeout(() => {
              setFormStatus("");
            }, 2000);
          }, 3000);
        }
      })
      .catch(error => {
        console.log(error);
        setValidating(false);
        setFormStatus(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>
              {
                <APIError
                  error={error}
                  showTitle={true}
                  title="Validation failed"
                />
              }
            </div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 2000);
      });
  };

  // const validateButton = () => {
  //   return validating ? (
  //     <Spinner />
  //   ) : (
  //     <div style={{ position: "relative", left: "250px" }}>
  //       <Button
  //         style={{ height: "37px" }}
  //         onClick={e => applyStandard(e)}
  //       >
  //         Validate
  //       </Button>
  //     </div>
  //   );
  // };

  const validateButton = (
    <Button
      onClick={e => applyStandard(e)}
      disabled={
        validating
          ? true
          : indicies && indicies.length > 0
          ? standard
            ? false
            : true
          : true
      }
    >
      Validate
    </Button>
  );

  const validateSpinner = validating ? (
    <Spinner
      className="spinnerReg"
      style={{ float: "right", margin: "10px", marginLeft: "10px" }}
    />
  ) : (
    ""
  );

  const makeSection = () => {
    if (indicies.length === 0) {
      return (
        <Collapsible title="Resources">
          No resources have been discovered associated with this system.
        </Collapsible>
      );
    } else {
      return indicies.map(data => (
        <Collapsible
          key={data.key}
          isOpen={false}
          title={
            <div>
              {alerts.filter(alert => alert.index === data.key).length > 0 ? (
                <Icon
                  className="system-info-status epic-color-error"
                  type="warning"
                />
              ) : (
                ""
              )}

              {data.key.replace(/_/g, " ")}
            </div>
          }
          subtitle={
            data.doc_count <= 10000 ? (
              "Number of Resources: " + data.doc_count
            ) : (
              <div>
                Number of Resources: {data.doc_count}
                <br />
                <span style={{ color: "red" }}>
                  System will only display up to 10,000 resources in the table
                </span>
              </div>
            )
          }
        >
          <ResourceItems
            index={data.key}
            mergeAlerts={{ alerts: alerts, setAlerts: setAlerts }}
            maintAlerts={maintAlerts}
            rules={rules}
            standard={standard}
            {...props}
          />
        </Collapsible>
      ));
    }
  };

  const noAlertWarning = () => {
    if (errorMessageAlerts) {
      return (
        <div className="warningText">
          Warning: Resource alerts did not load correctly; data is complete
          except for alerts.
          <APIError error={errorMessageAlerts} showTitle={false} />
        </div>
      );
    }
  };

  return (
    <div>
      <Section key={props.id} id={props.id} title={props.title}>
        {!errorMessageAgg ? (
          <div>
            {noAlertWarning()}
            {account ? (
              <div className="epic-font-base-md">Account Resources Notice</div>
            ) : (
              ""
            )}
            {account ? (
              <div style={{ marginBottom: "2em" }}>
                <p>
                  The resources in this section will only include resources that
                  do not have a nike-tagguid. If you are looking for a resource
                  and can't find it refer to the associated systems section to
                  see the systems that are managing resources in this account.
                  <br />
                </p>
                <p style={{ padding: "0em", fontStyle: "italic" }}>
                  The resources older than 24 hours are not included in this
                  search results. You must use the&nbsp;
                  <a
                    style={{ fontSize: "16px" }}
                    className="dashLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://cloudred.nike.com/search/"
                  >
                    search page&nbsp;
                  </a>
                  to get results older than 24 hours.
                </p>
              </div>
            ) : (
              <br />
            )}
            <div className="epic-font-base-md">Standards</div>
            <p style={{ padding: "1em", fontStyle: "italic" }}>
              CCoE requires that all resources in the cloud has a set of
              standard tags. To validate these standards against the resources
              on this system, choose from the drop down and click "Validate".
              For more information, please visit this link: <br />
              <a
                style={{ fontSize: "18px" }}
                className="dashLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://confluence.nike.com/display/IECG/Nike+Cloud+Tagging+Standard"
              >
                Nike Cloud Tagging Standard documentation
              </a>
            </p>
            <br />
            {indicies && indicies.length > 0 ? (
              standards.length > 0 ? (
                <div
                  style={{
                    paddingBottom: "25px"
                  }}
                >
                  <div>{formStatus}</div>
                  <div
                    style={{
                      position: "absolute",
                      width: "200px"
                    }}
                  >
                    <Select
                      className={"cr-tag-standard"}
                      options={standards}
                      value={standard}
                      onChange={selected => setStandard(selected)}
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      width: "fit-content",
                      left: "200px"
                    }}
                  >
                    {validateButton}
                    {validateSpinner}
                  </div>
                </div>
              ) : !errorMessageStd ? (
                <Spinner />
              ) : (
                <APIError
                  showTitle={true}
                  title="Error loading tag standards"
                  error={errorMessageStd}
                />
              )
            ) : (
              <div></div>
            )}
            {indicies !== null ? makeSection() : <Spinner />}
          </div>
        ) : (
          <APIError showTitle={true} error={errorMessageAgg} />
        )}
      </Section>
    </div>
  );
};
