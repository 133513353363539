import React from "react";
import PropTypes from "prop-types";
import { PaginationTable } from "@nike/epic-react-ui";
import { useState, useEffect } from "react";

const FilterableSortTable = ({ columns, data, fields }) => {
  const [filteredTableData, setFilteredTableData] = useState(data);
  const [filters, setFilters] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({});
  const isCompact = true;
  const pageSizeOptions = [10, 25, 50, 100];
  const withSearchBar = true;

  useEffect(() => {
    if (data !== filteredTableData) {
      setFilteredTableData(data);
      onFiltersChange(currentFilters);
    }
  }, [data]);

  useEffect(() => {
    var generatedFilters = [];
    for (var field of fields) {
      var valueFilters = [];
      var newFilter = {
        name: field,
        accessor: field,
        filters: []
      };
      for (var entry of data) {
        if (!valueFilters.includes(entry[field])) {
          newFilter.filters.push({
            name: entry[field],
            accessor: entry[field]
          });
          valueFilters.push(entry[field]);
        }
      }
      generatedFilters.push(newFilter);
    }
    setFilters(generatedFilters);
    //console.log(generatedFilters);
  }, [data, fields]);

  const onFiltersChange = filterState => {
    setCurrentFilters(filterState);
    var filteredData = [];
    for (var entry of data) {
      var matchCount = 0;
      var emptyFilters = true;
      var filterCount = 0;
      for (var activeFilter in filterState) {
        if (filterState[activeFilter].length !== 0) {
          filterCount += 1;
          emptyFilters = false;
          if (filterState[activeFilter].includes(entry[activeFilter])) {
            //console.log(entry);
            matchCount += 1;
          }
        }
      }
      if (matchCount === filterCount) {
        //console.log(entry);
        filteredData.push(entry);
      }
    }
    if (emptyFilters) {
      setFilteredTableData(data);
    } else {
      setFilteredTableData(filteredData);
    }
  };

  /* eslint-disable */
  // We disable this because react-table handles all the key logic
  return (
    <div>
      <PaginationTable
        {...{
          isCompact,
          columns,
          data: filteredTableData,
          filters,
          onFiltersChange,
          pageSizeOptions,
          withSearchBar
        }}
      />
    </div>
  );
  /* eslint-enable */
};

FilterableSortTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object)
};

export default FilterableSortTable;
