import React from "react";
import { Link } from "raviger";
import { routesNav } from "../root/routes";
import config from "../config.js";
import { NavBar, Icon } from "@nike/epic-react-ui";

let basePath =
  window.location.href.indexOf("platforms.nike.com/cloudred") !== -1
    ? "/cloudred"
    : "";

const CloudRedNavBar = () => (
  <NavBar
    menuType="expand"
    name=""
    logo={config.appLogo}
    routes={routesNav}
    RouterLink={({ href, ...props }) => (
      <Link href={href} basePath={basePath} {...props} />
    )}
    rootPath="/"
    aside={
      <a
        style={{ margin: "1em" }}
        href={config.appFeedbackUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Feedback <Icon type="contact" />
      </a>
    }
    fixed={true}
  />
);

export default CloudRedNavBar;
