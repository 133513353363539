import React from "react";
import { navigate } from "raviger";

import { Card } from "@nike/epic-react-ui";
import Hero from "./Hero";

let basePath =
  window.location.href.indexOf("platforms.nike.com") !== -1 ? "/cloudred" : "";

const HomePage = () => (
  <div style={{ margin: "60px 0px 0px" }}>
    <Hero />
    <div className="card-container">
      <Card
        className="card"
        title="Register a System"
        hover
        description="Register a system in CloudRed and start using the cloud services provided to you!"
        onClick={() => navigate(basePath + "/system/register")}
      />
      <Card
        className="card"
        title="Register a Systems Collection"
        hover
        description="Register a systems collection in CloudRed to group systems together for reporting."
        onClick={() => navigate(basePath + "/collection/register")}
      />
      <Card
        className="card"
        title="Request AWS Account"
        hover
        description="Redirect to Waffle to get your own AWS accounts.  New accounts work with CloudRed immediately."
        onClick={() => {
          window.location.href =
            "https://ui.waffleiron-prod.nikecloud.com/accounts/create";
        }}
      />
      <Card
        className="card"
        title="Tagging Compliance Dashboard"
        hover
        description="View tagging compliance by various filters."
        onClick={() => navigate(basePath + "/compliance")}
      />
      <Card
        className="card"
        title="Tagging Rules Explorer"
        hover
        description="View Enterprise Tagging Standard rules in an simplified view"
        onClick={() => navigate(basePath + "/tagging/explorer")}
      />
    </div>
  </div>
);

export default HomePage;
