import React, { useState } from "react";
import { StaticTable, Button, Icon, Modal } from "@nike/epic-react-ui";

import { validate_tags } from "./SystemAlertFunctions.js";

export const AlertEngine = props => {
  const clearAlerts = () => {
    while (
      props.mergeAlerts.alerts.filter(
        alert => alert.resourceid === props.resource.ID
      ).length > 0
    ) {
      for (var i = 0; i < props.mergeAlerts.alerts.length; i++) {
        if (props.mergeAlerts.alerts[i].resourceid === props.resource.ID) {
          props.mergeAlerts.alerts.splice(i, 1);
          break;
        }
      }
    }
  };

  const runMaintAlerts = () => {
    if (Array.isArray(props.maintAlerts)) {
      const resourceMaintAlerts = props.maintAlerts.filter(alert => {
        // generate resource id from ARN since we
        // no longer have full ES results coming this far
        var resourceId = props.resource.ID.split(/[:/]+/).pop();
        if (!props.resource) return false;
        else if (
          props.index === "aws_ec2_instances" &&
          resourceId === alert.resourceid
        )
          return true;
        else if (
          props.index === "aws_rds_db_clusters" &&
          resourceId === alert.resourceid &&
          props.resource.REGION === alert.region
        ) {
          return true;
        } else if (
          props.index === "aws_rds_db_instances" &&
          resourceId === alert.resourceid &&
          props.resource.REGION === alert.region
        )
          return true;
        else return false;
      });

      if (resourceMaintAlerts.length === 0) return;

      let newAlerts = [];
      resourceMaintAlerts.forEach((data, index) => {
        newAlerts.push({
          resourceid: props.resource.ID,
          index: props.index,
          topic: "Maintenance Alert",
          messages: data.description
        });
      });
      // console.log(newAlerts);

      props.mergeAlerts.setAlerts([...props.mergeAlerts.alerts, ...newAlerts]);
    }
  };

  const runTagCheck = () => {
    if (props.standard === "") return;
    if (!props.resource.STD_Tags) return;

    const resourceTags = {};
    let validation_resp = null;
    if (Array.isArray(props.resource.STD_Tags))
      props.resource.STD_Tags.forEach((obj, index) => {
        resourceTags[obj.Key] = obj.Value;
      });

    validation_resp = validate_tags(resourceTags, props.rules);
    if (validation_resp.status === "fail") {
      validation_resp.message.forEach((data, index) => {
        props.mergeAlerts.alerts.push({
          resourceid: props.resource.ID,
          index: props.index,
          topic: "Tags",
          messages: data
        });
      });
    }
  };

  clearAlerts();
  runMaintAlerts();
  runTagCheck();

  return;
};

export const ResourceAlerts = props => {
  const [showModal, setModal] = useState(false);
  const [alertDesc, setAlertDesc] = useState("Default description");

  let hasAlerts = false;

  props.mergeAlerts.alerts.forEach((alert, index) => {
    if (alert.resourceid === props.resource.ID) hasAlerts = true;
  });

  const tblProps = {
    data: props.mergeAlerts.alerts
      .filter(alert => alert.resourceid === props.resource.ID)
      .map(data => ({
        id: data.resourceid,
        topic: data.topic,
        message: data.messages
      })),
    columns: [
      {
        Header: "Topic",
        accessor: "topic"
      },
      {
        Header: "Message",
        accessor: "message",
        Cell: ({ row }) => (
          <div style={{ textAlign: "left" }}>
            {row.values.message.length <= 50 ? (
              row.values.message
            ) : (
              <span>
                {row.values.message.substring(0, 50)}...
                <Button
                  small
                  onClick={() => {
                    setAlertDesc(row.values.message);
                    setModal(true);
                  }}
                >
                  <Icon type="walkthrough" />
                </Button>
              </span>
            )}
          </div>
        )
      }
    ]
  };

  const makeTable = () => {
    return (
      <div>
        <Modal onClose={() => setModal(false)} closeButton show={showModal}>
          <h2>Alert Description</h2>
          <div
            style={{ margin: "30px", overflow: "scroll", maxHeight: "400px" }}
          >
            <p>{alertDesc}</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button onClick={() => setModal(false)}>Close</Button>
          </div>
        </Modal>
        <StaticTable {...tblProps} />
      </div>
    );
  };
  return (
    <div>{!hasAlerts ? "There are no alerts at this time." : makeTable()}</div>
  );
};
