export const returnTaggingComplianceCollection = props => {
  var systems = props.systems;
  var shouldBlock = [];

  for (var system in systems) {
    shouldBlock.push({
      match: {
        "SYSTEM_ID.keyword": systems[system]
      }
    });
  }
  return {
    size: 0,
    query: {
      bool: {
        filter: {
          range: {
            last_seen: {
              gt: "now-1d"
            }
          }
        },
        must: [
          {
            match_phrase: {
              _index: "tag_validation*"
            }
          }
        ],
        should: shouldBlock,
        minimum_should_match: 1
      }
    },
    aggs: {
      system_id: {
        terms: {
          field: "SYSTEM_ID.keyword",
          size: 10000
        },
        aggs: {
          validated: {
            terms: {
              field: "validated"
            }
          },
          incomplete: {
            terms: {
              field: "INCOMPLETE"
            }
          }
        }
      }
    }
  };
};
