export const returnTaggingComplianceGeneral = props => {
  var filter = props.filter;
  var subFilter = props.subFilter;
  return {
    size: 0,
    query: {
      bool: {
        filter: {
          range: {
            last_seen: {
              gt: "now-1d"
            }
          }
        },
        must: [
          {
            match_phrase: {
              _index: "tag_validation*"
            }
          }
        ]
      }
    },
    aggs: {
      incomplete: {
        terms: {
          field: "INCOMPLETE"
        },
        aggs: {
          primary_filter: {
            terms: {
              field: filter + ".keyword",
              size: 10000
            },
            aggs: {
              validated: {
                terms: {
                  field: subFilter
                }
              }
            }
          }
        }
      }
    }
  };
};

export const returnTaggingComplianceSpecific = props => {
  var filter = props.filter + ".keyword";
  var target = props.target;
  return {
    size: 0,
    query: {
      bool: {
        filter: {
          range: {
            last_seen: {
              gt: "now-1d"
            }
          }
        },
        must: [
          {
            match_phrase: {
              _index: "tag_validation*"
            }
          },
          {
            match: {
              [filter]: target
            }
          }
        ]
      }
    },
    aggs: {
      incomplete: {
        terms: {
          field: "INCOMPLETE"
        },
        aggs: {
          primary_filter: {
            terms: {
              field: "partial_compliance"
            },
            aggs: {
              validated: {
                terms: {
                  field: "validated"
                }
              }
            }
          }
        }
      },
      resource_type: {
        terms: {
          size: 1000,
          field: "RESOURCE_TYPE.keyword"
        },
        aggs: {
          incomplete_type: {
            terms: {
              field: "INCOMPLETE"
            },
            aggs: {
              validated: {
                terms: {
                  field: "validated"
                }
              }
            }
          }
        }
      }
    }
  };
};

export const returnTaggingComplianceTargetedAggregation = props => {
  var filter = props.filter + ".keyword";
  var target = props.target;
  return {
    size: 0,
    query: {
      bool: {
        filter: {
          range: {
            last_seen: {
              gt: "now-1d"
            }
          }
        },
        must: [
          {
            match_phrase: {
              _index: "tag_validation*"
            }
          },
          {
            match: {
              [filter]: target
            }
          },
          {
            match: {
              INCOMPLETE: false
            }
          }
        ]
      }
    },
    aggs: {
      "nike-application": {
        terms: {
          field: "nike-application valid"
        },
        aggs: {
          "nike-application-present": {
            terms: {
              field: "nike-application present"
            }
          }
        }
      },
      "nike-environment": {
        terms: {
          field: "nike-environment valid"
        },
        aggs: {
          "nike-environment-present": {
            terms: {
              field: "nike-environment present"
            }
          }
        }
      },
      "nike-domain": {
        terms: {
          field: "nike-domain valid"
        },
        aggs: {
          "nike-domain-present": {
            terms: {
              field: "nike-domain present"
            }
          }
        }
      },
      "nike-department": {
        terms: {
          field: "nike-department valid"
        },
        aggs: {
          "nike-department-present": {
            terms: {
              field: "nike-department present"
            }
          }
        }
      },
      "nike-distributionlist": {
        terms: {
          field: "nike-distributionlist valid"
        },
        aggs: {
          "nike-distributionlist-present": {
            terms: {
              field: "nike-distributionlist present"
            }
          }
        }
      },
      "nike-owner": {
        terms: {
          field: "nike-owner valid"
        },
        aggs: {
          "nike-owner-present": {
            terms: {
              field: "nike-owner present"
            }
          }
        }
      },
      "nike-techsolution": {
        terms: {
          field: "nike-techsolution valid"
        },
        aggs: {
          "nike-techsolution-present": {
            terms: {
              field: "nike-techsolution present"
            }
          }
        }
      }
    }
  };
};

export const returnTaggingComplianceTargetedSearch = props => {
  var filter = props.filter + ".keyword";
  var target = props.target;
  var size = props.size;
  var tag = props.tag;
  var resourceType = props.resourceType;
  var matchClauses = [
    {
      match_phrase: {
        _index: "tag_validation*"
      }
    },
    {
      match: {
        INCOMPLETE: false
      }
    },
    {
      match: {
        validated: false
      }
    },
    {
      match: {
        [filter]: target
      }
    }
  ];

  if (resourceType) {
    matchClauses.push({
      match: {
        RESOURCE_TYPE: resourceType
      }
    });
  }

  if (tag) {
    matchClauses.push({
      match: {
        [tag + " valid"]: false
      }
    });
  }

  return {
    size: size,
    query: {
      bool: {
        filter: {
          range: {
            last_seen: {
              gt: "now-1d"
            }
          }
        },
        must: matchClauses
      }
    }
  };
};
