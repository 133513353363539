import { combineReducers } from "redux";
import { parseErrorMiddleware } from "@nike/redux-action-utils";
import { reducer as auth } from "@nike/oauth-client";
import offline from "../offline/reducer";
import notifications from "../notifications/reducer";
import resources from "../system/SystemReducer";

const rootReducer = combineReducers({
  auth,
  parseErrorMiddleware,
  offline,
  notifications,
  resources
});

export default rootReducer;
