import React, { useEffect, useState } from "react";
import config from "../config.js";
import { TextInput, Icon, Button, Modal, Spinner } from "@nike/epic-react-ui";
import { StaticTable } from "../components/table/index.js";

export const Delegates = props => {
  const systemid = props.data.key;
  let mdData = [];
  const [admin] = useState(props.system.is_admin);
  const [systemState] = useState(props.system.state);
  const [delegates, setDelegates] = useState([]);
  const [modal, setModal] = useState(false);
  const [delegateValue, setDelegateValue] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [deleteSpinner, setDeleteSpinner] = useState([]);

  // set boolean value for use in selective rendering to see if system is editable
  var editable = false;
  if (admin && systemState === "active") {
    editable = true;
  }

  useEffect(() => {
    if (typeof props.system.delegates !== "undefined") {
      setDelegates(props.system.delegates);
    }
  }, []);

  const createNewDelegate = () => {
    const sendDelegates = delegates;
    sendDelegates.push(delegateValue);
    updateDelegates(sendDelegates);
  };

  const deleteDelegate = index => {
    setDeleteSpinner(delegates[index]);
    const sendDelegates = delegates;
    sendDelegates.splice(index, 1);
    updateDelegates(sendDelegates);
  };

  const editDelegate = index => {
    setDelegateValue(delegates[index]);
    const sendDelegates = delegates;
    sendDelegates.splice(index, 1);
    setModal(true);
  };

  for (var delegate in delegates) {
    mdData.push({
      value: delegates[delegate],
      index: delegate
    });
  }

  const buttonState = data => {
    if (admin && systemState === "active") {
      return (
        <div style={{ textAlign: "center" }}>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => editDelegate(data.index)}
          >
            {/* <Icon type="edit" /> */}
            Edit
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => deleteDelegate(data.index)}
          >
            {/* <Icon type="delete" /> */}
            Delete
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <Button small disabled>
            {/* <Icon type="edit" /> */}
            Edit
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button small disabled>
            {/* <Icon type="delete" /> */}
            Delete
          </Button>
        </div>
      );
    }
  };

  mdData = mdData.map(data => ({
    value: data.value,
    index: data.index,
    actions: buttonState(data)
  }));

  const tblProps = {
    data: mdData,
    columns: [
      {
        Header: "Value",
        accessor: "value"
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {deleteSpinner.includes(row.original.value) ? (
              <Spinner />
            ) : (
              row.original.actions
            )}
          </div>
        )
      }
    ],
    minRows: 0,
    showPagination: false,
    filterable: false
  };

  const updateDelegates = sendDelegates => {
    setUpdateSpinner(true);
    fetch(config.apiUrl + "/ssot/systems/" + systemid, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        delegates: sendDelegates
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          throw Error(response.message[0].message);
        } else if (!response.key) {
          throw Error("Failure in Systems API");
        } else {
          setDelegateValue("");
          setUpdateSpinner(false);
          setDeleteSpinner([]);
          setModal(false);
          setDelegates(response.delegates);
          setFormStatus(
            <div className="epic-color-success system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Change saved."}</div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 2000);
          setUpdateSpinner(false);
          setDeleteSpinner([]);
        }
      })
      .catch(error => {
        //console.log(error);
        setFormStatus(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 6000);
        var index = delegates.indexOf(delegateValue);
        deleteDelegate(index);
        setDelegateValue("");
        setUpdateSpinner(false);
        setDeleteSpinner([]);
        setModal(false);
      });
  };

  const addBtn = editable ? (
    delegates.length < 5 ? (
      <Button
        style={{ marginBottom: "1em" }}
        onClick={() => {
          setDelegateValue("");
          setModal(true);
        }}
      >
        Add Delegate
      </Button>
    ) : (
      <div>Limited to 5 Delegates</div>
    )
  ) : (
    ""
  );
  const tbl = <StaticTable {...tblProps} />;

  return (
    <div>
      <div style={{ color: "#6d6d6d" }}>Delegates</div>
      <Modal onClose={() => setModal(false)} closeButton title="" show={modal}>
        <div>
          <div style={{ marginBottom: "30px" }}>
            <TextInput
              label="Email / AD Group"
              className="textInput"
              value={delegateValue}
              onChange={e => setDelegateValue(e.target.value)}
              onKeyPress={e => (e.key === "Enter" ? createNewDelegate() : null)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={updateSpinner}
              onClick={() => createNewDelegate()}
            >
              Submit
            </Button>
          </div>
          <div style={{ margin: "30px" }}></div>
          {updateSpinner ? (
            <div>
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <div>{formStatus}</div>
      {addBtn}
      {delegates.length > 0 ? tbl : ""}
    </div>
  );
};
