import {
  authCheckComplete,
  authCheckStart,
  setSilentAuthCheck,
  setLoginError,
  userLoginSuccess,
  innerLogoutUser,
  setPreviousLocation
} from './actions.js'

let defaultState = {
  hasAuthCheckStarted: false,
  hasAuthCheckFinished: false,
  isSilentLoginRunning: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isLoggedIn: false,

  username: null,
  name: null,
  email: null,
  accessToken: null,
  openIdToken: null,
  scope: null,
  claims: null,
  groups: [],

  previousLocation: null,

  loginError: null,
  logoutError: null
}

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case authCheckStart.type:
      return {
        ...state,
        hasAuthCheckStarted: true,
        hasAuthCheckFinished: false
      }
    case authCheckComplete.type:
      return { ...state, hasAuthCheckFinished: true, previousLocation: null }
    case setSilentAuthCheck.type:
      return { ...state, isSilentLoginRunning: action.payload }
    case setLoginError.type:
      return {
        ...state,
        loginError: action.payload.toString(),
        isLoggedIn: false
      }
    case userLoginSuccess.type:
      return {
        ...state,
        isLoggedIn: true,
        username: action.payload.username.toLowerCase(),
        groups: action.payload.groups,
        claims: action.payload.claims,
        name: action.payload.name,
        accessToken: action.payload.accessToken,
        openIdToken: action.payload.openIdToken,
        email: action.payload.email,
        scope: action.payload.scope,
        loginError: null
      }
    case innerLogoutUser.event.success:
      return {
        ...state,
        username: null,
        name: null,
        isLoggedIn: false,
        email: null,
        scope: null
      }
    case innerLogoutUser.event.error:
      return {
        ...state,
        logoutError: action.payload.toString()
      }
    case setPreviousLocation.type:
      return {
        ...state,
        previousLocation: action.payload
      }
    default:
      return state
  }
}

export default auth

export const selectUsername = state => state.auth.username
export const selectEmail = state => state.auth.email
export const selectName = state => state.auth.name
export const selectAccessToken = state => state.auth.accessToken
export const selectOpenIDToken = state => state.auth.openIdToken
export const selectUserGroups = state => [...state.auth.groups]
export const selectTokenClaims = state => ({ ...state.auth.claims })
export const selectIsSilentAuthRunning = state => state.auth.isSilentLoginRunning
export const selectLoginError = state => state.auth.loginError
export const selectLogoutError = state => state.auth.logoutError
export const selectIsLoggedIn = state => state.auth.isLoggedIn
export const selectPreviousLocation = state => state.auth.previousLocation

// TODO: for v1, replace these with `selectHasAuthCheckStarted` and `selectHasAuthCheckFinished`
export const hasAuthCheckStarted = state => state.auth.hasAuthCheckStarted
export const hasAuthCheckFinished = state => state.auth.hasAuthCheckFinished
