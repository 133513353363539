import React, { useState, useEffect } from "react";
import config from "../config.js";
import { Section } from "@nike/epic-react-ui";
import { SortableTable } from "../components/table/index.js";
import { returnSystemAccounts, returnAccountSystems } from "./SystemData.js";

export const Associations = props => {
  const key = props.data.key;
  const account = props.account;
  const [errorMessage, setErrorMessage] = useState(null);
  const [associations, setAssociations] = useState([]);
  const [friendlyNames, setFreindlyNames] = useState([]);
  const [refinedData, setRefinedData] = useState([]);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    let post_body = JSON.stringify(returnSystemAccounts(key));

    if (account)
      post_body = JSON.stringify(returnAccountSystems(account["accountId"]));

    fetch(config.apiUrl + "/cloud-resources/customAggregates", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      },
      method: "POST",
      body: post_body
    })
      .then(results => results.json())
      .then(results => {
        if (results.status === "fail") {
          // console.log(results);
          setErrorMessage(results);
        } else if (!results.hits) {
          setAssociations([]);
        } else {
          setErrorMessage(null);
          results = results.aggregations.associations.buckets;
          setAssociations(results);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
  }, [key, props.accessToken, account]);

  useEffect(() => {
    var friendlyMap = {};
    if (account) {
      fetch(config.apiUrl + "/ssot/systems/", {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        },
        method: "GET"
      })
        .then(response => response.json())
        .then(response => {
          for (var i in response) {
            var entry = response[i];
            friendlyMap[entry.key] = {
              name: entry.name,
              systemId: entry.key,
              account: ""
            };
          }
          setFreindlyNames(friendlyMap);
        })
        .catch(error => {
          console.log(error);
          setErrorMessage(error);
        });
    } else {
      fetch(config.apiUrl + "/ssot/reference/accessible-accounts-v2", {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        },
        method: "GET"
      })
        .then(response => response.json())
        .then(response => {
          for (var i in response) {
            var entry = response[i];
            friendlyMap[entry.accountId] = {
              name: entry.name + " - " + entry.accountType,
              systemId: entry.tagging.tagGuid,
              account: entry.accountId
            };
          }
          setFreindlyNames(friendlyMap);
        })
        .catch(error => {
          console.log(error);
          setErrorMessage(error);
        });
    }
  }, [key, props.accessToken, account]);

  useEffect(() => {
    var updateBody = [];
    if (associations.length > 0 && Object.keys(friendlyNames).length > 0) {
      for (var bucket of associations) {
        var name = "";
        var systemId = "";
        var account = "";
        var key = bucket.key;
        if (friendlyNames.hasOwnProperty(key)) {
          // console.log(key);
          name = friendlyNames[key].name;
          systemId = friendlyNames[key].systemId;
          account = friendlyNames[key].account;
          //console.log(name);

          updateBody.push({
            name: name,
            systemId: systemId,
            account: account,
            resourceCount: bucket.doc_count
          });
        }
      }
      setRefinedData(updateBody);
    }
  }, [friendlyNames, associations]);

  const tblProps = {
    data: refinedData.map(data => ({
      name: data.name,
      systemId: data.systemId,
      account: data.account,
      resourceCount: data.resourceCount
    })),
    columns: [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => (
          <div>
            {row.original.systemId !== null ? (
              <a
                className="dashLink"
                target="_self"
                rel="noopener noreferrer"
                href={basePath + "/system/" + row.original.systemId}
              >
                <b>{row.original.name}</b>
              </a>
            ) : (
              <a
                className="dashLink"
                target="_self"
                rel="noopener noreferrer"
                href={basePath + "/account/" + row.original.account}
              >
                <b>{row.original.account}</b>
              </a>
            )}
          </div>
        )
      },
      {
        Header: "Resource Count",
        accessor: "resourceCount"
      }
    ]
  };

  //console.log(tblProps);

  const makeTable = () => {
    return (
      <div>
        <SortableTable {...tblProps} />
      </div>
    );
  };

  return (
    <div>
      <Section key={props.id} id={props.id} title={props.title}>
        <div>
          <p style={{ marginBottom: "2em" }}>
            This section shows the systems that are in an account or the
            accounts that a system is deployed into. If no associations are
            shown then we have not found your resources in an AWS account.
          </p>
          {refinedData.length > 0 ? (
            makeTable()
          ) : (
            <b>No associated systems or accounts have been found</b>
          )}
        </div>
      </Section>
    </div>
  );
};
