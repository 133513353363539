import React from "react";

let errorMessage = [];

export const APIError = props => {
  // export const Metrics = props => {
  if (props.error) {
    errorMessage = [];
    if (props.error.errors) {
      if (Array.isArray(props.error.errors)) {
        props.error.errors.forEach(e => {
          let i = "";
          if (e.message) {
            i = e.message;
          } else {
            i = e;
          }
          errorMessage.push(i);
        });
      }
    } else {
      if (props.error.message) {
        if (Array.isArray(props.error.message)) {
          props.error.message.forEach(e => {
            if (e.message) {
              errorMessage.push(e.message);
            } else {
              errorMessage.push(e);
            }
          });
        } else {
          errorMessage.push(props.error.message);
          if (props.error.details) {
            if (Array.isArray(props.error.details)) {
              props.error.details.forEach(e => {
                if (e.message) {
                  errorMessage.push(e.message);
                } else {
                  errorMessage.push(e);
                }
              });
            }
          }
        }
      } else if (props.error.description) {
        errorMessage.push(props.error.description);
      } else if (typeof props.error == "string") {
        errorMessage.push(props.error);
      } else {
        errorMessage.push("Unknown error message");
      }
    }
  } else if (typeof props.error == "string") {
    errorMessage.push(props.error);
  } else {
    errorMessage.push("Unknown error message");
  }

  const showErrorDetail = errorMessage ? (
    errorMessage.map(y => <pre key={y}>{y}</pre>)
  ) : (
    <div></div>
  );

  return (
    <div style={{ padding: props.padding, paddingLeft: props.paddingLeft }}>
      {props.showTitle ? (
        <h1 style={{ fontSize: "18px" }} className="epic-font-brand">
          {props.title
            ? props.title
            : "An error occured, unable to load details."}
        </h1>
      ) : (
        ""
      )}
      {showErrorDetail}
    </div>
  );
};

export default APIError;
