import React, { useState, useEffect } from "react";
import { Section, Spinner } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";
import { returnTaggingComplianceSpecific } from "../tagging_compliance/TaggingComplianceQueries.js";

export const ComplianceMetrics = props => {
  const key = props.data.key;
  const account = props.account;
  const [compliance, setCompliance] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  var args = {};
  if (account) {
    args = { filter: "ACCOUNT", target: account.accountId };
  } else {
    args = { filter: "SYSTEM_ID", target: key };
  }
  let post_body = JSON.stringify(returnTaggingComplianceSpecific(args));

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    setCompliance(null);
    let local_metrics = [];
    let complianceUrl = config.apiUrl + "/cloud-resources/customAggregates";
    fetch(complianceUrl, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: post_body
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setErrorMessage(response);
          // throw Error(response.message);
        } else {
          setErrorMessage(null);
          var incomplete_val = 0;
          var invalid_count = 0;
          var partial_count = 0;
          var total = response.hits.total;
          for (var x in response.aggregations.incomplete.buckets) {
            var incomplete_agg = response.aggregations.incomplete.buckets[x];
            //console.log(incomplete_agg);
            if (incomplete_agg.key_as_string === "true") {
              incomplete_val = incomplete_agg.doc_count;
            } else if (incomplete_agg.key_as_string === "false") {
              for (var y in incomplete_agg.primary_filter.buckets) {
                var entry = incomplete_agg.primary_filter.buckets[y];
                if (entry.key_as_string === "true") {
                  partial_count += entry.doc_count;
                } else {
                  var invalid_resources = 0;
                  for (var z in entry.validated.buckets) {
                    var aggregation = entry.validated.buckets[z];
                    if (aggregation.key_as_string === "false") {
                      invalid_resources = aggregation.doc_count;
                      invalid_count += invalid_resources;
                    }
                  }
                }
              }
            }
          }
          var nonCompliant = invalid_count + partial_count;
          var compliancePercentage = (
            ((total - nonCompliant - incomplete_val) /
              (total - incomplete_val)) *
              100 || 0
          ).toFixed(1);
          local_metrics.push({
            system_id: "totalResources",
            metric_title: "Taggable Resources",
            metric_description:
              "Total number of taggable resources for the system",
            system_name: "Resouces",
            value: total
          });
          local_metrics.push({
            system_id: "incompleteResources",
            metric_title: "Excluded Resources",
            metric_description:
              "Resources where data is incomplete due to API rate limits or permissions issues",
            system_name: "Resouces",
            value: incomplete_val
          });
          local_metrics.push({
            system_id: "CompliancePercentage",
            metric_title: "Compliance Percentage",
            metric_description:
              "Percentage Compliance of included taggable resources",
            system_name: "percentage",
            value: compliancePercentage + "%"
          });
          setCompliance(local_metrics);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
    //console.log(a);
  }, [key, props.accessToken, props.data.key, post_body]);

  const showCards = metric => {
    return (
      <div
        className="metricCard"
        key={metric["system_id"]}
        style={{ height: "250px", width: "250px", marginLeft: "10px" }}
      >
        <h1 className="metricCardTitle" key="metric_title">
          {metric["metric_title"]}
        </h1>
        <h3 className="metricsCardSubtitle" key="metric_description">
          {metric["metric_description"]}
        </h3>
        <p className="metricCardText" key="value">
          {metric["value"]}
        </p>
      </div>
    );
  };

  return (
    <div>
      {account ? (
        <Section id={props.id} title="Account Specific Compliance Metrics">
          <b>
            This section gives the overall compliance for an account, it
            includes compliance percentage for the resources that have
            "nike-tagguid" tag.
          </b>
          {compliance && (
            <div style={{ margin: "10px" }}>
              To see more compliance details for your Account see:&nbsp;
              <a
                style={{ fontSize: "18px" }}
                className="dashLink"
                target="_self"
                rel="noopener noreferrer"
                href={basePath + "/compliance/ACCOUNT/" + account.accountId}
              >
                Account Compliance Dashboard
              </a>
              <br />
              <br />
              To see what Cloudred systems are in your account and affecting
              your compliance see the "Associated Systems" section in the side
              nav bar.
            </div>
          )}
          <div style={{ marginTop: "20px" }}>
            <h2>
              <b>Tagging Compliance Metrics</b>
            </h2>
            <br />
            {compliance ? (
              <div style={{ textAlign: "center", display: "flex" }}>
                {compliance.map(data => {
                  //console.log(data);
                  return showCards(data);
                })}
              </div>
            ) : !errorMessage ? (
              <Spinner />
            ) : (
              <APIError showTitle={true} error={errorMessage} />
            )}
          </div>
        </Section>
      ) : (
        <Section id={props.id} title="System Specific Compliance Metrics">
          <b>
            This section gives the compliance for the resources that have the
            system Id. If this is an account system this section shows the
            resources with no "nike-tagguid" tag.
          </b>
          {compliance && (
            <div style={{ margin: "10px" }}>
              To see more compliance details for your system see:&nbsp;
              <a
                style={{ fontSize: "18px" }}
                className="dashLink"
                target="_self"
                rel="noopener noreferrer"
                href={basePath + "/compliance/SYSTEM_ID/" + key}
              >
                Compliance Dashboard
              </a>
              <br />
              <br />
            </div>
          )}
          <div>
            <h2>
              <b>Tagging Compliance Metrics</b>
            </h2>
            <br />
            {compliance ? (
              <div style={{ textAlign: "center", display: "flex" }}>
                {compliance.map(data => {
                  //console.log(data);
                  return showCards(data);
                })}
              </div>
            ) : !errorMessage ? (
              <Spinner />
            ) : (
              <APIError showTitle={true} error={errorMessage} />
            )}
          </div>
        </Section>
      )}
    </div>
  );
};
