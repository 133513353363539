import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import http from "../util/http.js";
import { Button, Spinner } from "@nike/epic-react-ui";
import Checkbox from "../components/Checkbox";
import {
  FilterableTable,
  makeTextFilter,
  SelectFilter
} from "../components/table/index.js";

export const SystemSearch = user => {
  const [apps, setApps] = useState([]);
  const [accts, setAccts] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [filter, setFilter] = useState(user.email);
  const [checked, setChecked] = useState(true);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    var args = "";
    if (filter !== null) {
      args = "?owner=" + filter;
    }
    http
      .get(config.apiUrl + "/ssot/systems/" + args)
      .then(response => {
        setApps(response);
      })
      .catch(error => console.log(error));
  }, [user.accessToken, filter]);

  useEffect(() => {
    http
      .get(config.apiUrl + "/ssot/systems/account")
      .then(response => {
        setAccts(response);
      })
      .catch(error => console.log(error));
  }, [user.accessToken]);

  useEffect(() => {
    setCheckedList([]);
    const newList = [];
    newList.push(...user.systems);
    setCheckedList(newList);
  }, [user.systems]);

  const getAccountItem = (key, type, item) => {
    if (type !== "aws_account") return "N/A";

    for (var acct in accts) {
      if (accts[acct].tagging && accts[acct].tagging.tagGuid === key)
        return accts[acct][item];
    }
    return "N/A";
  };

  const handleCheck = value => {
    if (checkedList.includes(value)) {
      const newList = checkedList.filter(item => item !== value);
      return newList;
    } else {
      const newList = checkedList;
      newList.push(value);
      return newList;
    }
  };

  const submitForm = e => {
    user.createNewSystems(checkedList);
  };

  const props = {
    data: apps.map(data => ({
      Key: data.key,
      Type: data.type,
      Name: data.name,
      Owner: data.owner,
      AccountType: getAccountItem(data.key, data.type, "accountType"),
      AccountId: getAccountItem(data.key, data.type, "accountId")
    })),
    fields: ["Key", "Name", "Type", "Owner"],
    columns: [
      {
        Header: "System ID",
        accessor: "Key",
        Filter: makeTextFilter("Filter by Guid"),
        Cell: props => (
          <a
            className="dashLink"
            href={basePath + "/system/" + props.cell.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.cell.value.substring(0, 8) +
              "..." +
              props.cell.value.substring(props.cell.value.length - 4)}
          </a>
        ),
        filter: "fuxxyText"
      },
      {
        Header: "Name",
        accessor: "Name",
        Filter: makeTextFilter("Filter by Name"),
        filter: "fuzzyText"
      },
      {
        Header: "Owner",
        accessor: "Owner",
        Filter: makeTextFilter("Filter by Owner Email")
      },
      {
        Header: "System Type",
        accessor: "Type",
        Filter: SelectFilter
      },
      {
        Header: "Account Type",
        accessor: "AccountType",
        Cell: props =>
          props.cell.value === "N/A" ? (
            <div
              style={{
                fontStyle: "italic",
                color: "gray"
              }}
            >
              {props.cell.value}
            </div>
          ) : (
            props.cell.value
          ),
        Filter: SelectFilter
      },
      {
        Header: "Account Id",
        accessor: "AccountId",
        Cell: props =>
          props.cell.value === "N/A" ? (
            <div
              style={{
                fontStyle: "italic",
                color: "gray"
              }}
            >
              {props.cell.value}
            </div>
          ) : (
            props.cell.value
          ),
        Filter: makeTextFilter("Filter by Account Id")
      },
      {
        Header: "Select",
        id: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <Checkbox
              defaultChecked={checkedList.includes(row.original.Key)}
              onClick={() => setCheckedList(handleCheck(row.original.Key))}
            />
          </div>
        )
      }
    ]
  };

  const handleChecked = () => {
    setApps([]);
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  useEffect(() => {
    if (checked) {
      setFilter(user.email);
    } else {
      setFilter(null);
    }
  }, [user, checked]);

  const showTable = () => {
    return (
      <div>
        <FilterableTable {...props} />
        <div style={{ margin: "30px" }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button disabled={user.updateSpinner} onClick={submitForm}>
            Submit
          </Button>
        </div>
        <div style={{ margin: "30px" }}></div>
        {user.updateSpinner ? (
          <div>
            <Spinner />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div style={{ margin: "1em", maxHeight: "80vh", overflow: "scroll" }}>
      Filter to Owned Systems:
      <Checkbox
        defaultChecked={true}
        onClick={() => handleChecked()}
        checked={checked}
      />
      {apps.length === 0 || accts.length === 0 ? <Spinner /> : showTable()}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(SystemSearch);
