import React from "react";
import PropTypes from "prop-types";
import { Chart } from "react-charts";

const ComplianceGraph = ({ data, ...others }) => {
  const [{ activeDatumIndex }, setState] = React.useState({
    activeSeriesIndex: -1,
    activeDatumIndex: -1
  });

  const getDatumStyle = React.useCallback(
    datum => ({
      color: "#5197D6",
      opacity:
        activeDatumIndex > -1 ? (datum.index === activeDatumIndex ? 1 : 0.3) : 1
    }),
    [activeDatumIndex]
  );

  const onFocus = React.useCallback(
    focused =>
      setState({
        activeDatumIndex: focused ? focused.index : -1
      }),
    [setState]
  );

  const barData = React.useMemo(() => data, [data]);

  const series = React.useMemo(
    () => ({
      type: "bar"
    }),
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: "ordinal", position: "bottom" },
      { position: "left", type: "linear", stacked: false, base: 0 }
    ],
    []
  );

  return (
    <div
      style={{
        width: "400px",
        height: "300px"
      }}
    >
      <Chart
        data={barData}
        axes={axes}
        series={series}
        getDatumStyle={getDatumStyle}
        onFocus={onFocus}
        tooltip
      />
    </div>
  );
};

ComplianceGraph.propTypes = {
  data: PropTypes.array
};

ComplianceGraph.defaultProps = {
  data: []
};

export default ComplianceGraph;
