import React, { useState, useEffect } from "react";
import config from "../config.js";
import { StaticTable, Spinner } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";

export const ResourceTagTable = props => {
  const ATLTagGuid = props.resAccount.tagging;
  const [atlTags, setATLTags] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const formattags = () => {
    const data = props.resource;
    const tagset = props.system.tags;
    const columns = [
      {
        Header: "Key",
        accessor: "key"
      },
      {
        Header: "Value",
        accessor: "value"
      },
      {
        Header: "Source",
        accessor: "source"
      }
    ];

    if (data.STD_Tags == null) data.STD_Tags = [];

    if (data.STD_Tags.length > 0) {
      for (var tag in data.STD_Tags) {
        if (typeof tagset[data.STD_Tags[tag]["Key"]] === "string") {
          data.STD_Tags[tag]["Source"] = (
            <a href="#tags" className="dashLink">
              {props.data.key === ATLTagGuid.tagGuid
                ? "Account Level Tag Set"
                : "Managed Tag Set"}
            </a>
          );
        } else if (
          typeof atlTags[data.STD_Tags[tag]["Key"]] === "string" &&
          atlTags[data.STD_Tags[tag]["Key"]] === data.STD_Tags[tag]["Value"]
        ) {
          data.STD_Tags[tag]["Source"] = (
            <a
              className="dashLink"
              target="_blank"
              rel="noopener noreferrer"
              href={"/system/" + ATLTagGuid.tagGuid}
            >
              Account Level Tag Set
            </a>
          );
        } else {
          data.STD_Tags[tag]["Source"] = "";
        }
      }

      const tbldata = data.STD_Tags.map(tag => ({
        key: tag.Key,
        value: tag.Value,
        source: tag.Source
      }));

      return <StaticTable columns={columns} data={tbldata} />;
    } else {
      return <div>There are no tags on this resource.</div>;
    }
  };

  useEffect(() => {
    if (ATLTagGuid && ATLTagGuid.tagGuid !== null) {
      fetch(config.apiUrl + "/ssot/systems/" + ATLTagGuid.tagGuid, {
        // fetch("http://127.0.0.1:8000/" + ATLTagGuid.tagGuid, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.status === "fail") {
            setErrorMessage(response);
          } else if (!response.type) {
            console.log(response);
            throw Error("Failure in tags retrieval");
          } else {
            setErrorMessage(null);
            setATLTags(response.tags);
          }
        })
        .catch(error => {
          console.log(error);
          setErrorMessage(error);
        });
    } else {
      setATLTags({});
    }
  }, [ATLTagGuid, props.accessToken]);

  return (
    <div>
      {atlTags ? (
        formattags()
      ) : !errorMessage ? (
        <Spinner />
      ) : (
        <APIError error={errorMessage} showTitle={true} />
      )}
    </div>
  );
};
