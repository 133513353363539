import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import http from "../util/http.js";
import { Button, Icon, Spinner } from "@nike/epic-react-ui";
import { FilterableSortTable } from "../components/table/index.js";
import { navigate } from "raviger";
import APIError from "../components/APIError.js";

const AccountIndex = user => {
  const [accts, setAccts] = useState([]);
  const [errorMessageAccts, setErrorMessageAccts] = useState(null);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    http
      .get(config.apiUrl + "/ssot/systems/account")
      // .get("http://127.0.0.1:8000/account")
      .then(response => {
        if (response.status === "fail") {
          setErrorMessageAccts(response);
        } else {
          setErrorMessageAccts(null);
          setAccts(response);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageAccts(error);
      });
  }, [user.accessToken]);

  const props = {
    data: accts.map(data => ({
      Account: data.accountId,
      Name: data.name,
      Type: data.accountType,
      Guid: data.tagging.tagGuid
    })),
    fields: ["Type", "Account"],
    columns: [
      {
        Header: "Account ID",
        accessor: "Account"
      },
      {
        Header: "Name",
        accessor: "Name"
      },
      {
        Header: "Account Type",
        accessor: "Type"
      },
      {
        Header: "Has System Linked",
        Cell: ({ row }) => (
          <div>{row.original.Guid !== null ? "True" : "False"}</div>
        )
      },
      {
        Header: "Details",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.Guid !== null ? (
              <a href={basePath + "/system/" + row.original.Guid}>
                <Button
                  onClick={() =>
                    navigate(basePath + "/system/" + row.original.Guid)
                  }
                >
                  <Icon type="edit" />
                </Button>
              </a>
            ) : (
              <a href={basePath + "/account/" + row.original.Account}>
                <Button
                  onClick={() =>
                    navigate(basePath + "/account/" + row.original.Account)
                  }
                >
                  <Icon type="edit" />
                </Button>
              </a>
            )}
          </div>
        )
      }
    ]
  };

  const showTable = () => {
    return (
      <div>
        <FilterableSortTable {...props} />
      </div>
    );
  };

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      {!errorMessageAccts ? (
        accts.length === 0 ? (
          <Spinner overlay={false} show={true} />
        ) : (
          showTable()
        )
      ) : (
        <APIError showTitle={true} error={errorMessageAccts} />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(AccountIndex);
