export const TOKEN_KEY = 'ngp-auth:token'
export const RECOVERY_KEY = 'ngp-auth:recover'
export const LOCATION_KEY = 'ngp-auth:location'
export const CODE_VERIFIER_KEY = 'code_verifier'

export const OKTA_HASH = 'SHA-256'

export const SILENT_LOGIN_IFRAME_ID = 'silent-login'

export const RANDOM_CHAR_SET = 'abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
