import React from "react";
import { Select } from "@nike/epic-react-ui";

export default function SelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  options
}) {
  let computedOptions = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()].map(o => ({
      label: <span>{o}</span>,
      value: <span>{o}</span>
    }));
  }, [id, preFilteredRows]);

  options = options || computedOptions;

  return (
    <Select
      value={
        filterValue
          ? {
              value: <span>{filterValue}</span>,
              label: <span>{filterValue}</span>
            }
          : ""
      }
      onChange={o => {
        setFilter((o && o.value.props.children) || undefined);
      }}
      options={options}
      isClearable
      placeholder="Select..."
      className="SelectCR"
    />
  );
}
