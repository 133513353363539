import React, { useState, useEffect } from "react";
import { Section, Spinner, Button } from "@nike/epic-react-ui";
import { SortableTable } from "../components/table/index.js";
import config from "../config.js";
import { PieChart } from "react-minimal-pie-chart";
import { navigate } from "raviger";
import ComplianceGraph from "./CollectionComplianceGraph.js";
import APIError from "../components/APIError.js";
import { returnTaggingComplianceCollection } from "./CollectionQueries.js";

let basePath =
  window.location.href.indexOf("platforms.nike.com") !== -1 ? "/cloudred" : "";
let waffleUrl = "https://ui.waffleiron-prod.nikecloud.com";

export const Compliance = props => {
  const systems = props.collection.systems;
  const breakout = props.breakout;
  const [compliantBreakout, setCompliantBreakout] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 1
  });

  useEffect(() => {
    const args = { systems: systems };
    let post_body = JSON.stringify(returnTaggingComplianceCollection(args));
    if (!compliantBreakout) {
      if (systems.length > 0) {
        fetch(config.apiUrl + "/cloud-resources/customAggregates", {
          headers: {
            Authorization: "Bearer " + props.accessToken,
            "Cache-Control": "no-cache",
            "Content-Type": "application/json"
          },
          method: "POST",
          body: post_body
        })
          .then(response => response.json())
          .then(response => {
            if (response.status === "fail") {
              console.log(response);
              setErrorMessage(response);
            } else if (!Number.isInteger(response.hits.total)) {
              console.log(response);
              throw Error("Failure in Compliance Retrieval");
            } else {
              /*
            model:
            {
              "total_resources":18,
              "total_taggable":8,
              "total_compliant":0,
              "percentage_compliant":0.0,
              "accounts":[],
              "applications":[
                {
                  "application":"fa8f1167-e5b6-422b-a1e9-25fff3adadff",
                  "total_resources":14,
                  "total_taggable":6,
                  "total_compliance":0,
                  "total_percentage":0.0}]}
            */
              var systemsCompliance = {};
              var complianceSummary = {
                total_resources: 0,
                total_taggable: 0,
                total_compliant: 0,
                total_excluded: 0,
                percentage_compliant: 0,
                accounts: [],
                applications: [],
                gcp_projects: [],
                azure_rgs: [],
                azure_subscriptions: []
              };
              for (var entry of response.aggregations.system_id.buckets) {
                //var entry = response.aggregations.system_id.buckets[y];
                //console.log(entry);
                var invalid_resources = 0;
                var valid_resources = 0;
                var incomplete_resources = 0;
                for (var aggregation of entry.validated.buckets) {
                  //var aggregation = entry.validated.buckets[z];
                  if (aggregation.key_as_string === "true") {
                    valid_resources = aggregation.doc_count;
                    complianceSummary.total_compliant += valid_resources;
                  } else if (aggregation.key_as_string === "false") {
                    invalid_resources = aggregation.doc_count;
                  }
                }
                for (var incomplete of entry.incomplete.buckets) {
                  if (incomplete.key_as_string === "true") {
                    incomplete_resources = incomplete.doc_count;
                    complianceSummary.total_excluded += incomplete.doc_count;
                  }
                }
                complianceSummary.total_resources += entry.doc_count;
                complianceSummary.total_taggable +=
                  entry.doc_count - incomplete_resources;
                var complianceDivisor = entry.doc_count - incomplete_resources;
                var compliancePercentage = (
                  valid_resources / complianceDivisor
                ).toFixed(3);
                systemsCompliance[entry.key] = {
                  total_resources: entry.doc_count,
                  total_taggable: entry.doc_count - incomplete_resources,
                  total_excluded: incomplete_resources,
                  total_compliance: valid_resources,
                  total_percentage: compliancePercentage
                };
                //console.log(systemsCompliance);
              }
              for (var appEntry in breakout.applications) {
                var appCompliance = {};
                var breakoutApp = breakout.applications[appEntry];
                if (breakoutApp.system_id in systemsCompliance) {
                  appCompliance = systemsCompliance[breakoutApp.system_id];
                  appCompliance.application = breakoutApp.system_id;
                  complianceSummary.applications.push(appCompliance);
                }
              }
              for (var accountEntry in breakout.accounts) {
                var acctCompliance = {};
                var breakoutAccount = breakout.accounts[accountEntry];
                if (breakoutAccount.system_id in systemsCompliance) {
                  acctCompliance = systemsCompliance[breakoutAccount.system_id];
                  acctCompliance.account = breakoutAccount.account_id;
                  complianceSummary.accounts.push(acctCompliance);
                }
              }
              for (var gcpEntry in breakout.gcp_projects) {
                var gcpCompliance = {};
                var breakoutGcp = breakout.gcp_projects[gcpEntry];
                if (breakoutGcp.system_id in systemsCompliance) {
                  gcpCompliance = systemsCompliance[breakoutGcp.system_id];
                  gcpCompliance.application = breakoutGcp.system_id;
                  complianceSummary.gcp_projects.push(gcpCompliance);
                }
              }
              for (var azureRgEntry in breakout.azure_rgs) {
                var azureRgCompliance = {};
                var breakoutAzureRg = breakout.azure_rgs[azureRgEntry];
                if (breakoutAzureRg.system_id in systemsCompliance) {
                  azureRgCompliance =
                    systemsCompliance[breakoutAzureRg.system_id];
                  azureRgCompliance.application = breakoutAzureRg.system_id;
                  complianceSummary.azure_rgs.push(azureRgCompliance);
                }
              }
              for (var azureSubEntry in breakout.azure_subscriptions) {
                var azureSubCompliance = {};
                var breakoutAzureSub =
                  breakout.azure_subscriptions[azureSubEntry];
                if (breakoutAzureSub.system_id in systemsCompliance) {
                  azureSubCompliance =
                    systemsCompliance[breakoutAzureSub.system_id];
                  azureSubCompliance.application = breakoutAzureSub.system_id;
                  complianceSummary.azure_subscriptions.push(
                    azureSubCompliance
                  );
                }
              }
            }
            complianceSummary.percentage_compliant = (
              complianceSummary.total_compliant /
              (complianceSummary.total_resources -
                complianceSummary.total_excluded)
            ).toFixed(3);
            //console.log(complianceSummary);
            setCompliantBreakout(complianceSummary);
            setErrorMessage(null);
          })
          .catch(error => {
            console.log(error);
            setErrorMessage(error);
          });
      }
    }
  }, [systems, breakout, props.accessToken, compliantBreakout]);

  let barData = [];
  if (compliantBreakout && compliantBreakout.applications) {
    var nonCompliant =
      compliantBreakout.total_taggable - compliantBreakout.total_compliant;
    barData = [
      {
        label: "Resources",
        data: [
          ["Total", compliantBreakout.total_resources],
          ["Compliant", compliantBreakout.total_compliant],
          ["Non-Compliant", nonCompliant],
          ["Incomplete", compliantBreakout.total_excluded]
        ]
      }
    ];
  }

  // build array needed for tblPropsOverall
  const complianceOverall = [];
  if (compliantBreakout && compliantBreakout.applications) {
    complianceOverall.push({
      total_compliant: compliantBreakout.total_compliant,
      total_resources: compliantBreakout.total_resources,
      total_noncompliant:
        compliantBreakout.total_taggable - compliantBreakout.total_compliant,
      total_incomplete: compliantBreakout.total_excluded,
      total_taggable: compliantBreakout.total_taggable,
      percentage_compliant: parseFloat(compliantBreakout.percentage_compliant)
    });
  }

  // build array needed for tblPropsApplications
  const complianceApplications = [];
  if (compliantBreakout && compliantBreakout.applications) {
    compliantBreakout.applications.forEach(a => {
      complianceApplications.push({
        id: (
          <a
            className="dashLink"
            href={basePath + "/system/" + a.application}
            target="_blank"
            rel="noopener noreferrer"
          >
            {a.application}
          </a>
        ),
        name: (
          <a
            className="dashLink"
            href={basePath + "/system/" + a.application}
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              breakout.applications.find(h => h.system_id === a.application)
                .system_name
            }
          </a>
        ),
        owner: breakout.applications.find(h => h.system_id === a.application)
          .system_owner,
        compliant: a.total_compliance,
        total_taggable: a.total_taggable,
        total_resources: a.total_resources,
        nonCompliant: a.total_taggable - a.total_compliance,
        incomplete: a.total_excluded,
        percent: parseFloat(a.total_percentage)
      });
    });
  }

  // build array needed for tblPropsAccounts
  // includes button for detail modal
  // note actions+button is disabled until we build the queries
  const complianceAccounts = [];
  if (compliantBreakout && compliantBreakout.accounts) {
    compliantBreakout.accounts.forEach(a => {
      complianceAccounts.push({
        account_id: (
          <a
            href={waffleUrl + "/account/" + a.account}
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            {a.account}
          </a>
        ),
        id: (
          <a
            href={
              basePath +
              "/system/" +
              breakout.accounts.find(w => w.account_id === a.account).system_id
            }
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            {breakout.accounts.find(w => w.account_id === a.account).system_id}
          </a>
        ),
        name: (
          <a
            className="dashLink"
            href={
              basePath +
              "/system/" +
              breakout.accounts.find(w => w.account_id === a.account).system_id
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              breakout.accounts.find(h => h.account_id === a.account)
                .system_name
            }
          </a>
        ),
        owner: breakout.accounts.find(h => h.account_id === a.account)
          .account_owner
          ? breakout.accounts.find(h => h.account_id === a.account)
              .account_owner
          : breakout.accounts.find(h => h.account_id === a.account)
              .system_owner,
        account_type: breakout.accounts.find(h => h.account_id === a.account)
          .account_type,
        compliant: a.total_compliance,
        total_taggable: a.total_taggable,
        total_resources: a.total_resources,
        nonCompliant: a.total_taggable - a.total_compliance,
        incomplete: a.total_excluded,
        percent: parseFloat(a.total_percentage)
        // actions: (
        //   <div style={{ textAlign: "center" }}>
        //     <Button
        //       small
        //       onClick={() => {
        //         setModal(true);
        //         getAccount(a.id);
        //       }}
        //     >
        //       <Icon type="walkthrough" />
        //     </Button>
        //   </div>
        // )
      });
    });
  }

  // build array needed for tblPropsgcpProjects
  const complianceGcpProjects = [];
  if (compliantBreakout && compliantBreakout.gcp_projects) {
    compliantBreakout.gcp_projects.forEach(a => {
      complianceGcpProjects.push({
        id: (
          <a
            className="dashLink"
            href={basePath + "/system/" + a.application}
            target="_blank"
            rel="noopener noreferrer"
          >
            {a.application}
          </a>
        ),
        name: (
          <a
            className="dashLink"
            href={basePath + "/system/" + a.application}
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              breakout.gcp_projects.find(h => h.system_id === a.application)
                .system_name
            }
          </a>
        ),
        owner: breakout.gcp_projects.find(h => h.system_id === a.application)
          .system_owner,
        compliant: a.total_compliance,
        total_taggable: a.total_taggable,
        total_resources: a.total_resources,
        nonCompliant: a.total_taggable - a.total_compliance,
        incomplete: a.total_excluded,
        percent: parseFloat(a.total_percentage)
      });
    });
  }

  // build array needed for tblProps Azure Rgs
  const complianceAzureRg = [];
  if (compliantBreakout && compliantBreakout.azure_rgs) {
    compliantBreakout.azure_rgs.forEach(a => {
      complianceAzureRg.push({
        id: (
          <a
            className="dashLink"
            href={basePath + "/system/" + a.application}
            target="_blank"
            rel="noopener noreferrer"
          >
            {a.application}
          </a>
        ),
        name: (
          <a
            className="dashLink"
            href={basePath + "/system/" + a.application}
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              breakout.azure_rgs.find(h => h.system_id === a.application)
                .system_name
            }
          </a>
        ),
        owner: breakout.azure_rgs.find(h => h.system_id === a.application)
          .system_owner,
        compliant: a.total_compliance,
        total_taggable: a.total_taggable,
        total_resources: a.total_resources,
        nonCompliant: a.total_taggable - a.total_compliance,
        incomplete: a.total_excluded,
        percent: parseFloat(a.total_percentage)
      });
    });
  }

  // build array needed for tblProps Azure Subscriptions
  const complianceAzureSub = [];
  if (compliantBreakout && compliantBreakout.azure_subscriptions) {
    compliantBreakout.azure_subscriptions.forEach(a => {
      complianceAzureSub.push({
        id: (
          <a
            className="dashLink"
            href={basePath + "/system/" + a.application}
            target="_blank"
            rel="noopener noreferrer"
          >
            {a.application}
          </a>
        ),
        name: (
          <a
            className="dashLink"
            href={basePath + "/system/" + a.application}
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              breakout.azure_subscriptions.find(
                h => h.system_id === a.application
              ).system_name
            }
          </a>
        ),
        owner: breakout.azure_subscriptions.find(
          h => h.system_id === a.application
        ).system_owner,
        compliant: a.total_compliance,
        total_taggable: a.total_taggable,
        total_resources: a.total_resources,
        nonCompliant: a.total_taggable - a.total_compliance,
        incomplete: a.incomplete,
        percent: parseFloat(a.total_percentage)
      });
    });
  }

  const tblPropsOverall = {
    data:
      complianceOverall.length > 0
        ? complianceOverall.map(data => ({
            total_resources: data.total_resources,
            total_taggable: data.total_taggable,
            total_compliant: data.total_compliant,
            total_noncompliant: data.total_noncompliant,
            total_incomplete: data.total_incomplete,
            percentage_compliant: data.percentage_compliant
          }))
        : [],
    columns: [
      {
        Header: "Total",
        accessor: "total_resources"
      },
      {
        Header: "Taggable",
        accessor: "total_taggable"
      },
      {
        Header: "Compliant",
        accessor: "total_compliant"
      },
      {
        Header: "Non-compliant",
        accessor: "total_noncompliant"
      },
      {
        Header: "Incomplete",
        accessor: "total_incomplete"
      },
      {
        Header: "Percent compliant",
        accessor: "percentage_compliant",
        sortType: "basic",
        Cell: props => <div>{formatter.format(props.cell.value)}</div>
      }
    ]
  };

  const tblPropsApplications = {
    data:
      complianceApplications.length > 0
        ? complianceApplications.map(data => ({
            id: data.id,
            idLink: data.idLink,
            name: data.name,
            owner: data.owner,
            compliant: data.compliant,
            total: data.total_resources,
            taggable: data.total_taggable,
            nonCompliant: data.nonCompliant,
            incomplete: data.incomplete,
            percent: data.percent
          }))
        : [],
    columns: [
      {
        Header: "Name",
        accessor: "name"
      },
      // {
      //   Header: "ID",
      //   accessor: "id"
      // },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: props => (
          <div
            style={
              props.cell.value.length > 30 ? { wordBreak: "break-word" } : {}
            }
          >
            {props.cell.value}
          </div>
        )
      },
      {
        Header: "Total",
        accessor: "total"
      },
      {
        Header: "Taggable",
        accessor: "taggable"
      },
      {
        Header: "Compliant",
        accessor: "compliant"
      },
      {
        Header: "Non-compliant",
        accessor: "nonCompliant"
      },
      {
        Header: "Incomplete",
        accessor: "incomplete"
      },
      {
        Header: "Percent compliant",
        accessor: "percent",
        sortType: "basic",
        Cell: props => <div>{formatter.format(props.cell.value)}</div>
      }
    ]
  };

  const tblPropsGcpProjects = {
    data:
      complianceGcpProjects.length > 0
        ? complianceGcpProjects.map(data => ({
            id: data.id,
            idLink: data.idLink,
            name: data.name,
            owner: data.owner,
            compliant: data.compliant,
            total: data.total_resources,
            taggable: data.total_taggable,
            nonCompliant: data.nonCompliant,
            incomplete: data.incomplete,
            percent: data.percent
          }))
        : [],
    columns: [
      {
        Header: "Name",
        accessor: "name"
      },
      // {
      //   Header: "ID",
      //   accessor: "id"
      // },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: props => (
          <div
            style={
              props.cell.value.length > 30 ? { wordBreak: "break-word" } : {}
            }
          >
            {props.cell.value}
          </div>
        )
      },
      {
        Header: "Total",
        accessor: "total"
      },
      {
        Header: "Taggable",
        accessor: "taggable"
      },
      {
        Header: "Compliant",
        accessor: "compliant"
      },
      {
        Header: "Non-compliant",
        accessor: "nonCompliant"
      },
      {
        Header: "Incomplete",
        accessor: "incomplete"
      },
      {
        Header: "Percent compliant",
        accessor: "percent",
        sortType: "basic",
        Cell: props => <div>{formatter.format(props.cell.value)}</div>
      }
    ]
  };

  const tblPropsAzureRgs = {
    data:
      complianceAzureRg.length > 0
        ? complianceAzureRg.map(data => ({
            id: data.id,
            idLink: data.idLink,
            name: data.name,
            owner: data.owner,
            compliant: data.compliant,
            total: data.total_resources,
            taggable: data.total_taggable,
            nonCompliant: data.nonCompliant,
            incomplete: data.incomplete,
            percent: data.percent
          }))
        : [],
    columns: [
      {
        Header: "Name",
        accessor: "name"
      },
      // {
      //   Header: "ID",
      //   accessor: "id"
      // },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: props => (
          <div
            style={
              props.cell.value.length > 30 ? { wordBreak: "break-word" } : {}
            }
          >
            {props.cell.value}
          </div>
        )
      },
      {
        Header: "Total",
        accessor: "total"
      },
      {
        Header: "Taggable",
        accessor: "taggable"
      },
      {
        Header: "Compliant",
        accessor: "compliant"
      },
      {
        Header: "Non-compliant",
        accessor: "nonCompliant"
      },
      {
        Header: "Incomplete",
        accessor: "incomplete"
      },
      {
        Header: "Percent compliant",
        accessor: "percent",
        sortType: "basic",
        Cell: props => <div>{formatter.format(props.cell.value)}</div>
      }
    ]
  };

  const tblPropsAzureSub = {
    data:
      complianceAzureSub.length > 0
        ? complianceAzureSub.map(data => ({
            id: data.id,
            idLink: data.idLink,
            name: data.name,
            owner: data.owner,
            compliant: data.compliant,
            total: data.total_resources,
            taggable: data.total_taggable,
            nonCompliant: data.nonCompliant,
            incomplete: data.incomplete,
            percent: data.percent
          }))
        : [],
    columns: [
      {
        Header: "Name",
        accessor: "name"
      },
      // {
      //   Header: "ID",
      //   accessor: "id"
      // },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: props => (
          <div
            style={
              props.cell.value.length > 30 ? { wordBreak: "break-word" } : {}
            }
          >
            {props.cell.value}
          </div>
        )
      },
      {
        Header: "Total",
        accessor: "total"
      },
      {
        Header: "Taggable",
        accessor: "taggable"
      },
      {
        Header: "Compliant",
        accessor: "compliant"
      },
      {
        Header: "Non-compliant",
        accessor: "nonCompliant"
      },
      {
        Header: "Incomplete",
        accessor: "incomplete"
      },
      {
        Header: "Percent compliant",
        accessor: "percent",
        sortType: "basic",
        Cell: props => <div>{formatter.format(props.cell.value)}</div>
      }
    ]
  };

  // note actions+button is disabled until we build the queries
  const tblPropsAccounts = {
    data:
      complianceAccounts.length > 0
        ? complianceAccounts.map(data => ({
            id: data.id,
            account_id: data.account_id,
            account_type: data.account_type,
            name: data.name,
            owner: data.owner,
            compliant: data.compliant,
            total: data.total_resources,
            taggable: data.total_taggable,
            nonCompliant: data.nonCompliant,
            incomplete: data.incomplete,
            percent: data.percent,
            actions: data.actions
          }))
        : [],
    columns: [
      {
        Header: "Name",
        accessor: "name"
      },
      // {
      //   Header: "ID",
      //   accessor: "id"
      // },
      {
        Header: "Account ID",
        accessor: "account_id"
      },
      {
        Header: "Account Type",
        accessor: "account_type"
      },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: props => (
          <div
            style={
              props.cell.value.length > 30 ? { wordBreak: "break-word" } : {}
            }
          >
            {props.cell.value}
          </div>
        )
      },
      {
        Header: "Total",
        accessor: "total"
      },
      {
        Header: "Taggable",
        accessor: "taggable"
      },
      {
        Header: "Compliant",
        accessor: "compliant"
      },
      {
        Header: "Non-compliant",
        accessor: "nonCompliant"
      },
      {
        Header: "Incomplete",
        accessor: "incomplete"
      },
      {
        Header: "Percent compliant",
        accessor: "percent",
        sortType: "basic",
        Cell: props => <div>{formatter.format(props.cell.value)}</div>
      }
      // {
      //   Header: "",
      //   accessor: "actions"
      // }
    ]
  };

  const createTableOverall = () => {
    return <SortableTable {...tblPropsOverall} />;
  };

  const createTableApplications = () => {
    return <SortableTable {...tblPropsApplications} />;
  };

  const createTableGcpProjects = () => {
    return <SortableTable {...tblPropsGcpProjects} />;
  };

  const createTableAzureRgs = () => {
    return <SortableTable {...tblPropsAzureRgs} />;
  };

  const createTableAzureSubs = () => {
    return <SortableTable {...tblPropsAzureSub} />;
  };

  const createTableAccounts = () => {
    return <SortableTable {...tblPropsAccounts} />;
  };

  // currently unused until we build the queries
  // const createTableAccount = () => {
  //   return <SortableTable {...tblPropsAccount} />;
  // };

  // vars for pie chart
  const pieData = () => {
    var data = [];
    if (compliantBreakout.percentage_compliant > 0) {
      data.push({
        title: "Compliant",
        value: (compliantBreakout.percentage_compliant * 100).toFixed(1),
        color: "#5197D6"
      });
    }
    return data;
  };

  return systems.length === 0 ? (
    <Section
      id={props.id}
      title="Tag Compliance"
      align="center"
      collapsible="True"
      collapsibleConfig={{ open: true }}
    >
      {"No systems in this SC"}
      <div style={{ marginBottom: "10px" }}></div>
      <Button
        title="Edit"
        onClick={() =>
          navigate(basePath + "/collection/" + props.collection.key)
        }
      >
        Edit SC
      </Button>
    </Section>
  ) : (
    <Section
      id={props.id}
      title="Tag Compliance"
      collapsible="True"
      collapsibleConfig={{ open: true }}
    >
      <Section
        style={{ paddingLeft: "0px", paddingRight: "0px", margin: "0px" }}
        id={props.id}
        title="Overall Compliance"
        align="center"
      >
        {errorMessage ? (
          <APIError error={errorMessage} showTitle={true} />
        ) : compliantBreakout ? (
          compliantBreakout.applications ? (
            compliantBreakout.total_resources -
              compliantBreakout.total_compliant <
            0 ? (
              <div>
                Chart cannot be drawn
                {createTableOverall()}
              </div>
            ) : (
              <div>
                <div style={{ padding: "10px" }}>
                  <PieChart
                    style={{ width: "20%", height: "20%" }}
                    viewBoxSize={[100, 100]}
                    radius={50}
                    data={pieData()}
                    totalValue={100}
                    lineWidth={20}
                    background="#C7C7C7"
                    label={data => data.dataEntry.value + "%"}
                    labelStyle={{
                      fontSize: "25px",
                      fontFamily: "sans-serif",
                      fill: "#5197D6"
                    }}
                    labelPosition={0}
                  />
                </div>
                <div style={{ padding: "10px" }}>
                  <ComplianceGraph data={barData} />
                </div>
                <div style={{ marginBottom: "30px" }}></div>
                {createTableOverall()}
              </div>
            )
          ) : (
            <Spinner />
          )
        ) : (
          <Spinner />
        )}
      </Section>
      <Section
        style={{ paddingLeft: "0px", paddingRight: "0px", margin: "0px" }}
        id={props.id}
        title="Applications"
      >
        <div style={{ textAlign: "center" }}>
          {compliantBreakout ? (
            compliantBreakout.applications ? (
              complianceApplications.length === 0 ? (
                "No applications compliance data for this SC"
              ) : (
                createTableApplications()
              )
            ) : (
              <Spinner />
            )
          ) : breakout.applications.length === 0 ? (
            "No applications compliance data for this SC"
          ) : !errorMessage ? (
            <Spinner />
          ) : (
            <APIError error={errorMessage} showTitle={true} />
          )}
        </div>
      </Section>
      <Section
        style={{ paddingLeft: "0px", paddingRight: "0px", margin: "0px" }}
        id={props.id}
        title="Accounts"
      >
        <div style={{ textAlign: "center" }}>
          {compliantBreakout ? (
            compliantBreakout.accounts ? (
              complianceAccounts.length === 0 ? (
                "No accounts compliance data for this SC"
              ) : (
                createTableAccounts()
              )
            ) : (
              <Spinner />
            )
          ) : breakout.accounts.length === 0 ? (
            "No accounts compliance data for this SC"
          ) : !errorMessage ? (
            <Spinner />
          ) : (
            <APIError error={errorMessage} showTitle={true} />
          )}
        </div>
      </Section>
      <Section
        style={{ paddingLeft: "0px", paddingRight: "0px", margin: "0px" }}
        id={props.id}
        title="GCP Projects"
      >
        <div style={{ textAlign: "center" }}>
          {compliantBreakout ? (
            compliantBreakout.gcp_projects ? (
              complianceGcpProjects.length === 0 ? (
                "No GCP Project compliance data for this SC"
              ) : (
                createTableGcpProjects()
              )
            ) : (
              <Spinner />
            )
          ) : breakout.gcp_projects.length === 0 ? (
            "No GCP Project compliance data for this SC"
          ) : !errorMessage ? (
            <Spinner />
          ) : (
            <APIError error={errorMessage} showTitle={true} />
          )}
        </div>
      </Section>
      <Section
        style={{ paddingLeft: "0px", paddingRight: "0px", margin: "0px" }}
        id={props.id}
        title="Azure Resource Groups"
      >
        <div style={{ textAlign: "center" }}>
          {compliantBreakout ? (
            compliantBreakout.azure_rgs ? (
              complianceAzureRg.length === 0 ? (
                "No Azure Resource Group compliance data for this SC"
              ) : (
                createTableAzureRgs()
              )
            ) : (
              <Spinner />
            )
          ) : breakout.azure_rgs.length === 0 ? (
            "No Azure Resource Group compliance data for this SC"
          ) : !errorMessage ? (
            <Spinner />
          ) : (
            <APIError error={errorMessage} showTitle={true} />
          )}
        </div>
      </Section>
      <Section
        style={{ paddingLeft: "0px", paddingRight: "0px", margin: "0px" }}
        id={props.id}
        title="Azure Subscriptions"
      >
        <div style={{ textAlign: "center" }}>
          {compliantBreakout ? (
            compliantBreakout.azure_subscriptions ? (
              complianceAzureSub.length === 0 ? (
                "No Azure Subscription compliance data for this SC"
              ) : (
                createTableAzureSubs()
              )
            ) : (
              <Spinner />
            )
          ) : breakout.azure_subscriptions.length === 0 ? (
            "No Azure Subscription compliance data for this SC"
          ) : !errorMessage ? (
            <Spinner />
          ) : (
            <APIError error={errorMessage} showTitle={true} />
          )}
        </div>
      </Section>
    </Section>
  );
};
