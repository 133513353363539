import React from "react";
import PropTypes from "prop-types";
import { PaginationTable } from "@nike/epic-react-ui";

const SortableTable = ({ columns, data }) => {
  /* eslint-disable */
  // We disable this because react-table handles all the key logic
  return (
    <div>
      <PaginationTable
        data={data}
        columns={columns}
        isCompact={true}
        pageSizeOptions={[10, 25, 50]}
      />
    </div>
  );
  /* eslint-enable */
};

SortableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object)
};

export default SortableTable;
