import React from "react";
import { navigate } from "raviger";
import { Card } from "@nike/epic-react-ui";

let basePath =
  window.location.href.indexOf("platforms.nike.com") !== -1 ? "/cloudred" : "";

const IndexHome = () => (
  <div style={{ margin: "60px 0px 0px" }}>
    <div className="card-container">
      <Card
        className="card"
        title="System Index"
        hover
        description="View registered Cloudred Systems"
        onClick={() => navigate(basePath + "/system/index")}
      />
      <Card
        className="card"
        title="Account Index"
        hover
        description="View AWS Accounts Cloudred has discovered resources for and any systems that are associated with the account"
        onClick={() => navigate(basePath + "/account/index")}
      />
      <Card
        className="card"
        title="System Collection Index"
        hover
        description="View registered Cloudred System Collections. These are user defined groupings of systems."
        onClick={() => navigate(basePath + "/collection/index")}
      />
    </div>
    <div className="card-container">
      <Card
        className="card"
        title="Register a System"
        hover
        description="Register a system in CloudRed and start using the cloud services provided to you!"
        onClick={() => navigate(basePath + "/system/register")}
      />
      <Card
        className="card"
        title="Register a Systems Collection"
        hover
        description="Register a systems collection in CloudRed to group systems together for reporting."
        onClick={() => navigate(basePath + "/collection/register")}
      />
    </div>
  </div>
);

export default IndexHome;
