import React, { useState } from "react";
import {
  FilterableSortTable,
  makeTextFilter
} from "../components/table/index.js";
import { Section, Spinner } from "@nike/epic-react-ui";

export const RulesTable = props => {
  //console.log(props.rule);
  const [caseRestrictions, setCaseRestrictions] = useState(null);
  const [illegalAttributes, setIllegalAttributes] = useState([]);
  const [validation, setValidation] = useState([]);
  const [processed, setProcessed] = useState(false);
  const [section, setSection] = useState([]);

  if (!processed) {
    if ("case_rule" in props.rule) {
      setCaseRestrictions(props.rule.case_rule);
    }

    const illegalList = [];
    for (var entry in props.rule) {
      //console.log(entry);
      if (entry.includes("illegal")) {
        for (var value in props.rule[entry]) {
          var local_entry = "";
          if (entry === "illegal_strings") {
            local_entry = "Must Not Contain";
          } else if (entry.includes("starts")) {
            local_entry = "Must Not Start With";
          } else if (entry.includes("ends")) {
            local_entry = "Must Not End With";
          } else {
            local_entry = entry;
          }
          illegalList.push({
            type: local_entry,
            value: props.rule[entry][value]
          });
        }
      }
    }

    let localIllegal = illegalAttributes;
    if (illegalList.length > 0) {
      const illegalAttributesLocal = {
        data: illegalList.map(data => ({
          Type: data.type,
          Value: data.value
        })),
        fields: ["Type", "Value"],
        columns: [
          {
            Header: "Type",
            accessor: "Type"
          },
          {
            Header: "Value",
            accessor: "Value"
          }
        ]
      };
      localIllegal.push(
        <FilterableSortTable
          key={props.rule.tag_key}
          {...illegalAttributesLocal}
        />
      );
      setIllegalAttributes(localIllegal);
    }

    if ("validation_endpoint" in props.rule) {
      var validationSummary =
        props.rule.validation_endpoint.description +
        " at: " +
        props.rule.validation_endpoint.url;
      setValidation(validationSummary);
    }

    let localSection = section;
    if ("option_list" in props.rule && "value_list" in props.rule) {
      //console.log(props.rule.tag_key);
      const optionList = [];
      props.rule.option_list.forEach((item, index) => {
        optionList.push({
          value: item.id,
          label: item.value
        });
      });

      const attributes = {
        data: optionList.map(data => ({
          Value: data.value,
          Friendly: data.label
        })),
        fields: ["Value", "Friendly"],
        columns: [
          {
            Header: "Value",
            accessor: "Value"
          },
          {
            Header: "Friendly Name",
            accessor: "Friendly"
          }
        ]
      };
      localSection.push(
        <FilterableSortTable key={props.rule.tag_key} {...attributes} />
      );
    } else if ("value_list" in props.rule) {
      //console.log(props.rule.tag_key);
      const valueList = [];
      props.rule.value_list.forEach((value, index) => {
        valueList.push({
          value: value
        });
      });

      const attributes = {
        data: valueList.map(data => ({
          Value: data.value
        })),
        fields: ["Value"],
        columns: [
          {
            Header: "Values",
            accessor: "Value"
          }
        ]
      };
      //console.log(localAttributes);
      localSection.push(
        <FilterableSortTable key={props.rule.tag_key} {...attributes} />
      );
    } else if ("prefix_list" in props.rule) {
      const prefixList = [];
      props.rule.prefix_list.forEach((value, index) => {
        prefixList.push({
          value: value
        });
      });

      const attributes = {
        data: prefixList.map(data => ({
          Value: data.value
        })),
        fields: ["Value"],
        columns: [
          {
            Header: "Values",
            accessor: "Value"
          }
        ]
      };
      //console.log(localAttributes);
      localSection.push(
        <FilterableSortTable key={props.rule.tag_key} {...attributes} />
      );
    }
    setSection(localSection);
    setProcessed(true);
  }

  return (
    <div>
      {section.length === 0 && !processed ? (
        <div>
          <Spinner overlay={false} show={true} />
        </div>
      ) : (
        <Section key={props.id} id={props.id} title={props.id}>
          <h3>
            <b>Tag Key:&nbsp;</b> {props.id}
          </h3>
          {props.rule.description ? (
            <h3>
              <b>Description:&nbsp;</b>
              <p>{props.rule.description}</p>
            </h3>
          ) : (
            ""
          )}
          {props.rule.benefit ? (
            <h3>
              <b>Benefit:&nbsp;</b>
              <p>{props.rule.benefit}</p>
            </h3>
          ) : (
            ""
          )}
          {props.rule.delimiter ? (
            <h3>
              <b>Prefix Delimiter:&nbsp;</b>
              {props.rule.delimiter}
            </h3>
          ) : (
            ""
          )}
          {caseRestrictions ? (
            <h3>
              <b>Case Rule:&nbsp;</b>
              {caseRestrictions}
              <br></br>
            </h3>
          ) : (
            ""
          )}
          {illegalAttributes.length > 0 ? (
            <h3>
              <b>Value Restrictions:</b>
              <br></br>
            </h3>
          ) : (
            ""
          )}
          {illegalAttributes.length > 0 ? <div>{illegalAttributes}</div> : ""}
          {validation.length > 0 ? (
            <h3>
              <b>Validation Method:&nbsp;</b>
              <br></br>
              {validation}
            </h3>
          ) : (
            ""
          )}
          {section.length > 0 ? (
            <h3>
              <b>Allowed Values:</b>
              <br></br>
            </h3>
          ) : (
            ""
          )}
          {section.length > 0 ? <div>{section}</div> : ""}
        </Section>
      )}
    </div>
  );
};
