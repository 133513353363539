import React from "react";
import { navigate } from "raviger";
import { Card } from "@nike/epic-react-ui";

let basePath =
  window.location.href.indexOf("platforms.nike.com") !== -1 ? "/cloudred" : "";

const UtilitiesHome = () => (
  <div style={{ margin: "60px 0px 0px" }}>
    <div className="card-container">
      <Card
        className="card"
        title="Search"
        hover
        description="Search for resources in CloudRed"
        onClick={() => navigate(basePath + "/search/")}
      />
      <Card
        className="card"
        title="Tagging Check"
        hover
        description="A standalone utility to validate tags"
        onClick={() => navigate(basePath + "/check")}
      />
    </div>
  </div>
);

export default UtilitiesHome;
