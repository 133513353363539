import React from "react";
import {
  BsFillPatchExclamationFill,
  BsFillPatchQuestionFill
} from "react-icons/bs";

export const StatusTable = ({ resource }) => {
  const errorKeys = resource.errorKeys;
  const errorMessage = [];

  errorKeys.forEach(key => {
    let status = "";
    let iconStatus = "";
    if (resource[`${key} present`]) {
      if (!resource[`${key} valid`]) {
        status = "invalid";
        iconStatus = <BsFillPatchExclamationFill color="#f44336" size="20px" />;
      }
    } else {
      status = "missing";
      iconStatus = <BsFillPatchQuestionFill color="#ff9800" size="20px" />;
    }
    errorMessage.push({ key: key, status: status, iconStatus: iconStatus });
  });

  return (
    <div>
      <table style={{ width: "100%" }}>
        <tbody>
          {errorMessage.map((error, i) => {
            return (
              <tr key={i}>
                <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>
                  {error.key}
                </td>
                <td
                  style={{ paddingLeft: "10px", textAlign: "right" }}
                  title={error.status}
                >
                  {error.iconStatus}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
