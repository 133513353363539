import React, { useState, useEffect } from "react";
import config from "../config.js";
import {
  TextInput,
  ToggleSwitch,
  Tooltip,
  Section,
  Icon,
  Button,
  Modal,
  Spinner,
  Select
} from "@nike/epic-react-ui";
import { SortableTable, StaticTable } from "../components/table/index.js";
import { boolean } from "./SystemData.js";

export const TaggingRecommendations = props => {
  const systemid = props.data.key;
  let trData = [];
  let trDataFmt = [];
  const [admin] = useState(props.system.is_admin);
  const [systemState] = useState(props.system.state);
  const [systemUpdated, setSystemUpdated] = useState(props.system.date_updated);
  const [modal, setModal] = useState(false);
  const [moveModal, setMoveModal] = useState(false);
  const [metadataKey, setMetadataKey] = useState("");
  const [metadataValue, setMetadataValue] = useState("");
  const [metadataCategory, setMetadataCategory] = useState("General");
  const [metadataVisible, setMetadataVisible] = useState(false);
  const [formStatus, setFormStatus] = useState("");
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [deleteSpinner, setDeleteSpinner] = useState([]);
  const [errors, setErrors] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [message, setMessage] = useState("");
  const metadata = props.metadata;
  const setMetadata = props.setMetadata;
  const metadataConfig = props.metadataConfig;
  const setMetadataConfig = props.setMetadataConfig;
  const tags = props.tags;
  const setTags = props.setTags;
  const [standard] = useState({
    value: "Latest",
    label: "Latest"
  });
  const [rules, setRules] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  var trTags = {};

  // set boolean value for use in selective rendering to see if system is editable
  var editable = false;
  if (admin && systemState === "active") {
    editable = true;
  }

  const formatRules = rules => {
    const formattedRules = [];
    for (var key in rules) {
      formattedRules[key] = rules[key];
    }
    setRules(formattedRules);
  };

  const createNewMetadata = () => {
    if (!validateRequiredFields()) {
      return setErrors(true);
    } else {
      setErrors(false);
    }
    const sendMetadata = { ...metadata };
    sendMetadata[metadataKey] = metadataValue;
    const sendMetadataConfig = {
      [metadataKey]: {
        category: metadataCategory,
        visible: metadataVisible
      }
    };
    updateMetadata(sendMetadata, sendMetadataConfig);
  };

  //console.log(trTags);
  useEffect(() => {
    taggingRecommendations();
    applyStandard();
  }, [trTags.length, systemUpdated, props.accessToken]);

  const deleteMetadata = key => {
    setDeleteSpinner([key]);
    const sendMetadata = { ...metadata };
    delete sendMetadata[key];
    updateMetadata(sendMetadata);
  };

  const editMetadata = key => {
    setMetadataKey(key);
    setMetadataValue(metadata[key]);
    setMetadataCategory(metadataConfig[key].category);
    setMetadataVisible(metadataConfig[key].visible);
    setModal(true);
  };

  const moveMetadata = () => {
    const sendTags = { ...tags };
    sendTags[metadataKey] = metadataValue;
    if (metadataKey in tags) {
      setMoveModal(false);
      setFormStatus(
        <div
          className="epic-color-error system-info-message"
          style={{ width: "300px" }}
        >
          <Icon className="system-info-status" type="warning" />
          <div>Key already exists in tag set</div>
        </div>
      );
      setTimeout(() => {
        setFormStatus("");
      }, 4000);
      return;
    }

    updateTags(sendTags);
  };

  const moveMetadataModal = key => {
    setMetadataKey(key);
    setMetadataValue(metadata[key]);
    setMetadataCategory(metadataConfig[key].category);
    setMetadataVisible(metadataConfig[key].visible);
    setMoveModal(true);
  };

  // add tag and remove metadata
  const updateTags = sendTags => {
    setUpdateSpinner(true);
    fetch(config.apiUrl + "/ssot/systems/" + systemid, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        tags: sendTags
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setUpdateSpinner(false);
          setMoveModal(false);
          let matches = response.details[0].matchAll(/'(.*?)'/g);
          let errors = [];
          for (const error of matches) {
            errors.push(error[1]);
          }
          setFormStatus(
            <div
              className="epic-color-error system-info-message"
              style={{ width: "500px" }}
            >
              <Icon className="system-info-status" type="warning" />
              <div>
                {response.message}
                <ul style={{ textAlign: "left", margin: "10px 0px 0px 100px" }}>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 8000);
        } else if (!response.key) {
          throw Error("Failure in System API");
        } else {
          setTags(response.tags);
          deleteMetadata(metadataKey);
          setMoveModal(false);
          setSystemUpdated(response.date_updated);
        }
      })
      .catch(error => {
        setFormStatus(
          <div className="system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 6000);

        setUpdateSpinner(false);
        setMoveModal(false);
      });
  };

  const applyStandard = () => {
    if (standard === "") return;
    fetch(config.apiUrl + "/tagging/guided_validation", {
      // fetch("http://127.0.0.1:8002/guided_validation", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      },
      method: "POST",
      body: JSON.stringify({
        standard: standard.value,
        tags: trTags
      })
    })
      .then(response => response.json())
      .then(response => {
        if (!response.status) {
          throw Error("Failure in validate API");
        } else if (response.status === "fail") {
          throw Error(response.message);
        } else if (response.status === "incomplete compliance") {
          //console.log(response);
          formatRules(response.rule_data);
          var compliance_warnings = [];
          for (var entry of response.reasons) {
            var passed = false;
            for (var tag in trTags) {
              if (entry.includes(tag) && !passed) {
                compliance_warnings.push({
                  warning: entry,
                  recommendation:
                    "Update value in system tags to be compliant with the standard"
                });
                passed = true;
              }
            }
          }
          var compliance_data = {
            data: compliance_warnings,
            columns: [
              {
                Header: "Warning",
                accessor: "warning"
              },
              {
                Header: "Recommendation",
                accessor: "recommendation"
              }
            ]
          };
          if (compliance_warnings.length > 0) {
            setMessage(
              <div>
                <h2
                  className="epic-font-brand"
                  style={{
                    textAlign: "left",
                    fontSize: "14",
                    color: "#ffbf00"
                  }}
                >
                  Tag Configuration Validation Warnings:
                </h2>
                <StaticTable {...compliance_data} />
              </div>
            );
          }
        } else {
          /*setFormStatus(
            <div className="system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Validation Successful!"}</div>
            </div>
          );*/
          formatRules(response.rule_data);
          setMessage(
            <div>
              <h2
                className="epic-font-brand"
                style={{
                  textAlign: "left",
                  fontSize: "14",
                  color: "green"
                }}
              >
                Tag configuration is compliant
              </h2>
              No validation issues were found for the tags that are configured
              for the system
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 6000);
        }
      })
      .catch(error => {
        setMessage(
          <div>
            Failure in validation:
            <ul>
              <li key={error.message}>
                <pre>{error.message}</pre>
              </li>
            </ul>
          </div>
        );
        console.log(error);
        setShowOverlay(true);
      });
  };

  const validateRequiredFields = () => {
    return metadataKey && metadataValue && metadataCategory;
  };

  const buttonState = data => {
    if (admin && systemState === "active") {
      return (
        <div style={{ textAlign: "center" }}>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => editMetadata(data.key)}
          >
            {/* <Icon type="edit" /> */}
            Edit
          </Button>
          <span style={{ padding: "5px" }}></span>
          {/* disabled={deleteSpinner.length > 0 ? true : updateSpinner} */}
          <Button
            small
            disabled={true}
            onClick={() => moveMetadataModal(data.key)}
          >
            Accept
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => deleteMetadata(data.key)}
          >
            {/* <Icon type="delete" /> */}
            Delete
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <Button small disabled>
            {/* <Icon type="edit" /> */}
            Edit
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button small disabled>
            Promote
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button small disabled>
            {/* <Icon type="delete" /> */}
            Delete
          </Button>
        </div>
      );
    }
  };

  const updateMetadata = (sendMetadata, sendMetadataConfig) => {
    setUpdateSpinner(true);
    let body = sendMetadataConfig
      ? { metadata: sendMetadata, metadata_config: sendMetadataConfig }
      : { metadata: sendMetadata };
    fetch(config.apiUrl + "/ssot/systems/" + systemid, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          throw Error(response.message[0].message);
        } else if (!response.key) {
          throw Error("Failure in Systems API");
        } else {
          setMetadataKey("");
          setMetadataValue("");
          setMetadataCategory("General");
          setMetadataVisible(false);
          setUpdateSpinner(false);
          setDeleteSpinner([]);
          setModal(false);
          setMetadata(response.metadata);
          setMetadataConfig(response.metadata_config);
          setSystemUpdated(response.date_updated);
          setFormStatus(
            <div className="epic-color-success system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Change saved."}</div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 2000);
          setUpdateSpinner(false);
          setDeleteSpinner([]);
        }
      })
      .catch(error => {
        console.log(error);
        setFormStatus(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 6000);

        setMetadataKey("");
        setMetadataValue("");
        setMetadataCategory("");
        setMetadataVisible(false);
        setUpdateSpinner(false);
        setDeleteSpinner([]);
        setModal(false);
      });
  };

  const getTagRule = tag_key => {
    let rule = rules[tag_key];

    if ("option_list" in rule) {
      const valueList = [];
      rule.option_list.forEach((item, index) => {
        valueList.push({
          value: item.id,
          label: item.value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports a set list friendly names and their associated values.
            Selecting an option will set the value to the associated value"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Value"
            className="textInput"
            name="tagvalue"
            options={valueList}
            value={metadataValue}
            onChange={selected => setMetadataValue(selected.value)}
          />
        </div>
      );
    }

    if ("value_list" in rule) {
      const valueList = [];
      rule.value_list.forEach((value, index) => {
        valueList.push({
          value: value,
          label: value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports a list of managed values"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Value"
            className="textInput"
            name="tagvalue"
            options={valueList}
            value={metadataValue}
            onChange={selected => setMetadataValue(selected.value)}
          />
        </div>
      );
    }

    if ("prefix_list" in rule) {
      const valueList = [];
      rule.prefix_list.forEach((value, index) => {
        valueList.push({
          value: value,
          label: value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports prefixes from a list of managed values and an optional suffix, the result of setting both will be hypenated"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Prefix"
            className="textInput"
            name="prefix"
            options={valueList}
            value={prefix}
            onChange={selected => setPrefix(selected)}
          />
          <TextInput
            label="Suffix (Optional)"
            className="textInput"
            full
            name="suffix"
            value={suffix}
            onChange={e => {
              let suffix = e.target.value;
              if ("case_rule" in rule) {
                if (rule.case_rule === "lower") suffix = suffix.toLowerCase();
                else if (rule.case_rule === "upper")
                  suffix = suffix.toUpperCase();
              }

              if ("illegal_strings" in rule) {
                rule.illegal_strings.forEach((item, index) => {
                  suffix = suffix.replace(item, "");
                });
              }

              setSuffix(suffix);
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <Tooltip
          text="This tag key supports free form text but may have case or other restrictions"
          inverse={true}
        >
          Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
        </Tooltip>
        <TextInput
          label="Value"
          className="textInput"
          full
          name="tagvalue"
          value={metadataValue}
          onChange={e => {
            let tag_value = e.target.value;
            if ("case_rule" in rule) {
              if (rule.case_rule === "lower")
                tag_value = tag_value.toLowerCase();
              else if (rule.case_rule === "upper")
                tag_value = tag_value.toUpperCase();
            }

            if ("illegal_strings" in rule) {
              rule.illegal_strings.forEach((item, index) => {
                tag_value = tag_value.replace(item, "");
              });
            }

            setMetadataValue(tag_value);
          }}
        />
      </div>
    );
  };

  const taggingRecommendations = () => {
    for (var key in metadata) {
      let category = "";
      let visible = "";

      if (metadataConfig && metadataConfig[key]) {
        category = metadataConfig[key].category;
        visible = metadataConfig[key].visible.toString();
      }

      console.log(category);
      if (category === "Tagging Recommendation") {
        trData.push({
          key: key,
          value: metadata[key],
          category: category,
          visible: visible
        });
        trTags[key] = metadata[key];
      }
    }
  };
  //console.log(trTags);
  taggingRecommendations();

  trDataFmt = trData.map(data => ({
    key: data.key,
    value: data.value,
    category: data.category,
    visible: data.visible,
    actions: buttonState(data)
  }));

  const trTblProps = {
    data: trDataFmt,
    columns: [
      {
        Header: "Key",
        accessor: "key"
      },
      {
        Header: "Value",
        accessor: "value"
      },
      {
        Header: "Category",
        accessor: "category"
      },
      {
        Header: "Visible",
        accessor: "visible"
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {deleteSpinner.includes(row.original.key) ? (
              <Spinner />
            ) : (
              row.original.actions
            )}
          </div>
        )
      }
    ],
    minRows: 0,
    showPagination: false,
    filterable: false
  };

  //console.log(trTblProps);
  let tbl = trTblProps ? (
    <SortableTable {...trTblProps} />
  ) : (
    <p>No Tagging Recommendations have been defined for this system</p>
  );

  console.log(rules);
  return (
    <Section id={props.id} key={props.id} title="Tagging Recommendations">
      <Modal
        onClose={() => {
          setModal(false);
          setErrors(false);
        }}
        closeButton
        title=""
        show={modal}
      >
        <div>
          <div key="inputs" style={{ marginBottom: "30px" }}>
            <TextInput
              key="metadataKey"
              label="Key"
              className="textInput"
              full
              value={metadataKey}
              onChange={e => setMetadataKey(e.target.value)}
              hasErrors={errors && !metadataKey}
            />
            <div key="keyspacer" style={{ marginBottom: "30px" }}></div>
            {metadataKey in rules ? (
              getTagRule(metadataKey)
            ) : (
              <TextInput
                key="metadataValue"
                label="Value"
                className="textInput"
                full
                value={metadataValue}
                onChange={e => setMetadataValue(e.target.value)}
                hasErrors={errors && !metadataValue}
              />
            )}
            <div key="valuespacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              key="metadataCategory"
              label="Category"
              className="textInput"
              full
              value={metadataCategory}
              onChange={e => setMetadataCategory(e.target.value)}
              hasErrors={errors && !metadataCategory}
            />
            <div key="categoryspacer" style={{ marginBottom: "30px" }}></div>
            <div style={{ margin: "0px 0px 3px 10px" }}>
              <Tooltip
                text="Whether non-owners can view this metadata"
                inverse={true}
              >
                Visible&nbsp;<Icon type="walkthrough" size="small"></Icon>
              </Tooltip>
            </div>
            <ToggleSwitch
              options={boolean}
              value={metadataVisible}
              name="visible"
              onChange={e => setMetadataVisible(e)}
            />
          </div>
          <div
            key="submit"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              disabled={updateSpinner}
              onClick={() => createNewMetadata()}
            >
              Submit
            </Button>
          </div>
          <div key="spacerBottom" style={{ margin: "30px" }}></div>
          {updateSpinner ? (
            <div>
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <Modal
        onClose={() => setMoveModal(false)}
        closeButton
        title=""
        show={moveModal}
      >
        <div>
          <div key="inputs" style={{ marginBottom: "30px" }}>
            <TextInput
              key="metadataKey"
              label="Key"
              className="textInput"
              full
              value={metadataKey}
              disabled
            />
            <div key="keyspacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              label="Value"
              className="textInput"
              full
              value={metadataValue}
            />
            <TextInput
              key="metadataValue"
              label="Value"
              className="textInput"
              full
              value={metadataValue}
              disabled
            />
            <div key="valuespacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              key="metadataCategory"
              label="Category"
              className="textInput"
              full
              value={metadataCategory}
              disabled
            />
            <div key="categoryspacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              key="metadataVisible"
              label="Visible"
              className="textInput"
              full
              value={metadataVisible}
              disabled
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              fontSize: "16px"
            }}
          >
            Move from Metadata to Tag Set?
          </div>
          <div
            key="submit"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button disabled={updateSpinner} onClick={() => moveMetadata()}>
              Submit
            </Button>
          </div>
          <div key="spacerBottom" style={{ margin: "30px" }}></div>
          {updateSpinner ? (
            <div>
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      {formStatus}
      {message ? <div style={{ margin: "20px" }}>{message}</div> : <div></div>}
      {tbl}
    </Section>
  );
};
