import React from "react";
import { TextInput } from "@nike/epic-react-ui";

export default function makeTextFilter(placeholder) {
  return function TextFilter({ column: { filterValue, setFilter } }) {
    return (
      <TextInput
        style={{ width: "100%", marginTop: "10px" }}
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={placeholder}
      />
    );
  };
}
