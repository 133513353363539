import { fromJS } from "immutable";

import { notifyOffline } from "./actions.js";

const defaultState = fromJS({
  offline: false
});

export default function notifications(state = defaultState, action) {
  state = fromJS(state); // Force immutable in case of rehydration

  switch (action.type) {
    case notifyOffline.type:
      return state.set("offline", action.payload.offline);
    default:
      return state;
  }
}

export const isOffline = state => state.offline.get("offline");
