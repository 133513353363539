import React, { useState, useEffect } from "react";
import { Button, Icon, Section, Spinner } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";
import { FilterableSortTable } from "../components/table/index.js";
import ComplianceGraph from "./TaggingComplianceGraph";
import { navigate } from "raviger";

const TaggingComplianceTagSummary = props => {
  const [buckets, setBuckets] = useState([]);
  const [incomplete, setIncomplete] = useState(null);
  const [valid, setValid] = useState(null);
  const [invalid, setInvalid] = useState(null);
  const [errorMessageQuery, setErrorMessageQuery] = useState(null);
  const [hasBarData, setHasBarData] = useState(false);
  const [nameLookup, setNameLookup] = useState({});
  const friendlyNames = props.friendlyNames;
  const filter = props.filter;
  const subFilter = props.subFilter;

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  let barData = [
    {
      label: "Count",
      data: [
        ["Incomplete", incomplete],
        ["Non Compliant", invalid],
        ["Compliant", valid]
      ]
    }
  ];

  let system_data = [];
  for (const entry in buckets.sort(function(a, b) {
    if (a.InvalidResources > b.InvalidResources) {
      return -1;
    }
    if (a.InvalidResources < b.InvalidResources) {
      return 1;
    }
    return 0;
  })) {
    if (buckets[entry].InvalidResources > 0 && system_data.length < 20) {
      system_data.push([
        nameLookup[buckets[entry].Key],
        buckets[entry].InvalidResources
      ]);
    }
  }

  let system_compliance = [
    {
      label: "Count",
      data: system_data
    }
  ];

  const showTable = () => {
    return (
      <div>
        <FilterableSortTable {...attributes} />
      </div>
    );
  };

  const filterTitle = () => {
    if (typeof filter === "undefined") {
      return "System ID";
    }

    switch (filter) {
      case "SYSTEM_ID":
        return "System ID";
      case "ACCOUNT":
        return "Account";
      case "RESOURCE_TYPE":
        return "Resource Type";
      case "FinOps_MAPPING.Domain":
        return "Domain";
      case "FinOps_MAPPING.Subdomain":
        return "Subdomain";
      case "FinOps_MAPPING.Subdepartment":
        return "Subdepartment";
      case "nike-owner":
        return "Owner";
      default:
        return "Undefined";
    }
  };

  useEffect(() => {
    var localFilter = "";
    if (typeof filter === "undefined") {
      localFilter = "SYSTEM_ID";
    } else {
      localFilter = filter;
    }

    let filterPath = "/cloud-resources/compliance/summary/" + localFilter;
    let filterParams = "?sub_filter=" + subFilter;
    fetch(config.apiUrl + filterPath + filterParams, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          // console.log(indicies);
          setErrorMessageQuery(response);
        } else if (!response.hasOwnProperty("ValidResources")) {
          throw Error("Failure in resources API");
        } else {
          setErrorMessageQuery(null);
          const nameHash = {};
          for (const obj of response.BreakdownByFilter) {
            let name = "Undefined";
            let key = obj.Key;
            // console.log(friendlyNames);
            if (friendlyNames.hasOwnProperty(key)) {
              // console.log(key);
              name = friendlyNames[key];
              // console.log(name);
            } else if (filter === "RESOURCE_TYPE") {
              name = key
                .replace("aws_", "")
                .replace("azure_", "")
                .replace(/_/g, " ");
            } else {
              name = key;
            }
            nameHash[key] = name;
          }
          setBuckets(response.BreakdownByFilter);
          setValid(response.ValidResources);
          setInvalid(response.InvalidResources);
          setIncomplete(response.IncompleteResources);
          setNameLookup(nameHash);
          setHasBarData(true);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageQuery(error);
      });
  }, [filter, subFilter, props.accessToken, friendlyNames]);

  const attributes = {
    data: buckets.map(data => ({
      Key: data.Key,
      Name: nameLookup[data.Key],
      Percentage: Math.round(parseFloat(data.CompliancePercentage)) + "%",
      Resources: data.TotalResources,
      Resources_Fail: data.InvalidResources,
      Resources_Pass: data.ValidResources
    })),
    fields: ["Key", "Name"],
    columns: [
      {
        Header: "Filter",
        accessor: "Key"
      },
      {
        Header: "Name",
        accessor: "Name"
      },
      {
        Header: "Percentage",
        accessor: "Percentage"
      },
      {
        Header: "Total Resources",
        accessor: "Resources"
      },
      {
        Header: (
          <>
            Non-Compliant Resources
            <br />
          </>
        ),
        accessor: "Resources_Fail"
      },
      {
        Header: (
          <>
            Compliant Resources
            <br />
          </>
        ),
        accessor: "Resources_Pass"
      },
      {
        Header: "Non-Compliance Details",
        id: "details",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <a
              href={
                typeof filter === "undefined"
                  ? basePath + "/compliance/SYSTEM_ID/" + row.original.Key
                  : basePath + "/compliance/" + filter + "/" + row.original.Key
              }
            >
              <Button
                onClick={
                  typeof filter === "undefined"
                    ? () =>
                        navigate(
                          basePath + "/compliance/SYSTEM_ID/" + row.original.Key
                        )
                    : () =>
                        navigate(
                          basePath +
                            "/compliance/" +
                            filter +
                            "/" +
                            row.original.Key
                        )
                }
              >
                <Icon type="walkthrough" />
              </Button>
            </a>
          </div>
        )
      }
    ]
  };

  return (
    <div>
      <Section
        key={subFilter + "-charts"}
        id={subFilter + "-charts"}
        title={subFilter + " Charts"}
        subtitle={"Summarized by " + filterTitle()}
        collapsible="true"
        collapsibleConfig={{ open: true }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {!errorMessageQuery ? (
            !hasBarData ? (
              <div style={{ margin: "auto" }}>
                <Spinner overlay={false} show={true} />
              </div>
            ) : (
              <>
                <ComplianceGraph
                  data={barData}
                  margin={"20px 80px 20px 20px"}
                  header={"Overall Tagging Compliance (" + subFilter + ")"}
                />
                <ComplianceGraph
                  data={system_compliance}
                  margin={"20px 30px"}
                  header={
                    "Top 20 Items by Count of Non-Compliant Resources (" +
                    subFilter +
                    ")"
                  }
                />
              </>
            )
          ) : (
            <APIError showTitle={true} error={errorMessageQuery} />
          )}
        </div>
      </Section>
      <Section
        key={subFilter + "-data"}
        id={subFilter + "-data"}
        title={subFilter + " Data"}
        subtitle={"Summarized by " + filterTitle()}
        collapsible="true"
        collapsibleConfig={{ open: true }}
      >
        <div style={{ margin: "50px 0px 200px" }}>
          {!errorMessageQuery ? (
            showTable()
          ) : (
            <APIError showTitle={true} error={errorMessageQuery} />
          )}
        </div>
      </Section>
    </div>
  );
};

export default TaggingComplianceTagSummary;
