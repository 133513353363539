export const validate_tags = (tags, rules) => {
  const errors = [];
  rules.forEach((rule, index) => {
    if (rule.tag_key in tags) {
      for (var key in tags) {
        if (rule.tag_key === key) {
          if ("value_list" in rule) {
            if (!rule.value_list.includes(tags[key]))
              errors.push(key + " value not in list of allowed values");
          }

          if ("case_rule" in rule) {
            if (
              rule.case_rule === "lower" &&
              tags[key].toLowerCase() !== tags[key]
            )
              errors.push(key + " value not all lowercase");
            else if (
              rule.case_rule === "upper" &&
              tags[key].toUpperCase() !== tags[key]
            )
              errors.push(key + " value not all uppercase");
          }

          if ("illegal_strings" in rule) {
            //eslint-disable-next-line
                        rule.illegal_strings.forEach((value, index) => {
              if (tags[key].replace(value, "") !== tags[key])
                errors.push(
                  key + " value contains illegal string '" + value + "'"
                );
            });
          }
        }
      }
    } else if ("required" in rule && rule.required) {
      errors.push(rule.tag_key + " is required");
    }
  });

  if (errors.length > 0) {
    return { status: "fail", message: errors };
  }

  return { status: "pass" };
};
