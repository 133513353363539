import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Details, Systems, Settings } from "./CollectionDetails";
import APIError from "../components/APIError.js";
import { Scrollspy, Spinner } from "@nike/epic-react-ui";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import { clearIndexes } from "./CollectionActions";

const CollectionScreen = props => {
  const key = props.data.key;
  const [collection, setCollection] = useState(null);
  const [collectionReset, setCollectionReset] = useState(false);
  const [panels] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearIndexes());
  });

  useEffect(() => {
    setCollection(null);
    setCollectionReset(false);
    // let isSubscribed = true;
    // fetch("http://127.0.0.1:8000/" + key, {
    fetch(config.apiUrl + "/ssot/ous/" + key, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          console.log(response);
          setErrorMessage(response);
        } else if (!response.key) {
          throw Error("Failure in SC retrieval");
        } else {
          panels.splice(0, panels.length);

          panels.push({
            text: "Details",
            hash: "details",
            component: (
              <Details
                key="details"
                id="details"
                collection={response}
                {...props}
              />
            )
          });

          panels.push({
            text: "Systems",
            hash: "systems",
            component: (
              <Systems
                key="systems"
                id="systems"
                collection={response}
                {...props}
              />
            )
          });

          panels.push({
            text: "Settings",
            hash: "settings",
            component: (
              <Settings
                key="settings"
                id="settings"
                collection={response}
                {...props}
              />
            )
          });

          setCollection(response);
          setErrorMessage(null);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
    // return () => (isSubscribed = false);
  }, [key, panels, collectionReset, props]);

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
        .epic__sectionContent__947 {
          margin-top: 0px;
        }
        .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__container__d0a.epic__disabled__f8e {
            opacity: 1;
        }`}
      </style>
      {!collection ? (
        !errorMessage ? (
          <div>
            <Spinner overlay={false} show={true} />
          </div>
        ) : (
          <APIError showTitle={true} paddingLeft="1em" error={errorMessage} />
        )
      ) : (
        <div className="scrollspy-container">
          <Scrollspy
            title="SC Edit"
            items={panels}
            uppercaseTitle={true}
            uppercaseItems={true}
            className="scrollspy-menu"
          />
          <div className="scrollspy-content">
            {panels.map(data => {
              return data.component;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(CollectionScreen);
