import React from "react";
import PropTypes from "prop-types";
import { Table } from "@nike/epic-react-ui";

const StaticTable = ({ columns, data }) => {
  /* eslint-disable */
  // We disable this because react-table handles all the key logic
  return <Table data={data} columns={columns} isCompact={true} />;
  /* eslint-enable */
};

StaticTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object)
};

export default StaticTable;
