import React from "react";
import { Tabs } from "@nike/epic-react-ui";
import { ResourceData } from "./SystemResourceData.js";
import { ResourceTags } from "./SystemResourceTags.js";
import { ResourceAlerts } from "./SystemResourceAlerts.js";

export const ResourceDetails = props => {
  const displayDetails = () => {
    return (
      <div>
        <Tabs
          className="tabs"
          tabs={["Alerts", "Data", "Tags"]}
          selectedTabPanelClassName="activeTabPanel"
        >
          <Tabs.TabPanel className="tabPanel">
            <ResourceAlerts {...props} />
          </Tabs.TabPanel>
          <Tabs.TabPanel className="tabPanel">
            <ResourceData {...props} />
          </Tabs.TabPanel>
          <Tabs.TabPanel className="tabPanel">
            <ResourceTags {...props} />
          </Tabs.TabPanel>
        </Tabs>
      </div>
    );
  };

  return <div>{displayDetails()}</div>;
};
