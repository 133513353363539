import React, { useState, useEffect } from "react";
import config from "../config.js";
import { formatdata } from "./SystemData.js";
import APIError from "../components/APIError.js";
import { Spinner } from "@nike/epic-react-ui";

export const ResourceData = props => {
  const [resource, setResource] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetch(config.apiUrl + "/cloud-resources/customSearch", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      },
      method: "POST",
      body: JSON.stringify({
        size: 1,
        query: {
          bool: {
            filter: {
              range: {
                last_seen: {
                  gt: "now-24h"
                }
              }
            },
            must: [
              {
                match: {
                  _id: props.resource.ID
                }
              }
            ]
          }
        }
      })
    })
      .then(resources => resources.json())
      .then(resources => {
        if (resources.status === "fail") {
          setErrorMessage(resources);
          // throw Error(resources.message);
        } else if (!Array.isArray(resources) || !resources[0].ID) {
          throw Error("Failure in resource API");
        } else {
          setResource(resources[0]);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
    //eslint-disable-next-line
  }, [props.resource.ID, props.accessToken]);

  return (
    <div>
      {!errorMessage ? (
        resource ? (
          formatdata(resource)
        ) : (
          <Spinner />
        )
      ) : (
        <APIError showTitle={true} error={errorMessage} />
      )}
    </div>
  );
};
