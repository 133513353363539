import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";

import {
  TextInput,
  Collapsible,
  Button,
  Icon,
  Spinner
} from "@nike/epic-react-ui";
import { FilterableSortTable } from "../components/table/index.js";
import { navigate } from "raviger";
import APIError from "../components/APIError.js";

const CollectionIndex = user => {
  const [collections, setCollections] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageIdentity, setErrorMessageIdentity] = useState(null);
  const [identity, setIdentity] = useState(null);
  const [term, setTerm] = useState(null);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    // fetch("http://127.0.0.1:8000/", {
    fetch(config.apiUrl + "/ssot/ous/", {
      headers: {
        Authorization: "Bearer " + user.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setErrorMessage(response);
        } else {
          setCollections(response);
          setErrorMessage(null);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
  }, [user.accessToken]);

  useEffect(() => {
    fetch(config.apiUrl + "/identity/user/" + user.email.toLowerCase(), {
      // fetch(config.apiUrl + "/identity/user/" + user.email.toLowerCase(), {
      headers: {
        Authorization: "Bearer " + user.accessToken
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);
        if (response.status === "fail") {
          setErrorMessageIdentity(response);
          setIdentity(false);
        } else if (!Array.isArray(response) || response.length <= 0) {
          throw Error("Failure in identity API");
        } else {
          setIdentity(response[0]);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageIdentity(error);
        setIdentity(false);
      });
  }, [user.accessToken, user.email]);

  const props = {
    data: collections.map(data => ({
      Key: data.key,
      Type: data.type,
      Name: data.name,
      Description: data.description,
      Owner: data.owner,
      isOwner:
        data.owner.toLowerCase() === user.email.toLowerCase() ||
        (identity && identity.groups && identity.groups.includes(data.owner))
          ? "Yes"
          : "No"
    })),
    fields: ["Name", "Description", "Owner", "isOwner"],
    columns: [
      {
        Header: "Key",
        accessor: "Key"
      },
      {
        Header: "Name",
        accessor: "Name"
      },
      {
        Header: "Description",
        accessor: "Description"
      },
      {
        Header: "Owner",
        accessor: "Owner"
      },
      {
        Header: "Is Owner",
        accessor: "isOwner"
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <a href={basePath + "/collection/" + row.original.Key}>
              <Button
                title="Edit"
                onClick={() =>
                  navigate(basePath + "/collection/" + row.original.Key)
                }
              >
                <Icon type="edit" />
              </Button>
            </a>
            <span style={{ padding: "5px" }}></span>
            <a href={basePath + "/collection/dashboard/" + row.original.Key}>
              <Button
                title="Dashboard"
                onClick={() =>
                  navigate(
                    basePath + "/collection/dashboard/" + row.original.Key
                  )
                }
              >
                <Icon type="preview" />
              </Button>
            </a>
          </div>

          // option of using text instead of icons
          // <div style={{ textAlign: "center" }}>
          //   <Button
          //     small
          //     title="Edit"
          //     onClick={() => navigate(basePath + "/collection/" + row.original.Key)}
          //   >
          //     Edit
          //   </Button>
          //   <span style={{ padding: "5px" }}></span>
          //   <Button
          //     small
          //     title="Dashboard"
          //     onClick={() =>
          //       navigate(basePath + "/collection/dashboard/" + row.original.Key)
          //     }
          //   >
          //     Dashboard
          //   </Button>
          // </div>
        )
      }
    ]
  };
  const handleRegister = () => {
    navigate(basePath + "/collection/register");
  };

  const showTable = () => {
    return (
      <div>
        {errorMessageIdentity ? (
          <div style={{ paddingBottom: "10px" }}>
            <APIError
              showTitle={true}
              title="Failed to load AD groups"
              error={{
                errors: [
                  "Unable to calculate ownership using AD groups.",
                  "Onwership by individual users is still reflected properly."
                ]
              }}
            />
          </div>
        ) : (
          ""
        )}
        <FilterableSortTable {...props} />
      </div>
    );
  };

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <a href={basePath + "/collection/register"}>
        <Button onClick={() => handleRegister()} style={{ margin: "1em" }}>
          Register Systems Collections
        </Button>
      </a>
      <Collapsible
        title="Dynamic Collection"
        subtitle="View a collection of systems where you or another user have owner permissions"
        align="left"
        isOpen={false}
      >
        <p style={{ padding: "1em", fontSize: "18px" }}>
          <b>View collection of systems where you are an owner:</b>
        </p>
        <a href={basePath + "/collection/dynamic/" + user.email}>
          <Button
            onClick={() =>
              navigate(basePath + "/collection/dynamic/" + user.email)
            }
          >
            My Dynamic Collection
          </Button>
        </a>
        <br />
        <p style={{ padding: "1em", fontSize: "18px" }}>
          <b>View collection for another user's Email address:</b>
        </p>
        <TextInput
          label="Email Address"
          onChange={e => setTerm(e.target.value)}
          onKeyPress={e =>
            e.key === "Enter"
              ? navigate(basePath + "/collection/dynamic/" + term)
              : null
          }
          className="textInput"
          value={term === null ? "" : term}
          border={false}
          name="Search Term"
          full
        />
        <a href={basePath + "/collection/dynamic/" + term}>
          <Button
            onClick={() => navigate(basePath + "/collection/dynamic/" + term)}
          >
            Submit
          </Button>
        </a>
      </Collapsible>
      {!errorMessage ? (
        collections.length === 0 || identity === null ? (
          <Spinner overlay={false} show={true} />
        ) : (
          showTable()
        )
      ) : (
        <APIError showTitle={true} error={errorMessage} />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(CollectionIndex);
