import React, { useState, useEffect } from "react";
import config from "../config.js";
import { Icon, Spinner, Button, Modal } from "@nike/epic-react-ui";
import { SearchableTable } from "../components/table/index.js";
import APIError from "../components/APIError.js";
import { ResourceDetails } from "./SystemResourceDetails.js";
import { AlertEngine } from "./SystemResourceAlerts.js";
import {
  returnAccountResourceQuery,
  returnResourceQuery
} from "./SystemData.js";
import { setIndex } from "./SystemActions";
import { useDispatch } from "react-redux";

export const ResourceItems = props => {
  const key = props.data.key;
  const account = props.account;
  const index = props.index;
  const [showModal, setModal] = useState(false);
  const [resources, setResources] = useState([]);
  const [resource, setResource] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const [fetchResources, updateFetchResources] = useState(false);

  useEffect(() => {
    if (fetchResources) {
      let post_body = JSON.stringify(returnResourceQuery(key, index));
      if (account)
        post_body = JSON.stringify(
          returnAccountResourceQuery(account["accountId"], index)
        );

      fetch(config.apiUrl + "/cloud-resources/customSearch", {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        },
        method: "POST",
        body: post_body
      })
        .then(resources => resources.json())
        .then(resources => {
          if (resources.status === "fail") {
            setErrorMessage(resources);
          } else if (!Array.isArray(resources)) {
            throw Error("Error in resource API");
          } else {
            setErrorMessage(null);
            resources.forEach((item, iter) => {
              const myprops = {
                resource: item,
                ...props
              };
              AlertEngine({ ...myprops });
            });
            setResources(resources);
            var resourceData = {};
            resourceData[index] = resources;
            dispatch(setIndex(resourceData));
            // todo ship resource to redux by index key props.index
          }
        })
        .catch(error => {
          setErrorMessage(error);
          console.log(error);
        });
      //eslint-disable-next-line
    }
  }, [
    key,
    account,
    props.accessToken,
    props.index,
    props.rules,
    fetchResources
  ]);

  const resourceName = data => {
    if (data.ID.includes("task-definition") && data.ID.includes("ecs")) {
      console.log(data);
      return (
        data.ID.split(":")[data.ID.split(":").length - 2] +
        ":" +
        data.ID.split(":")[data.ID.split(":").length - 1]
      );
    } else if (data.ID.includes("alarm") && data.ID.includes("cloudwatch")) {
      return (
        data.ID.split(":")[data.ID.split(":").length - 2] +
        ":" +
        data.ID.split(":")[data.ID.split(":").length - 1]
      );
    } else {
      return data.ID.split(":")[data.ID.split(":").length - 1];
    }
  };

  const tblprops = {
    data: resources.map(data => ({
      Key: data.ID,
      Alert:
        props.mergeAlerts.alerts.filter(alert => alert.resourceid === data.ID)
          .length > 0
          ? "ALERT"
          : "OK",
      // Name: data.ID.split(":")[data.ID.split(":").length - 1],
      Name: resourceName(data),
      Raw: data,
      Component: <ResourceDetails resource={data} {...props} />
    })),
    fields: ["Name"],
    columns: [
      {
        Header: "Alert",
        accessor: "Alert",
        filter: "fuzzyText",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {row.values.Alert === "ALERT" ? (
              <Icon
                className="system-info-status epic-color-error"
                type="warning"
              />
            ) : (
              ""
            )}
          </div>
        )
      },
      {
        Header: "Name",
        accessor: "Name",
        filter: "fuzzyText"
      },
      {
        Header: "Details",
        id: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <Button
              small
              onClick={() => {
                setResource(row.original.Component);
                setModal(true);
              }}
            >
              <Icon type="walkthrough" />
            </Button>
          </div>
        )
      }
    ]
  };

  const makeTable = () => {
    return (
      <div key={index}>
        <Modal
          onClose={() => {
            setModal(false);
            setResource(null);
          }}
          closeButton
          show={showModal}
        >
          <h2>Resource Details</h2>
          <div
            style={{ margin: "30px", overflow: "scroll", maxHeight: "400px" }}
          >
            {resource}
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                setModal(false);
                setResource(null);
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
        <SearchableTable {...tblprops} />
      </div>
    );
  };

  return (
    <div>
      {!fetchResources ? (
        <Button
          small
          onClick={() => {
            updateFetchResources(true);
          }}
        >
          Load Resources
        </Button>
      ) : resources.length > 0 ? (
        makeTable()
      ) : !errorMessage ? (
        <Spinner />
      ) : (
        <APIError showTitle={true} paddingLeft="20px" error={errorMessage} />
      )}
    </div>
  );
};
