import React, { useState, useEffect } from "react";
import { Section, Spinner } from "@nike/epic-react-ui";
import { SortableTable } from "../components/table/index.js";
import APIError from "../components/APIError.js";
import config from "../config.js";

export const Costing = props => {
  const key = props.data.key;
  const [costing, setCosting] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const account = props.account;

  useEffect(() => {
    let url = config.apiUrl + "/ssot/systems/costing/tagguid/" + key;
    // console.log(props)
    if (account)
      url =
        config.apiUrl + "/ssot/systems/costing/account/" + account.accountId;
    // "http://127.0.0.1:8000/costing/account/" + account.accountId;
    return; // disabled until we move to the Entities API
    /*fetch(url, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          // setCosting(response);
          setErrorMessage(response);
          // console.log("error in Costing");
          // console.log(response);
          // throw Error(response.message);
        } else {
          setErrorMessage(null);
          const costingAry = [];
          let report = response.data.asyncPreviewFlexReportResponseStatus.preview.csv.split(
            "\n"
          );
          for (var i = 1; i < report.length - 1; i++) {
            costingAry.push({
              month: report[i].split(",")[0].replace(/"/g, ""),
              cost: report[i].split(",")[1].replace(/"/g, "")
            });
          }
          setCosting(costingAry);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });*/
  }, [key, account, props.accessToken, props.data.key]);

  // formatting function for currency
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  const tblProps = {
    data: costing
      ? costing
          .map(data => ({
            month: data.month,
            cost: formatter.format(parseFloat(data.cost))
          }))
          .sort((a, b) => {
            return (
              Number(a.month.replace("-", "")) -
              Number(b.month.replace("-", ""))
            );
          })
      : [],
    columns: [
      {
        Header: "Month",
        accessor: "month"
      },
      {
        Header: "Aggregated Cost",
        accessor: "cost"
      }
    ]
  };

  const createTable = () => {
    return <SortableTable {...tblProps} />;
  };

  const finopsInfo = () => {
    return (
      <div
        style={{
          width: "70%",
          borderRadius: "1em",
          padding: "20px 1%",
          marginBottom: "30px",
          backgroundColor: "white"
        }}
      >
        <p>
          For the most accurate cloud cost data, please see the&nbsp;
          <a
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://dash.finops.nikecloud.com/"
          >
            FinOps Dashboard&nbsp;
          </a>
          and&nbsp;
          <a
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://developer.niketech.com/docs/projects/Entities%20API?tab=readme"
          >
            Entities API.&nbsp;
          </a>
          Both are superseding CloudHealth which is now deprecated. CloudRed
          will show System cost data from the Entities API in the future.
        </p>
        <br />
        <p>
          More information and support can be found below:&nbsp;
          <ul>
            <li>
              Entities API:&nbsp;
              <a
                className="dashLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://confluence.nike.com/display/CBO/Entities+API"
              >
                https://confluence.nike.com/display/CBO/Entities+API
              </a>
            </li>
            <li>
              FinOps Dashboard:&nbsp;
              <a
                className="dashLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://confluence.nike.com/display/CBO/Cloud+FinOps+Dashboard"
              >
                https://confluence.nike.com/display/CBO/Cloud+FinOps+Dashboard
              </a>
            </li>
            <li>
              Slack:&nbsp;
              <a
                className="dashLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://nikedigital.slack.com/archives/CFXB22A0N"
              >
                #cloud-finops
              </a>
            </li>
          </ul>
        </p>
      </div>
    );
  };

  return (
    <Section id={props.id} title="Costing">
      {finopsInfo()}
      {errorMessage ? (
        <APIError showTitle={true} error={errorMessage} />
      ) : costing ? (
        costing.length <= 2 ? (
          <div className="epic-font-brand" style={{ fontSize: 18 }}>
            No costing data was found for this system.
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>{createTable()}</div>
        )
      ) : (
        // <Spinner />
        ""
      )}
    </Section>
  );
};
