import React, { useState, useEffect } from "react";
import config from "../config.js";
import { navigate } from "raviger";
import APIError from "../components/APIError.js";
import { Section, Icon, Button, Modal, Spinner } from "@nike/epic-react-ui";
import { SortableTable } from "../components/table/index.js";
import { SystemSearch } from "./CollectionSystemsSearch.js";
import deepmerge from "deepmerge";

export const Systems = props => {
  const collectionid = props.data.key;
  const [admin] = useState(props.collection.is_admin);
  let sysData = [];
  const [systems, setSystems] = useState(props.collection.systems);
  const [systemsBreakout, setSystemsBreakout] = useState([]);
  const [modal, setModal] = useState(false);
  const [systemsValue, setSystemsValue] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [errorMessageBreakout, setErrorMessageBreakout] = useState(null);
  const [deleteSpinner, setDeleteSpinner] = useState([]);
  const [updateSpinner, setUpdateSpinner] = useState(false);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    const flattenBreakout = () => {
      let breakoutList = [];
      if (systemsBreakout) {
        for (const [, value] of Object.entries(systemsBreakout)) {
          value.forEach(element => {
            breakoutList.push(element.system_id);
          });
        }
      }
      return breakoutList;
    };

    let breakoutList = flattenBreakout();
    let needsBreakout = [];

    systems.forEach(s => {
      if (!breakoutList.includes(s)) {
        needsBreakout.push(s);
      }
    });

    // fetch("http://127.0.0.1:5000/breakout", {
    if (needsBreakout.length > 0) {
      fetch(config.apiUrl + "/ssot/systems/breakout", {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          systems: needsBreakout
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.status === "fail") {
            setErrorMessageBreakout("System detail not fully loaded.");
          } else if (!response.applications) {
            throw Error("System detail not fully loaded.");
          } else {
            setSystemsBreakout(
              deepmerge(systemsBreakout ? systemsBreakout : {}, response)
            );
            setErrorMessageBreakout(null);
          }
        })
        .catch(error => {
          console.log(error);
          setErrorMessageBreakout(error);
        });
    }
  }, [props.accessToken, systems, systemsBreakout]);

  const updateSystems = sendSystems => {
    // fetch("http://127.0.0.1:8000/" + collectionid, {
    fetch(config.apiUrl + "/ssot/ous/" + collectionid, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        systems: sendSystems
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          throw Error(response.message[0].message);
        } else if (!response.systems) {
          throw Error("Failed to update SC");
        } else {
          setSystemsValue("");
          setDeleteSpinner([]);
          setModal(false);
          setSystems(response.systems);
          setModal(false);
          setUpdateSpinner(false);
          setFormStatus(
            <div className="epic-color-success system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Change saved."}</div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 2000);
        }
      })
      .catch(error => {
        setDeleteSpinner([]);
        setUpdateSpinner(false);
        setFormStatus(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 3000);
      });
  };

  const editSystems = newSystems => {
    setUpdateSpinner(true);
    const sendSystems = [];
    sendSystems.push(...newSystems);
    updateSystems(sendSystems);
  };

  const deleteSystems = key => {
    setDeleteSpinner([key]);
    const sendSystems = [...systems];
    sendSystems.splice(key, 1);
    updateSystems(sendSystems);
  };

  for (let key in systems) {
    let realSystemName = null;
    let systemType = null;

    if (systemsBreakout) {
      for (const [, list] of Object.entries(systemsBreakout)) {
        const found = list.find(h => h.system_id === systems[key]);
        if (found) {
          realSystemName = found.system_name;
          systemType = found.system_type;
        }
      }
    }
    sysData.push({
      key: key,
      value: systems[key],
      name: realSystemName,
      type: systemType
    });
  }

  sysData = sysData.map(data => ({
    key: data.key,
    value: data.value,
    name: errorMessageBreakout ? (
      <div style={{ textAlign: "center" }}>
        <Icon className="noData" type="close-x" />
      </div>
    ) : data.name ? (
      data.name
    ) : (
      <Spinner />
    ),
    type: errorMessageBreakout ? (
      <div style={{ textAlign: "center" }}>
        <Icon className="noData" type="close-x" />
      </div>
    ) : data.type ? (
      data.type
    ) : (
      <Spinner />
    ),
    actions: (
      <div style={{ textAlign: "center" }}>
        <Button
          disabled={deleteSpinner.length === 0 ? (admin ? false : true) : true}
          small
          onClick={() => {
            deleteSystems(data.key);
          }}
        >
          Delete
        </Button>
        <span style={{ padding: "5px" }}></span>
        <Button
          small
          onClick={() => navigate(basePath + "/system/" + data.value)}
        >
          <Icon type="preview" />
        </Button>
      </div>
    )
  }));

  const tblSystemProps = {
    data: sysData,
    columns: [
      {
        Header: "System ID",
        accessor: "value"
      },
      {
        Header: "System Type",
        accessor: "type"
      },
      {
        Header: "System Name",
        accessor: "name"
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {deleteSpinner.includes(row.original.key) ? (
              <Spinner />
            ) : (
              row.original.actions
            )}
          </div>
        )
      }
    ],
    minRows: 0,
    showPagination: false,
    filterable: false
  };

  const addBtn = (
    <Button
      disabled={!admin}
      style={{ marginBottom: "1em" }}
      onClick={() => {
        setModal(true);
      }}
    >
      Edit Systems
    </Button>
  );

  const tbl = <SortableTable {...tblSystemProps} />;

  let errorMessage = modal ? "" : formStatus;

  return (
    <Section id={props.id} title="Systems">
      <div>{errorMessage}</div>
      <Modal
        onClose={() => {
          setModal(false);
        }}
        closeButton
        title=""
        show={modal}
        className="systemSearch"
      >
        <div>{systemsValue}</div>
        <SystemSearch
          createNewSystems={editSystems}
          updateSpinner={updateSpinner}
          systems={systems}
          {...props}
        />
      </Modal>
      {addBtn}
      {errorMessageBreakout ? (
        <div>
          <APIError
            error={errorMessageBreakout}
            title="Error retrieving system details"
            showTitle={true}
          />
          <div style={{ marginTop: "10px" }}></div>
        </div>
      ) : (
        ""
      )}
      {Object.keys(sysData).length > 0 ? (
        tbl
      ) : (
        <div>
          <br />
          No Systems selected for this collection
        </div>
      )}
    </Section>
  );
};
