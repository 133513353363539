import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import config from "../config.js";
import { navigate } from "raviger";
import { typeList } from "./SystemData";
import {
  Button,
  Label,
  Select,
  TextInput,
  Section,
  Modal,
  Spinner,
  Icon,
  Tooltip
} from "@nike/epic-react-ui";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import { SortableTable, StaticTable } from "../components/table/index.js";
import APIError from "../components/APIError.js";
import { useRoutes } from "react-router-dom";

const SystemRegistration = user => {
  const obj = {
    headers: {
      Authorization: "Bearer " + user.accessToken
    }
  };
  const [showOverlay, setShowOverlay] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState({
    value: "application",
    label: "Application"
  });
  const [owner, setOwner] = useState("");
  const [tags] = useState([]);
  const [metadata] = useState([]);
  const [addTagModal, setAddTagModal] = useState(false);
  const [editTagModal, setEditTagModal] = useState(false);
  const [tagKey, setTagKey] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [tagSelected, setTagSelected] = useState(null);
  const [mdModal, setMdModal] = useState(false);
  const [mdKey, setMdKey] = useState("");
  const [mdValue, setMdValue] = useState("");
  const [mdSelected, setMdSelected] = useState(null);
  const [tagRefresh, setTagRefresh] = useState([]);
  const [mdRefresh, setMDRefresh] = useState([]);
  const [standard, setStandard] = useState({
    value: "Latest",
    label: "Latest"
  });
  const [rules, setRules] = useState([]);
  const [createRunning, setCreateRunning] = useState(false);
  const [validating, setValidating] = useState(false);
  const [formStatus, setFormStatus] = useState("");
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  let tagData = [];

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  const getRandomId = () => {
    return Math.random() * 100000000;
  };

  useEffect(() => {
    validateStandard();
  }, [tags.length, type, user.accessToken]);

  const submitForm = e => {
    setCreateRunning(true);
    e.preventDefault();
    if (!validateRequiredFields()) {
      return setErrors(true);
    } else {
      setErrors(false);
    }
    const formTags = {};
    for (var i = 0; i < tags.length; i++) {
      formTags[tags[i].key] = tags[i].value;
    }
    const formMetadata = {};
    for (i = 0; i < metadata.length; i++) {
      formMetadata[metadata[i].key] = metadata[i].value;
    }
    obj.body = JSON.stringify({
      name: name,
      owner: owner,
      type: type.value,
      tags: formTags,
      metadata: formMetadata
    });
    obj.headers["Content-Type"] = "application/json";

    obj.method = "POST";
    // fetch("http://127.0.0.1:8000/", obj)
    fetch(config.apiUrl + "/ssot/systems/", obj)
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setShowOverlay(true);
          setErrors(true);
          setCreateRunning(false);
          setMessage(
            <div>
              <div>
                <h2
                  className="epic-font-brand"
                  style={{
                    textAlign: "left",
                    fontSize: "12",
                    color: "red"
                  }}
                >
                  Creation Failed:
                </h2>
                <APIError
                  error={response}
                  // title="Error retrieving system details"
                  showTitle={false}
                />
              </div>
            </div>
          );
          // throw Error(response);
        } else {
          setCreateRunning(false);
          // navigate("/system/index");
          navigate(basePath + "/system/" + response.key);
        }
      })
      .catch(error => {
        setCreateRunning(false);
        setShowOverlay(true);
        setErrors(true);
        setMessage(
          <div>
            <div>
              <h2
                className="epic-font-brand"
                style={{
                  textAlign: "left",
                  fontSize: "12",
                  color: "red"
                }}
              >
                Creation Failed:
              </h2>
              <p>{error.message}</p>
            </div>
          </div>
        );
        // setMessage(error.message);
        console.log(error);
      });

    return true;
  };

  const validateRequiredFields = () => {
    return type && owner && name;
  };

  const createNewTag = () => {
    // e.preventDefault();
    let edit = false;
    for (var i = 0; i < tags.length; i++) {
      if (tags[i].id === tagSelected) {
        edit = true;
        tags[i].value =
          typeof tagValue !== "object" ? tagValue : tagValue.value;
        tags[i].desc =
          typeof tagValue === "object" && tagValue.label !== tagValue.value
            ? tagValue.label
            : "";
        tags[i].key = tagKey;
        tags[i].actions = (
          <div style={{ textAlign: "center" }}>
            <Button
              small
              onClick={e => {
                e.preventDefault();
                setTagKey(tagKey);
                setTagValue(tagValue);
                setPrefix("");
                setSuffix("");
                setEditTagModal(true);
                setTagSelected(tagSelected);
              }}
            >
              {/* <Icon type="edit" /> */}
              Edit
            </Button>
            <span style={{ padding: "5px" }}></span>
            <Button
              small
              onClick={e => {
                e.preventDefault();
                for (var i = 0; i < tags.length; i++) {
                  if (tags[i].key === tagKey) {
                    tags.splice(i, 1);
                  }
                }
                setTagRefresh([...tags]);
              }}
            >
              {/* <Icon type="delete" /> */}
              Delete
            </Button>
          </div>
        );
      }
    }

    if (!edit) {
      let tagId = getRandomId();
      tags.push({
        id: tagId,
        key: tagKey,
        value: typeof tagValue !== "object" ? tagValue : tagValue.value,
        desc: "",
        actions: (
          <div style={{ textAlign: "center" }}>
            <Button
              small
              onClick={e => {
                e.preventDefault();
                setTagKey(tagKey);
                setTagValue(tagValue);
                setPrefix("");
                setSuffix("");
                setEditTagModal(true);
                setTagSelected(tagId);
              }}
            >
              {/* <Icon type="edit" /> */}
              Edit
            </Button>
            <span style={{ padding: "5px" }}></span>
            <Button
              small
              onClick={e => {
                e.preventDefault();
                for (var i = 0; i < tags.length; i++) {
                  if (tags[i].key === tagKey) {
                    tags.splice(i, 1);
                  }
                }
                setTagRefresh([...tags]);
              }}
            >
              {/* <Icon type="delete" /> */}
              Delete
            </Button>
          </div>
        )
      });
    }
    setTagKey("");
    setTagValue("");
    setPrefix("");
    setSuffix("");
    setTagSelected(null);
    setEditTagModal(false);
    setAddTagModal(false);
  };

  const createNewMd = e => {
    e.preventDefault();

    let edit = false;
    for (var i = 0; i < metadata.length; i++) {
      if (metadata[i].id === mdSelected) {
        edit = true;
        metadata[i].value = mdValue;
        metadata[i].key = mdKey;
        metadata[i].actions = (
          <div style={{ textAlign: "center" }}>
            <Button
              small
              onClick={() => {
                e.preventDefault();
                setMdKey(mdKey);
                setMdValue(mdValue);
                setMdModal(true);
                setMdSelected(mdSelected);
              }}
            >
              {/* <Icon type="edit" /> */}
              Edit
            </Button>
            <span style={{ padding: "5px" }}></span>
            <Button
              small
              onClick={e => {
                e.preventDefault();
                for (var i = 0; i < metadata.length; i++) {
                  if (metadata[i].key === mdKey) {
                    metadata.splice(i, 1);
                  }
                }
                setMDRefresh([...metadata]);
              }}
            >
              {/* <Icon type="delete" /> */}
              Delete
            </Button>
          </div>
        );
      }
    }

    if (!edit) {
      let mdId = getRandomId();
      metadata.push({
        id: mdId,
        key: mdKey,
        value: mdValue,
        actions: (
          <div style={{ textAlign: "center" }}>
            <Button
              small
              onClick={() => {
                e.preventDefault();
                setMdKey(mdKey);
                setMdValue(mdValue);
                setMdModal(true);
                setMdSelected(mdId);
              }}
            >
              {/* <Icon type="edit" /> */}
              Edit
            </Button>
            <span style={{ padding: "5px" }}></span>
            <Button
              small
              onClick={e => {
                e.preventDefault();
                for (var i = 0; i < metadata.length; i++) {
                  if (metadata[i].key === mdKey) {
                    metadata.splice(i, 1);
                  }
                }
                setMDRefresh([...metadata]);
              }}
            >
              {/* <Icon type="delete" /> */}
              Delete
            </Button>
          </div>
        )
      });
    }
    setMdKey("");
    setMdValue("");
    setMdSelected(null);
    setMdModal(false);
  };

  for (var key in tags) {
    tagData.push({
      key: key,
      value: tags[key]
    });
  }

  var tblTagProps = {
    columns: [
      {
        Header: "Tag Key",
        accessor: "key"
      },
      {
        Header: "Tag Value",
        accessor: "value"
      },
      {
        Header: "Actions",
        accessor: "actions"
      }
    ]
  };

  var hasDesc = false;
  for (var entry in tagData) {
    if (tagData[entry].desc) {
      hasDesc = true;
      break;
    }
  }

  if (hasDesc) {
    tblTagProps = {
      columns: [
        {
          Header: "Tag Key",
          accessor: "key"
        },
        {
          Header: "Tag Value",
          accessor: "value"
        },
        {
          Header: "Value Description",
          accessor: "desc"
        },
        {
          Header: "Actions",
          accessor: "actions"
        }
      ]
    };
  }

  const tblMdProps = {
    columns: [
      {
        Header: "Key",
        accessor: "key"
      },
      {
        Header: "Value",
        accessor: "value"
      },
      {
        Header: "",
        accessor: "actions"
      }
    ],
    minRows: 0,
    showPagination: false,
    filterable: false
  };

  const formatRules = rules => {
    const formattedRules = [];
    for (var key in rules) {
      formattedRules[key] = rules[key];
    }
    setRules(formattedRules);
  };

  const validateStandard = () => {
    setValidating(true);
    if (standard === "") return;
    let tagsFlat = {};
    tags.forEach(a => {
      tagsFlat[a.key] = a.value;
    });
    fetch(config.apiUrl + "/tagging/guided_validation", {
      // fetch("http://127.0.0.1:8002/guided_validation", {
      headers: {
        Authorization: "Bearer " + user.accessToken,
        "Cache-Control": "no-cache"
      },
      method: "POST",
      body: JSON.stringify({
        standard: standard.value,
        tags: tagsFlat
      })
    })
      .then(response => response.json())
      .then(response => {
        if (!response.status) {
          console.log(response);
          throw Error("Failure in validate API");
        } else if (response.status === "fail") {
          throw Error(response.message);
        } else if (response.status === "incomplete compliance") {
          var compliance_warnings = [];
          formatRules(response.rule_data);
          for (var entry of response.reasons) {
            if (
              entry.includes("environment") &&
              type.value === "application" &&
              entry.includes("required")
            ) {
              compliance_warnings.push({
                warning: entry,
                recommendation:
                  "If application will be deployed to more than one aws account or deployed by the BMX Pipelines then define the tag in the pipeline configuration / infrastructure as code"
              });
            } else if (
              entry.includes("environment") &&
              type.value === "application" &&
              entry.includes("Prefix")
            ) {
              compliance_warnings.push({
                warning: entry,
                recommendation:
                  "Update value in system tags. If this tag is being managed in BMX Pipelines / infrastructure as code then this key should be deleted"
              });
            } else if (entry.includes("required")) {
              compliance_warnings.push({
                warning: entry,
                recommendation: "Add key / value to system tags"
              });
            } else {
              compliance_warnings.push({
                warning: entry,
                recommendation:
                  "Update value in system tags to be compliant with the standard"
              });
            }
          }
          var compliance_data = {
            data: compliance_warnings,
            columns: [
              {
                Header: "Warning",
                accessor: "warning"
              },
              {
                Header: "Recommendation",
                accessor: "recommendation"
              }
            ]
          };

          setMessage(
            <div>
              <h2
                className="epic-font-brand"
                style={{
                  textAlign: "left",
                  fontSize: "14",
                  color: "#ffbf00"
                }}
              >
                Tag Configuration Validation Warnings:
              </h2>
              <StaticTable {...compliance_data} />
            </div>
          );
          setValidating(false);
        } else {
          /*setFormStatus(
            <div className="system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Validation Successful!"}</div>
            </div>
          );*/
          formatRules(response.rule_data);
          setMessage(
            <div>
              <h2
                className="epic-font-brand"
                style={{
                  textAlign: "left",
                  fontSize: "14",
                  color: "green"
                }}
              >
                Tag configuration is compliant
              </h2>
              No validation issues were found for the tags that are configured
              for the system
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 6000);
          setValidating(false);
        }
      })
      .catch(error => {
        setMessage(
          <div>
            Failure in validation:
            <ul>
              <li key={error.message}>
                <pre>{error.message}</pre>
              </li>
            </ul>
          </div>
        );
        console.log(error);
        setShowOverlay(true);
        setValidating(false);
      });
  };

  useEffect(() => {
    var updateTag = "";
    if (prefix.value && suffix) {
      updateTag = prefix.value + "-" + suffix;
    } else if (prefix.value && !suffix) {
      updateTag = prefix;
    } else if (!prefix && suffix) {
      updateTag = suffix;
    }
    setTagValue(updateTag);
  }, [prefix, suffix]);

  const getTagRuleKeys = rules => {
    const keyList = [];
    for (var rule in rules) {
      keyList.push({ value: rule, label: rule });
    }
    return (
      <div>
        <Tooltip
          text="Choose from a list of tags that have rules associated with them"
          inverse={true}
        >
          Managed Tag Key&nbsp; <Icon type="walkthrough" size="small"></Icon>
        </Tooltip>
        <Select
          label=""
          className="textInput"
          name="managedKey"
          options={keyList}
          value={{ value: tagKey, label: tagKey }}
          onChange={selected => setTagKey(selected.value)}
        />
        <br />
        <Tooltip
          text="Define a custom tag key. See tagging rules for key name restrictions"
          inverse={true}
        >
          Custom Tag Key&nbsp; <Icon type="walkthrough" size="small"></Icon>
        </Tooltip>
        <br />
        <TextInput
          label=""
          className="textInput"
          hasErrors={tagData.find(tag => tag.key === tagKey) ? true : false}
          errorMessage="Tag Key already exists"
          name="tagkey"
          value={tagKey}
          onChange={e => setTagKey(e.target.value)}
        />
      </div>
    );
  };

  const getTagRule = tag_key => {
    let rule = rules[tag_key];

    if ("option_list" in rule) {
      const valueList = [];
      rule.option_list.forEach((item, index) => {
        valueList.push({
          value: item.id,
          label: item.value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports a set list friendly names and their associated values.
            Selecting an option will set the value to the associated value"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Value"
            className="textInput"
            name="tagvalue"
            options={valueList}
            value={tagValue}
            onChange={selected => setTagValue(selected)}
          />
        </div>
      );
    }

    if ("value_list" in rule) {
      const valueList = [];
      rule.value_list.forEach((value, index) => {
        valueList.push({
          value: value,
          label: value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports a list of managed values"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Value"
            className="textInput"
            name="tagvalue"
            options={valueList}
            value={tagValue}
            onChange={selected => setTagValue(selected)}
          />
        </div>
      );
    }

    if ("prefix_list" in rule) {
      const valueList = [];
      rule.prefix_list.forEach((value, index) => {
        valueList.push({
          value: value,
          label: value
        });
      });

      return (
        <div>
          <Tooltip
            text="This tag key supports prefixes from a list of managed values and an optional suffix, the result of setting both will be hypenated"
            inverse={true}
          >
            Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
          </Tooltip>
          <Select
            label="Prefix"
            className="textInput"
            name="prefix"
            options={valueList}
            value={prefix}
            onChange={selected => setPrefix(selected)}
          />
          <TextInput
            label="Suffix (Optional)"
            className="textInput"
            full
            name="suffix"
            value={suffix}
            onChange={e => {
              let suffix = e.target.value;
              if ("case_rule" in rule) {
                if (rule.case_rule === "lower") suffix = suffix.toLowerCase();
                else if (rule.case_rule === "upper")
                  suffix = suffix.toUpperCase();
              }

              if ("illegal_strings" in rule) {
                rule.illegal_strings.forEach((item, index) => {
                  suffix = suffix.replace(item, "");
                });
              }

              setSuffix(suffix);
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <Tooltip
          text="This tag key supports free form text but may have case or other restrictions"
          inverse={true}
        >
          Value&nbsp; <Icon type="walkthrough" size="small"></Icon>
        </Tooltip>
        <TextInput
          label="Value"
          className="textInput"
          full
          name="tagvalue"
          value={tagValue}
          onChange={e => {
            let tag_value = e.target.value;
            if ("case_rule" in rule) {
              if (rule.case_rule === "lower")
                tag_value = tag_value.toLowerCase();
              else if (rule.case_rule === "upper")
                tag_value = tag_value.toUpperCase();
            }

            if ("illegal_strings" in rule) {
              rule.illegal_strings.forEach((item, index) => {
                tag_value = tag_value.replace(item, "");
              });
            }

            setTagValue(tag_value);
          }}
        />
      </div>
    );
  };

  const validateSpinner = validating ? (
    <Spinner
      className="spinnerReg"
      style={{ float: "right", margin: "10px", marginLeft: "10px" }}
    />
  ) : (
    ""
  );
  // const validateSpinner = !validating ? <div className="spinnerReg" /> : "";

  console.log(tags);

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
          background-color: #f7f7f7;
          border-radius: 1em;
          margin-bottom: 30px;
          padding: 20px 1%;
          position: relative;
        }
        .epic__sectionContent__947 {
            margin-top: 0px;
        }
        .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }`}
      </style>
      {/* <Modal onClose={() => setShowOverlay(false)} show={showOverlay}>
        <p
          style={{
            backgroundColor: "white",
            textAlign: "center",
            color: "red"
          }}
        >
          {message}
        </p>
      </Modal> */}
      <form noValidate onSubmit={submitForm}>
        <Section title="Details">
          <Label label="What type of system are you registering?">
            <Select
              required
              options={typeList}
              value={type}
              onChange={selected => setType(selected)}
              hasErrors={errors && !type}
            />
          </Label>
          <div style={{ marginBottom: "30px" }}></div>
          <TextInput
            required
            label="What do you call this system?"
            onChange={e => setName(e.target.value)}
            onKeyPress={e =>
              e.key === "Enter" ? setName(e.target.value) : null
            }
            className="textInput"
            value={name}
            hasErrors={errors && !name}
            full
          />
          <div style={{ marginBottom: "30px" }}></div>
          <TextInput
            required
            label="Who is the owner of this system? (Active Directory email or group name)"
            onChange={e => setOwner(e.target.value)}
            onKeyPress={e => (e.key === "Enter" ? setOwner(e) : null)}
            className="textInput"
            value={owner}
            hasErrors={errors && !owner}
            full
          />
        </Section>
        <Section title="Tags">
          <Modal onClose={() => setShowOverlay(false)} show={showOverlay}>
            <div
              style={{
                backgroundColor: "white",
                textAlign: "left"
              }}
            >
              {message}
            </div>
          </Modal>
          <div>{formStatus}</div>
          <div className="epic-font-base-md">Standards</div>
          <p style={{ padding: "1em", fontStyle: "italic" }}>
            CCoE requires that all resources in the cloud have a set of standard
            tags. For more information, please visit this link: &nbsp;
            <a
              style={{ fontSize: "18px" }}
              className="dashLink"
              target="_blank"
              rel="noopener noreferrer"
              href="https://confluence.nike.com/display/IECG/Nike+Cloud+Tagging+Standard"
            >
              Nike Cloud Tagging Standard documentation
            </a>
            <br />
            To view the rules for the latest Nike Cloud Tagging Standard visit:
            &nbsp;
            <a
              style={{ fontSize: "18px" }}
              className="dashLink"
              target="_blank"
              rel="noopener noreferrer"
              href={basePath + "/tagging/explorer"}
            >
              Tagging Rules
            </a>
          </p>
          <div style={{ margin: "30px" }}></div>
          <Modal
            onClose={() => setAddTagModal(false)}
            closeButton
            title=""
            show={addTagModal}
          >
            <div style={{ marginBottom: "30px" }}>
              {standard ? (
                getTagRuleKeys(rules)
              ) : (
                <TextInput
                  label="Key"
                  className="textInput"
                  hasErrors={
                    tagData.find(tag => tag.key === tagKey) ? true : false
                  }
                  errorMessage="Tag Key already exists"
                  name="tagkey"
                  full
                  value={tagKey}
                  onChange={e => setTagKey(e.target.value)}
                  onKeyPress={e =>
                    e.key === "Enter" ? setTagKey(e.target.value) : null
                  }
                />
              )}
              <div style={{ marginBottom: "30px" }}></div>
              {tagKey in rules ? (
                getTagRule(tagKey)
              ) : (
                <TextInput
                  label="Value"
                  className="textInput"
                  full
                  value={tagValue}
                  onChange={e => setTagValue(e.target.value)}
                  onKeyPress={e =>
                    e.key === "Enter" ? setTagValue(e.target.value) : null
                  }
                />
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                disabled={tags.find(tag => tag.key === tagKey) ? true : false}
                onClick={() => createNewTag()}
              >
                Submit
              </Button>
            </div>
          </Modal>
          <Modal
            onClose={() => setEditTagModal(false)}
            closeButton
            title=""
            show={editTagModal}
          >
            <div style={{ marginBottom: "30px" }}>
              <TextInput
                readOnly={true}
                label="Key"
                className="readOnlyText"
                full
                value={tagKey}
                onChange={e => setTagKey(e.target.value)}
                onKeyPress={e => (e.key === "Enter" ? setTagKey(e) : null)}
              />
              <div style={{ marginBottom: "30px" }}></div>
              {tagKey in rules ? (
                getTagRule(tagKey)
              ) : (
                <TextInput
                  label="Value"
                  className="textInput"
                  full
                  value={tagValue}
                  onChange={e => setTagValue(e.target.value)}
                  onKeyPress={e => (e.key === "Enter" ? setTagValue(e) : null)}
                />
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createNewTag();
                  validateStandard();
                }}
              >
                Edit Tag
              </Button>
            </div>
          </Modal>
          <Button
            style={{ marginBottom: "1em" }}
            onClick={e => {
              e.preventDefault();
              setTagKey("");
              setTagValue("");
              setPrefix("");
              setSuffix("");
              setTagSelected(null);
              setAddTagModal(true);
            }}
          >
            Add Tag
          </Button>
          {message ? (
            <div style={{ margin: "20px" }}>{message}</div>
          ) : (
            <div></div>
          )}
          {tags.length > 0 ? (
            <SortableTable data={tags} refresh={tagRefresh} {...tblTagProps} />
          ) : (
            "No Tags have been defined yet"
          )}
        </Section>

        <Section title="Metadata">
          <Modal
            onClose={() => setMdModal(false)}
            closeButton
            title=""
            show={mdModal}
          >
            <div style={{ marginBottom: "30px" }}>
              <TextInput
                label="Key"
                className="textInput"
                full
                value={mdKey}
                onChange={e => setMdKey(e.target.value)}
                onKeyPress={e =>
                  e.key === "Enter" ? setMdKey(e.target.value) : null
                }
              />
              <div style={{ marginBottom: "30px" }}></div>
              <TextInput
                label="Value"
                className="textInput"
                full
                value={mdValue}
                onChange={e => setMdValue(e.target.value)}
                onKeyPress={e =>
                  e.key === "Enter" ? setMdValue(e.target.value) : null
                }
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={e => createNewMd(e)}>Add Metadata</Button>
            </div>
          </Modal>
          <Button
            style={{ marginBottom: "1em" }}
            onClick={e => {
              e.preventDefault();
              setMdModal(true);
            }}
          >
            Add Metadata
          </Button>
          <br />
          {metadata.length > 0 ? (
            <SortableTable
              refresh={mdRefresh}
              data={metadata}
              {...tblMdProps}
            />
          ) : (
            "No Metadata has been defined yet"
          )}
        </Section>

        <div
          style={{
            marginBottom: "5em",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button onClick={submitForm}>Submit</Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(SystemRegistration);
