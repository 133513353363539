import store from './store.js'
import { makeToken } from './token.js'
import { TOKEN_KEY, SILENT_LOGIN_IFRAME_ID, RECOVERY_KEY } from './constants.js'
import { selectIsSilentAuthRunning, selectLoginError } from './reducer.js'
import { login, getTokenFromRedirect, getLoginUrl, watchForTokenExpiration } from './authorizer.js'
import { userLoginSuccess, setLoginError, setSilentAuthCheck } from './actions.js'

const hasPreviousLogin = () => !!store.getItem(TOKEN_KEY)

const addListener = (eventTarget, name, fn) => eventTarget.addEventListener(name, fn)

const removeListener = (eventTarget, name, fn) => eventTarget.removeEventListener(name, fn)

const loadFrame = src => {
  let iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.src = src

  return document.body.appendChild(iframe)
}

const createSilentLoginIframe = config => async getState => {
  store.setItem(RECOVERY_KEY, JSON.stringify(getState()))

  let loginUrl = await getLoginUrl({
    config,
    skipPrompt: true,
    state: RECOVERY_KEY
  })
  return loadFrame(loginUrl)
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (error) {
    return true
  }
}

const tryJsonParse = value => {
  let parsed

  try {
    parsed = JSON.parse(value)
  } catch (error) {
    return undefined
  }

  return parsed
}

export const iframeSilentLogin = async (config = {}) => {
  // Note (DJF): purposefully not documenting `originOverride` so as not to cause confusion
  // Fixes infinite loop issue in TestCafe
  const origin = config.originOverride || window.origin
  try {
    const token = await getTokenFromRedirect(config)
    let message = JSON.stringify({
      type: 'SILENT_LOGIN',
      token
    })
    window.parent.postMessage(message, origin)
  } catch (error) {
    let message = JSON.stringify({
      type: 'SILENT_LOGIN',
      error: error.message
    })
    window.parent.postMessage(message, origin)
  }
}

const cleanupSilentLoginIframe = () => {
  let doc = window.document
  let iframe = doc.getElementById(SILENT_LOGIN_IFRAME_ID)

  if (iframe) {
    doc.body.removeChild(iframe)
  }
}

const silentLoginListener = ({ originOverride } = {}) => {
  const origin = originOverride || window.origin
  let result = {}
  let hasCanceled = false
  let timeout = 5000

  result.promise = new Promise((resolve, reject) => {
    result.handler = event => {
      if (hasCanceled) {
        return
      }

      if (event.origin !== origin) {
        return
      }

      let data = tryJsonParse(event.data)

      if (!data || data.type !== 'SILENT_LOGIN' || !data.token) {
        return
      }

      removeListener(window, 'message', result.handler)

      cleanupSilentLoginIframe()

      if (data.error) {
        reject(data.error)
      } else {
        store.removeItem(RECOVERY_KEY)
        resolve(makeToken(data.token))
      }
    }

    setTimeout(() => {
      hasCanceled = true

      reject(new Error('Silent Login Timed out'))
    }, timeout)
  })

  return result
}

export const trySilentLogin = config => async getState => {
  let shouldAttempt = hasPreviousLogin() || !config.keepToken

  if (!shouldAttempt) {
    return Promise.resolve(null)
  }

  let silentLogin = silentLoginListener(config)

  addListener(window, 'message', silentLogin.handler)

  await createSilentLoginIframe(config)(getState)

  return silentLogin.promise
}

export const refreshSilentLogin = (config, auth) => async (dispatch, getState) => {
  let state = getState()
  if (selectIsSilentAuthRunning(state) || selectLoginError(state)) {
    return Promise.resolve()
  }

  dispatch(setSilentAuthCheck(true))

  return trySilentLogin(config)(getState)
    .then(async token => {
      if (!token) {
        return await login(config)(getState)
      }

      dispatch(userLoginSuccess(token))

      auth.emit('refreshSuccess', dispatch, getState)

      watchForTokenExpiration(config, token, auth)(dispatch, getState)
    })
    .catch(error => {
      dispatch(setLoginError(error))

      auth.emit('refreshError', dispatch, getState)
    })
    .finally(() => dispatch(setSilentAuthCheck(false)))
}
