import React, { useState, useEffect } from "react";
import { Section, Spinner, Button, Icon, Modal } from "@nike/epic-react-ui";
import { SortableTable } from "../components/table/index.js";
import APIError from "../components/APIError.js";
import config from "../config.js";

export const Alerts = props => {
  // const alerts = props.maintAlerts;
  const [alerts, setAlerts] = useState(null);
  const [showModal, setModal] = useState(false);
  const [alertDesc, setAlertDesc] = useState("Default description");
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetch(
      config.apiUrl +
        "/alerts/?status=open_upcoming&accountid=" +
        props.account.accountId,
      {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Cache-Control": "no-cache"
        }
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setTimeout(() => {
            console.log(response);
            console.log(alerts);
            setErrorMessage(response);
          }, 5000);
        } else {
          setAlerts(response);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
    //eslint-disable-next-line
  }, [props.accessToken]);

  const tblProps = {
    data: alerts
      ? alerts.map(data => ({
          id: data.id,
          service: data.service,
          resourceid: data.resourceid,
          status: data.status,
          region: data.region,
          starttime: data.start_time,
          endtime: data.end_time,
          description: data.description
        }))
      : [],
    columns: [
      {
        Header: "Service",
        accessor: "service"
      },
      {
        Header: "Resource",
        accessor: "resourceid"
      },
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "Region",
        accessor: "region"
      },
      {
        Header: "Start Time",
        accessor: "starttime"
      },
      {
        Header: "End Time",
        accessor: "endtime"
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <Button
              small
              onClick={() =>
                //alert(row.values.description)}
                {
                  setAlertDesc(row.values.description);
                  setModal(true);
                }
              }
            >
              <Icon type="walkthrough" />
            </Button>
          </div>
        )
      }
    ]
  };

  const makeTable = () => {
    return (
      <div>
        <Modal onClose={() => setModal(false)} closeButton show={showModal}>
          <h2>Alert Description</h2>
          <div
            style={{ margin: "30px", overflow: "scroll", maxHeight: "400px" }}
          >
            <p>{alertDesc}</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button onClick={() => setModal(false)}>Close</Button>
          </div>
        </Modal>
        <SortableTable {...tblProps} />
      </div>
    );
  };

  return (
    <Section id={props.id} title="Alerts">
      {!alerts ? (
        !errorMessage ? (
          <Spinner />
        ) : (
          <APIError showTitle={true} error={errorMessage} />
        )
      ) : alerts.length > 0 ? (
        makeTable()
      ) : (
        <div>There are no alerts to display at this time</div>
      )}
    </Section>
  );
};
