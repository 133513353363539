import React, { useCallback, useState, useEffect } from "react";
import config from "../config.js";
import { connect } from "react-redux";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import {
  Button,
  Label,
  Section,
  Spinner,
  TextInput
} from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import { TaggingCheckTextarea } from "./TaggingCheckTextarea.js";

const TaggingCheck = props => {
  const [message, setMessage] = useState("");
  const [rules, setRules] = useState({});
  const [tags, setTags] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [standard] = useState({
    value: "Latest",
    label: "Latest"
  });

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  const fetchRules = useCallback(async () => {
    if (Object.keys(rules).length === 0) {
      setUpdateSpinner(true);
      let response = await fetch(
        config.apiUrl + "/tagging/rules/" + standard.value,
        {
          headers: {
            Authorization: "Bearer " + props.accessToken,
            "Cache-Control": "no-cache"
          }
        }
      );
      let result = await response.json();
      if (!response.ok) {
        setMessage(
          <div>
            <APIError
              title="Failure in fetching rules"
              showTitle={true}
              error={result}
            />
          </div>
        );
        setUpdateSpinner(false);
        return false;
      } else {
        let formattedRules = formatRules(result.rules.key_rules);
        setUpdateSpinner(false);
        return formattedRules;
      }
    } else {
      return rules;
    }
  }, [rules, standard.value, props.accessToken]);

  useEffect(() => {
    fetchRules();
  }, [fetchRules, props.accessToken]);

  const formatRules = rules => {
    const formattedRules = {};
    rules.forEach(rule => {
      formattedRules[rule.tag_key] = rule;
    });
    setRules(formattedRules);
  };

  const filterErrors = errors => {
    let tagErrors = {};
    for (const error of errors) {
      let [tagKey, ...tagValue] = error.split(":");
      if (tagErrors.hasOwnProperty(tagKey)) {
        tagErrors[tagKey] = [...tagErrors[tagKey], tagValue.join(" ")];
      } else {
        tagErrors[tagKey] = [tagValue.join(" ")];
      }
    }
    setErrorMessage(tagErrors);
  };

  const applyStandard = () => {
    let filterTags = {};
    Object.entries(tags).forEach(([key, value]) => {
      if (value) {
        filterTags[key] = value;
      }
    });
    setUpdateSpinner(true);
    if (standard === "") return;
    if (!rules) {
      fetchRules();
    }
    fetch(config.apiUrl + "/tagging/guided_validation", {
      // fetch("http://127.0.0.1:8002/guided_validation", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache"
      },
      method: "POST",
      body: JSON.stringify({
        tags: filterTags,
        standard: "latest"
      })
    })
      .then(response => response.json())
      .then(response => {
        if (!response.status) {
          throw Error("Failure in validate API");
        } else if (response.status === "fail") {
          throw Error(response.message);
        } else if (response.status === "incomplete compliance") {
          filterErrors(response.reasons);
          setUpdateSpinner(false);
          setTags(filterTags);
        } else {
          setUpdateSpinner(false);
          setErrorMessage({});
          setMessage(
            <div>
              <h2
                className="epic-font-brand"
                style={{
                  textAlign: "left",
                  fontSize: "14",
                  color: "green"
                }}
              >
                Tag configuration is compliant
              </h2>
            </div>
          );
          setTags(filterTags);
        }
      })
      .catch(error => {
        console.log(error);
        setMessage(
          <div>
            Failure in validation:
            <ul>
              <li key={error.message}>
                <pre>{error.message}</pre>
              </li>
            </ul>
          </div>
        );
        setUpdateSpinner(false);
      });
  };

  const inputTagList = () => {
    const requiredTags = [];
    const optionalTags = [];
    Object.keys(rules).forEach(key => {
      rules[key]["required"] ? requiredTags.push(key) : optionalTags.push(key);
    });
    return (
      <div>
        {requiredTags.map(tag => inputTag(tag))}
        {optionalTags.map(tag => inputTag(tag))}
      </div>
    );
  };

  const handleChange = e => {
    const tag = { [e.target.id]: e.target.value };
    setTags({ ...tags, ...tag });
  };

  const handleClick = e => {
    setMessage("");
    if (Object.keys(tags).length !== 0) {
      applyStandard();
    }
  };

  const inputTag = tag => {
    let tagDesc = rules[tag]["required"] ? tag : tag + " (optional)";
    return (
      <div key={tag}>
        <Label label={tagDesc}>
          <TextInput
            id={tag}
            placeholder="value"
            onChange={e => handleChange(e)}
          />
          <div style={{ marginBottom: "1em", lineHeight: "150%" }}>
            <ul>
              {errorMessage.hasOwnProperty(tag)
                ? errorMessage[tag].map((error, index) => (
                    <li key={index}>
                      <pre>{error}</pre>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        </Label>
      </div>
    );
  };

  const tagStandardsDoc = () => {
    return (
      <div>
        <p style={{ padding: "1em", fontStyle: "italic" }}>
          CCoE requires that all resources in the cloud have a set of standard
          tags. <br /> For more information, please visit this link: &nbsp;
          <a
            style={{ fontSize: "18px" }}
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://confluence.nike.com/display/IECG/Nike+Cloud+Tagging+Standard"
          >
            Nike Cloud Tagging Standard documentation
          </a>
          <br />
          To view the rules for the latest Nike Cloud Tagging Standard visit:
          &nbsp;
          <a
            style={{ fontSize: "18px" }}
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href={basePath + "/tagging/explorer"}
          >
            Tagging Rules
          </a>
        </p>
      </div>
    );
  };

  return (
    <div style={{ margin: "80px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
        .epic__sectionContent__947 {
          margin-top: 0px;
        }
        .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__container__d0a.epic__disabled__f8e {
            opacity: 1;
        }`}
      </style>
      {tagStandardsDoc()}
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", margin: "1em", float: "left" }}>
          <Section title="Tag Check">
            {inputTagList()}
            <Button onClick={e => handleClick(e)}>Validate</Button>
            <div style={{ margin: "1em 1em 1em" }}>
              {updateSpinner ? <Spinner /> : ""}
              {message ? message : ""}
            </div>
          </Section>
        </div>
        <TaggingCheckTextarea {...props} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(TaggingCheck);
