import merge from "deepmerge";

const base = {
  appLogo: "/images/logo-color-horizontal.png",
  appSlackUrl: "https://nikedigital.slack.com/archives/C8DRYHN9X",
  appFeedbackUrl:
    "https://niketechpmo.sjc1.qualtrics.com/jfe/form/SV_6g1Z7nChFlR3d2K",
  appName: "CloudRed",
  appFullName: "CloudRed",
  oAuth: {
    client_id: "nike.niketech.cloudred",
    response_type: "id_token token",
    scope:
      "profile openid email cloudred.resources.read cloudtools.accounts.read cloudtools.accounts.update cloudred.taghistory.read cloudtools.ssot.systems.create"
  }
};

const dev = merge(base, {
  apiUrl: "https://cloudred-staging.nike.com/api",
  oAuth: {
    redirect_uri:
      window.location.href.indexOf("platforms.nike.com/cloudred") !== -1
        ? window.location.origin + "/cloudred"
        : window.location.origin,
    authorization:
      "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize"
  }
});

const prod = merge(base, {
  apiUrl: "https://cloudred.nike.com/api",
  oAuth: {
    redirect_uri:
      window.location.href.indexOf("platforms.nike.com/cloudred") !== -1
        ? window.location.origin + "/cloudred"
        : window.location.origin,
    authorization:
      "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize"
  }
});

const config =
  window.location.origin.includes(
    "//cloudred-gui.cloudred-prod.nikecloud.com"
  ) ||
  window.location.href.includes("//console-stage.platforms.nike.com") ||
  window.location.href.includes("//console.platforms.nike.com") ||
  window.location.href.includes("//cloudred.nike.com")
    ? prod
    : dev;

export default config;
