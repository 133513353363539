import React, { useState, useEffect, useRef } from "react";
import config from "../config.js";
import {
  TextInput,
  Section,
  Icon,
  Spinner,
  Modal,
  Button
} from "@nike/epic-react-ui";
import SearchResults from "./SearchResults.js";
import { connect } from "react-redux";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import APIError from "../components/APIError.js";
import { navigate } from "raviger";

export const Search = props => {
  const [term, setTerm] = useState(props.term.term);
  const transientTerm = useRef(props.term.term);
  const [formStatus, setFormStatus] = useState(null);
  const [results, setResults] = useState(null);
  const [hits, setHits] = useState(0);
  const [returned, setReturned] = useState(0);
  const [message, setMessage] = useState(null);
  const timerMessage = useRef(null);
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [size, setSize] = useState(10);
  const [tries, setTries] = useState(0);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  const getResults = () => {
    setResults(null);
    setUpdateSpinner(true);
    setFormStatus("");
    setFormStatus(
      <div className="spinnerParent">
        <div className="spinnerChild">
          <Spinner medium={true} />
        </div>
      </div>
    );
    clearTimeout(timerMessage.current);

    if (term.includes('"')) {
      setTerm(term.replaceAll('"', ""));
    }

    if (term.includes("%22")) {
      setTerm(term.replaceAll("%22", ""));
    }

    if (term.includes("%20")) {
      setTerm(term.replaceAll("%20", " "));
    }

    fetch(config.apiUrl + "/resources/search/?term=" + term, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setUpdateSpinner(false);
          setFormStatus("");
          setTerm(transientTerm.current);
          setShowOverlay(true);
          setMessage(
            <div>
              <div>
                <h2
                  className="epic-font-brand"
                  style={{
                    textAlign: "left",
                    fontSize: "12",
                    color: "red"
                  }}
                >
                  Search Failed:
                </h2>
                <APIError error={response} showTitle={false} />
              </div>
            </div>
          );
        } else if (!response) {
          throw Error("Failure in Resources API");
        } else {
          setResults(response.results);
          setHits(response.total);
          setReturned(response.returned);
          if (response.total < size) {
            setSize(response.total);
          } else {
            setSize(10);
          }
          setUpdateSpinner(false);
          transientTerm.current = term;
          setFormStatus(
            <div className="epic-color-success system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Search Success."}</div>
            </div>
          );
          timerMessage.current = setTimeout(() => {
            setFormStatus("");
          }, 3000);
          return () => clearTimeout(timerMessage.current);
        }
      })
      .catch(error => {
        setUpdateSpinner(false);
        setTerm(transientTerm.current);
        setFormStatus(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 6000);
        return () => clearTimeout(timerMessage.current);
      });
  };

  const handleBlur = e => {
    if (!transientTerm) {
      // console.log(term);
      getResults();
    } else if (transientTerm.current !== term && !updateSpinner && term) {
      // console.log(term);
      // console.log(transientTerm.current);
      setSize(10);
      getResults();
      navigate(basePath + "/search/" + term);
    }
  };

  // handle for initial intial / reload of search page to get results if a term is already defined
  if (typeof term !== "undefined" && !results && tries === 0) {
    setTries(tries + 1);
    getResults();
  } else if (typeof term === "undefined" && tries === 0) {
    setTries(tries + 1);
  }

  useEffect(() => {
    if (hits < size && hits > 0) {
      //console.log(hits);
      setSize(hits);
    }
  }, [hits, size]);

  return (
    <div style={{ margin: "60px 1em 1em", alignContent: "center" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
        }
        .epic__sectionContent__947 {
          margin-top: 0px
        }
        .epic__NextGenSelect__dea .NextGenSelect__control .NextGenSelect__indicators {
          color: #e5e5e5;
          padding: 5px;
        }`}
      </style>
      <Section id="1" title="Search">
        <Modal onClose={() => setShowOverlay(false)} show={showOverlay}>
          <div
            style={{
              backgroundColor: "white",
              textAlign: "left"
            }}
          >
            {message}
          </div>
        </Modal>
        <div>{formStatus}</div>
        <TextInput
          label="Search Term"
          onChange={e => setTerm(e.target.value)}
          onKeyPress={e => (e.key === "Enter" ? handleBlur(e) : null)}
          className={updateSpinner ? "readOnlyText" : "textInput"}
          value={term === null ? "" : term}
          name="Search Term"
          full
          disabled={updateSpinner}
        />
        <Button onClick={() => handleBlur(term)}>Search</Button>
        {results ? (
          <div>
            <b>Resources:</b> <br />
            <ol>
              <li>Total Results: {hits} </li>
              <li>Returned: {returned} </li>
              <li>Displayed: {size}</li>
            </ol>
          </div>
        ) : (
          <div />
        )}
      </Section>
      {results ? <SearchResults data={results} size={size} /> : <div />}
      {size < hits ? (
        <div style={{ float: "right" }}>
          <Button
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            }}
          >
            Back to Top
          </Button>
          <Button onClick={() => setSize(size + 10)}>Show More</Button>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(Search);
