import React, { useEffect, useState } from "react";
import { StaticTable } from "../components/table/index.js";
import { Section } from "@nike/epic-react-ui";

export const RulesSummary = props => {
  //console.log(props);
  const [nameLength, setNameLength] = useState(null);
  const [keyLength, setKeyLength] = useState(null);
  const [illegalAttributes, setIllegalAttributes] = useState([]);
  const [illegalvalues, setIllegalvalues] = useState([]);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (
      illegalvalues.length > 0 &&
      nameLength &&
      keyLength &&
      illegalAttributes.length > 0
    ) {
      setSuccess(true);
    }
  }, [illegalAttributes, illegalvalues, nameLength, keyLength, success]);

  const staticAttributes = {
    data: [
      {
        type: "Case Rule",
        details:
          "Check that the tag's value is a specific case ie. lower, UPPER, CamelCase"
      },
      {
        type: "Value Restrictions",
        details:
          "Strings that are not allowed in the tag value, type column denotes where in the value it is not allowed"
      },
      {
        type: "Allowed Values",
        details:
          "List of the values that are allowed for the tag key, some keys support a friendly mapping but the value to use is in the values column"
      },
      {
        type: "Validation Method",
        details:
          "Gives the description and api endpoint that the value for the tag is sent to to determine validity"
      }
    ],
    columns: [
      {
        Header: "Attributes",
        accessor: "type"
      },
      {
        Header: "Description",
        accessor: "details"
      }
    ]
  };

  for (var i in props.rules[0]) {
    //console.log(i);
    var rule = props.rules[0][i][0];
    if (i === "key_name_rules" && !nameLength) {
      //console.log(rule);
      let localNameLength = nameLength;
      const illegalList = [];
      for (var entry in rule) {
        if (entry.includes("illegal")) {
          //console.log(entry);
          //console.log(props.rule[entry]);
          var valueString = rule[entry].join(", ");
          var local_entry = "";
          if (entry === "illegal_strings") {
            local_entry = "Must Not Contain";
          } else if (entry.includes("starts")) {
            local_entry = "Must Not Start With";
          } else if (entry.includes("ends")) {
            local_entry = "Must Not End With";
          } else {
            local_entry = entry;
          }
          illegalList.push({
            type: local_entry,
            value: valueString
          });
        }
      }
      if ("min_length" in rule && "max_length" in rule) {
        localNameLength =
          "Minimum Length: " +
          rule.min_length +
          " Maximum Length: " +
          rule.max_length;
      }
      setNameLength(localNameLength);

      let localIllegal = illegalAttributes;
      if (illegalAttributes.length === 0) {
        const illegalAttributesLocal = {
          data: illegalList.map(data => ({
            Type: data.type,
            Value: data.value
          })),
          columns: [
            {
              Header: "Type",
              accessor: "Type"
            },
            {
              Header: "Value",
              accessor: "Value"
            }
          ]
        };
        localIllegal.push(
          <StaticTable key="Name Illegal" {...illegalAttributesLocal} />
        );
        //console.log(illegalAttributesLocal);
        setIllegalAttributes(localIllegal);
      }
    } else if (i === "key_rules" && !keyLength) {
      let localKeyLength = keyLength;
      const illegalKeyList = [];
      for (var keyEntry in rule) {
        if (keyEntry.includes("illegal")) {
          //console.log(entry);
          //console.log(props.rule[entry]);
          var keyValueString = rule[keyEntry].join(", ");
          var key_entry = "";
          if (keyEntry === "illegal_strings") {
            key_entry = "Must Not Contain";
          } else if (keyEntry.includes("starts")) {
            key_entry = "Must Not Start With";
          } else if (keyEntry.includes("ends")) {
            key_entry = "Must Not End With";
          } else {
            key_entry = entry;
          }
          illegalKeyList.push({
            type: key_entry,
            value: keyValueString
          });
        }
      }
      if ("min_length" in rule && "max_length" in rule) {
        localKeyLength =
          "Minimum Length: " +
          rule.min_length +
          " Maximum Length: " +
          rule.max_length;
      }
      setKeyLength(localKeyLength);

      let localValues = illegalvalues;
      if (illegalvalues.length === 0) {
        const illegalValuesLocal = {
          data: illegalKeyList.map(data => ({
            Type: data.type,
            Value: data.value
          })),
          columns: [
            {
              Header: "Type",
              accessor: "Type"
            },
            {
              Header: "Value",
              accessor: "Value"
            }
          ]
        };
        localValues.push(
          <StaticTable key="Values Illegal" {...illegalValuesLocal} />
        );
        setIllegalvalues(localValues);
      }
    }
  }

  //console.log(success);

  return (
    <Section
      key="summary"
      id="summary"
      title="Summary"
      collapsible="True"
      collapsibleConfig={{ open: true }}
    >
      <h2>
        <p style={{ padding: "1em" }}>
          CCoE requires that all resources in the cloud have a set of standard
          tags. For more information please visit this link: <br />
          <a
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://confluence.nike.com/x/kGFDMg"
          >
            <b>Nike Cloud Taggging Standard</b>
          </a>
        </p>
      </h2>
      <h2>
        {!success ? (
          ""
        ) : (
          <div>
            <b>
              --- The following restrictions come from the Cloud Service
              Providers ---
            </b>
            <p>
              <br />
              <b>
                <u>Tag Keys</u> have these restrictions:
              </b>
            </p>
            <br />
            <div>
              <b>Length Restrictions:&nbsp;</b> {nameLength}
            </div>
            <br />
            <b>Key Restrictions:&nbsp;</b>
            <div>{illegalAttributes}</div>
            <br />
            <p>
              <b>
                <u>Tag Values</u> have these restrictions:
              </b>{" "}
              <br />
            </p>
            <br />
            <div>
              <b>Length Restrictions:&nbsp;</b> {keyLength}
            </div>
            <br />
            <b>Value Restrictions:&nbsp;</b>
            <div>{illegalvalues}</div>
            <br />
            <p>
              <b>Each Tag Key's value can have the these validations:</b>
              <br />
            </p>
          </div>
        )}
        <StaticTable {...staticAttributes} />
      </h2>
    </Section>
  );
};
