import React, { useState, useRef } from "react";
import config from "../config.js";
import { TextInput, Section, Icon, Spinner, Modal } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import { Delegates } from "./SystemDelegates.js";

export { Tags } from "./SystemTags.js";
export { Metadata } from "./SystemMetadata.js";
export { Settings } from "./SystemSettings.js";
export { Resources } from "./SystemResources.js";
export { Costing } from "./SystemCosting.js";
export { Alerts } from "./SystemAlerts.js";
export { Export } from "./SystemExport";
export { ServiceMetrics } from "./SystemServiceMetrics";
export { ComplianceMetrics } from "./SystemComplianceMetrics";
export { WaffleDetails } from "./SystemWaffleData";
export { Associations } from "./SystemAssociations";
export { TaggingRecommendations } from "./SystemTaggingRecommendations.js";

// Function to convert epoch to human-readable date
export const formatDate = epoch => {
  const date = new Date(epoch * 1000); // Convert epoch to milliseconds
  return date.toLocaleDateString(); // Format date as a string
};

export const Warning = props => {
  let title = props.id.replace("_", " ");
  let a;
  let b;
  if (props.message) {
    a = (
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          backgroundColor: "#ff9800",
          textAlign: "center",
          padding: "20px"
        }}
      >
        This system is configured as an{" "}
        <span style={{ color: "red", fontWeight: "bold" }}>aws_account</span>,
        however there was an internal error retrieving the account for this
        system. Some features have been disabled. Please contact the Cloudred
        team on slack at:&nbsp;
        <a
          style={{ fontSize: "20px", fontWeight: "bold" }}
          className="dashLink"
          target="_blank"
          rel="noopener noreferrer"
          href={config.appSlackUrl}
        >
          #cloudred-discussion&nbsp;
        </a>
      </div>
    );
    b = <APIError error={props.message} />;
  } else if (props.type === "ownership") {
    var owners = props.system.owner;
    var delegates = props.system.delegates;
    if (delegates) {
      for (var i in delegates) {
        owners += ", " + delegates[i];
      }
    }
    a = (
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          backgroundColor: "#ff9800",
          textAlign: "center",
          padding: "20px"
        }}
      >
        <p>You are not authorized to make changes to this system.</p>
        <br />
        <p>
          To request membership in the owning group for this system go to&nbsp;
          <a
            style={{ fontSize: "20px", fontWeight: "bold" }}
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href="https://idlocker.nike.com/identityiq/home.jsf"
          >
            ID Locker&nbsp;
          </a>
          and request membership to <u>{props.system.owner}</u> by navigating
          to: <em> Manage Access {"-->"} Manage User Access</em> in the left
          hand menu
        </p>
        <p>
          This system is owned by: <u>{owners}</u>. If you are one of these
          owners or a member of one of the owning groups contact the Cloudred
          team on slack at:&nbsp;
          <a
            style={{ fontSize: "20px", fontWeight: "bold" }}
            className="dashLink"
            target="_blank"
            rel="noopener noreferrer"
            href={config.appSlackUrl}
          >
            #cloudred-discussion&nbsp;
          </a>
          channel, providing the System ID and Trace ID&nbsp;
          <u>{props.system.trace_id}</u> to have your permissions restored.
        </p>
      </div>
    );
  } else if (props.type === "state") {
    a = (
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          backgroundColor: "#f44336",
          textAlign: "center",
          padding: "20px"
        }}
      >
        <p>
          This system has been deleted and should not have its tag guid applied
          to any resources. No changes can be made to this system.
        </p>
      </div>
    );
  } else if (props.type === "tagging_recommendation") {
    a = (
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          backgroundColor: "#ff9800",
          textAlign: "center",
          padding: "20px"
        }}
      >
        <p>
          This system has pending tagging Recommendations. See the tagging
          Recommendations section and accept or update the Recommendations.
        </p>
      </div>
    );
  } else {
    a = (
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          backgroundColor: "#ff9800",
          textAlign: "center",
          padding: "20px"
        }}
      >
        This system is configured as an{" "}
        <span style={{ color: "red", fontWeight: "bold" }}>aws_account</span>,
        however there is no account linked to this system ID. Due to this issue,
        some CloudRed features may not work as expected for resources associated
        with this system ID. For information on how to resolve this contact the
        Cloudred team on slack at:&nbsp;
        <a
          style={{ fontSize: "20px", fontWeight: "bold" }}
          className="dashLink"
          target="_blank"
          rel="noopener noreferrer"
          href={config.appSlackUrl}
        >
          #cloudred-discussion&nbsp;
        </a>
      </div>
    );
  }
  return (
    <Section id={props.id} title={title}>
      {a}
      {b}
    </Section>
  );
};

export const Details = props => {
  const key = props.data.key;
  const [admin] = useState(props.system.is_admin);
  const [systemState] = useState(props.system.state);
  const [name, setName] = useState(props.system.name);
  const [type] = useState(props.system.type);
  const [owner, setOwner] = useState(props.system.owner);
  const [formStatus, setFormStatus] = useState(null);
  const transientName = useRef(props.system.name);
  const transientOwner = useRef(props.system.owner);
  const timerMessage = useRef(null);
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [message, setMessage] = useState("");
  const [date_created] = useState(props.system.date_created);
  const [date_updated] = useState(props.system.date_updated);
  const formattedDateCreated = formatDate(date_created);
  const formattedDateUpdated = formatDate(date_updated);

  if ((!admin || systemState !== "active") && !updateSpinner) {
    setUpdateSpinner(true);
  }

  const handleBlur = e => {
    if (
      (transientName.current !== name || transientOwner.current !== owner) &&
      !updateSpinner
    ) {
      setUpdateSpinner(true);
      setFormStatus("");
      setFormStatus(
        <div className="spinnerParent">
          <div className="spinnerChild">
            <Spinner medium={true} />
          </div>
        </div>
      );
      clearTimeout(timerMessage.current);
      // fetch("http://127.0.0.1:8000/" + key, {
      fetch(config.apiUrl + "/ssot/systems/" + key, {
        headers: {
          Authorization: "Bearer " + props.accessToken,
          "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
          [e.target.name]: e.target.value
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.status === "fail") {
            setUpdateSpinner(false);
            setFormStatus("");
            setName(transientName.current);
            setOwner(transientOwner.current);
            setShowOverlay(true);
            setMessage(
              <div>
                <div>
                  <h2
                    className="epic-font-brand"
                    style={{
                      textAlign: "left",
                      fontSize: "12",
                      color: "red"
                    }}
                  >
                    Update Failed:
                  </h2>
                  <APIError error={response} showTitle={false} />
                </div>
              </div>
            );
          } else if (!response.key) {
            throw Error("Failure in System API");
          } else {
            setOwner(response.owner);
            setUpdateSpinner(false);
            transientName.current = name;
            transientOwner.current = owner;
            setFormStatus(
              <div className="epic-color-success system-success-message">
                <Icon className="system-info-status" type="checkmark" />
                <div>{"Change saved."}</div>
              </div>
            );
            timerMessage.current = setTimeout(() => {
              setFormStatus("");
            }, 3000);
            return () => clearTimeout(timerMessage.current);
          }
        })
        .catch(error => {
          setUpdateSpinner(false);
          setName(transientName.current);
          setOwner(transientOwner.current);
          setFormStatus(
            <div className="epic-color-error system-info-message">
              <Icon className="system-info-status" type="warning" />
              <div>{error.toString()}</div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 6000);
          return () => clearTimeout(timerMessage.current);
        });
    }
  };

  return (
    <div>
      <Section id={props.id} title="Details">
        <Modal onClose={() => setShowOverlay(false)} show={showOverlay}>
          <div
            style={{
              backgroundColor: "white",
              textAlign: "left"
            }}
          >
            {message}
          </div>
        </Modal>
        <div>{formStatus}</div>
        <TextInput
          label="Name"
          onChange={e => (admin ? setName(e.target.value) : null)}
          onKeyPress={e => (e.key === "Enter" ? handleBlur(e) : null)}
          onBlur={e => (admin && type !== "aws_account" ? handleBlur(e) : null)}
          className={
            !admin
              ? "readOnlyText"
              : updateSpinner
              ? "readOnlyText"
              : "textInput"
          }
          value={name}
          name="name"
          disabled={type === "aws_account" ? true : updateSpinner}
        />
        <div style={{ marginBottom: "30px" }}></div>
        <TextInput
          onChange={e => (admin ? setOwner(e.target.value) : null)}
          onKeyPress={e => (e.key === "Enter" ? handleBlur(e) : null)}
          onBlur={e => (admin ? handleBlur(e) : null)}
          label="Owner"
          className={
            !admin
              ? "readOnlyText"
              : updateSpinner
              ? "readOnlyText"
              : "textInput"
          }
          value={owner}
          name="owner"
          disabled={updateSpinner}
          // readOnly={type === "aws_account" ? true : false}
        />
        <Delegates {...props} />
        <div style={{ marginBottom: "30px" }}></div>
        <TextInput
          label="Type"
          className="readOnlyText"
          value={type}
          name="type"
          disabled={true}
        />
        <div style={{ marginBottom: "30px" }}></div>
        {type === "application" ? (
          <TextInput
            label="System ID - tag your cloud resources with this value as the nike-tagguid"
            className="readOnlyText"
            value={key}
            name="type"
            disabled={true}
          />
        ) : (
          <TextInput
            label="System ID"
            className="readOnlyText"
            value={key}
            name="type"
            disabled={true}
          />
        )}
        <div style={{ marginBottom: "30px" }}></div>
        <TextInput
          label="Date Created"
          className="readOnlyText"
          value={formattedDateCreated}
          name="date_created"
          disabled={true}
        />
        <div style={{ marginBottom: "30px" }}></div>
        <TextInput
          label="Date Updated"
          className="readOnlyText"
          value={formattedDateUpdated}
          name="date_updated"
          disabled={true}
        />
      </Section>
    </div>
  );
};
