import React from "react";

export const TaggingComplianceModalData = props => {
  const formatdata = (data, highlights = Array([])) => {
    const list_items = [];
    let key = null;
    let value = null;
    for (var item in data) {
      key = <span style={{ fontWeight: "bold" }}>{item}:</span>;
      if (typeof data[item] === "object") {
        value = formatdata(data[item], highlights);
      } else {
        value = JSON.stringify(data[item]);
      }

      list_items.push(
        highlights.includes(item) ? (
          <li key={item} style={{ backgroundColor: "yellow" }}>
            {key}
            {value}
          </li>
        ) : (
          <li key={item}>
            {key}
            {value}
          </li>
        )
      );

      key = null;
      value = null;
    }
    return <ul>{list_items}</ul>;
  };
  return <div>{formatdata(props.resource)}</div>;
};
