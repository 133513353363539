import React from "react";
import PropTypes from "prop-types";
import { Chart } from "react-charts";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";

const ComplianceGraph = ({
  data,
  width = 600,
  height = 400,
  header,
  margin,
  resizable = true,
  ...others
}) => {
  const [{ activeDatumIndex }, setState] = React.useState({
    activeSeriesIndex: -1,
    activeDatumIndex: -1
  });

  const getDatumStyle = React.useCallback(
    datum => ({
      opacity:
        activeDatumIndex > -1 ? (datum.index === activeDatumIndex ? 1 : 0.3) : 1
    }),
    [activeDatumIndex]
  );

  const onFocus = React.useCallback(
    focused =>
      setState({
        activeDatumIndex: focused ? focused.index : -1
      }),
    [setState]
  );

  const barData = React.useMemo(() => data, [data]);

  const series = React.useMemo(
    () => ({
      type: "bar"
    }),
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: "ordinal", position: "bottom" },
      { position: "left", type: "linear", stacked: true, base: 0 }
    ],
    []
  );

  return (
    <div
      style={{
        width: "auto",
        height: "auto",
        margin: margin
      }}
    >
      <h2 style={{ textAlign: "center" }}>
        <b>{header}</b>
      </h2>
      <br></br>
      {resizable ? (
        <ResizableBox width={width} height={height}>
          <Chart
            data={barData}
            axes={axes}
            series={series}
            getDatumStyle={getDatumStyle}
            onFocus={onFocus}
            tooltip
          />
        </ResizableBox>
      ) : (
        <div style={{ width: `${width}px`, height: `${height}px` }}>
          <Chart
            data={barData}
            axes={axes}
            series={series}
            getDatumStyle={getDatumStyle}
            onFocus={onFocus}
            tooltip
          />
        </div>
      )}
    </div>
  );
};

ComplianceGraph.propTypes = {
  data: PropTypes.array
};

ComplianceGraph.defaultProps = {
  data: []
};

export default ComplianceGraph;
