import { connect } from "react-redux";
import React from "react";

import { notifyOffline } from "../offline/actions.js";
import { isOffline } from "../offline/reducer.js";

const OfflineBanner = ({ offline }) =>
  offline ? (
    <div>
      <span>
        <i />
      </span>
      <span>OFFLINE MODE</span>
      <span>functionality may be limited</span>
    </div>
  ) : null;

export default connect(
  state => ({
    offline: isOffline(state)
  }),
  { notifyOffline }
)(OfflineBanner);
