import {
  loginUser,
  innerLoginUser,
  logoutUser,
  innerLogoutUser,
  initializeUserAuth,
  innerInitializeUserAuth
} from './actions.js'

const validate = (key, prop, type = 'string') => {
  if (typeof prop !== type) {
    throw new Error(`Error validating oauth configuration: ${key} must be a(n) ${type}`)
  }
}

const validateConfig = config => {
  validate('authorization', config.authorization)
  if (config.response_type === 'authorization_code') {
    validate('token', config.token)
  }
  validate('client_id', config.client_id)
  validate('redirect_uri', config.redirect_uri)
  validate('response_type', config.response_type)
  validate('scope', config.scope)
  if (config.store) {
    validate('store.tokenName', config.store.tokenName)
    validate('store.hydrateName', config.store.hydrateName)
  }

  if (!('keepToken' in config) || config.keepToken) {
    Object.assign(config, { keepToken: true })
  } else {
    Object.assign(config, { keepToken: false })
  }

  validate('keepToken', config.keepToken, 'boolean')
}

const createAuthMiddleware = ({ config, authEvents = {} } = {}) => {
  validateConfig(config)
  return ({ dispatch, getState }) => next => action => {
    switch (action.type) {
      case loginUser.type:
        innerLoginUser(config, authEvents)(dispatch, getState)
        break
      case logoutUser.type:
        innerLogoutUser(authEvents)(dispatch, getState)
        break
      case initializeUserAuth.type:
        innerInitializeUserAuth(config, authEvents)(dispatch, getState)
        break
    }

    next(action)
  }
}

export default createAuthMiddleware
