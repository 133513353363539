import { setIndex, clearIndexes } from "./SystemActions";

const initialState = {};

export default function resources(state = initialState, action) {
  switch (action.type) {
    case setIndex.event.start:
      for (var key in action.payload) {
        state = { ...state, [key]: action.payload[key] };
      }
      return state;
    case clearIndexes.type:
      return {};
    default:
      return state;
  }
}

export const selectIndexData = state => state.resources;
