import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import config from "../config.js";
import APIError from "../components/APIError.js";
import { SortableTable } from "../components/table/index.js";
import { navigate } from "raviger";
import {
  Button,
  TextInput,
  Section,
  Modal,
  Icon,
  Spinner
} from "@nike/epic-react-ui";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import { SystemSearch } from "./CollectionSystemsSearch.js";

const CollectionRegistration = user => {
  const obj = {
    headers: {
      Authorization: "Bearer " + user.accessToken
    }
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const [message, setMessage] = useState("");
  const [owner, setOwner] = useState("");
  const [systems, setSystems] = useState([]);
  const [errors, setErrors] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [systemsBreakout, setSystemsBreakout] = useState(null);
  const [breakoutReset, setBreakoutReset] = useState(true);
  let sysData = [];
  let sysDataMin = [];
  const [modal, setModal] = useState(false);
  const [createRunning, setCreateRunning] = useState(false);
  const [errorMessageBreakout, setErrorMessageBreakout] = useState(null);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    setSystemsBreakout(null);

    // fetch("http://127.0.0.1:5000/breakout", {
    if (systems.length === 0) {
      setSystemsBreakout([]);
    } else if (breakoutReset) {
      setBreakoutReset(false);
      fetch(config.apiUrl + "/ssot/systems/breakout", {
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Cache-Control": "no-cache",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          systems: systems
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.status === "fail") {
            setErrorMessageBreakout("System detail not fully loaded.");
          } else if (!response.applications) {
            throw Error("System detail not fully loaded.");
          } else {
            setSystemsBreakout(response);
            setErrorMessageBreakout(null);
          }
        })
        .catch(error => {
          console.log(error);
          setErrorMessageBreakout(error);
        });
    }
  }, [user.accessToken, breakoutReset, systems]);

  const editSystems = newSystems => {
    setModal(false);
    const sendSystems = [];
    sendSystems.push(...newSystems);
    setSystems(sendSystems);
    setSystemsBreakout(null);
    setBreakoutReset(true);
  };

  const deleteSystems = key => {
    const sendSystems = [...systems];
    sendSystems.splice(key, 1);
    setSystems(sendSystems);
    setSystemsBreakout(null);
    setBreakoutReset(true);
    // setSystemsBreakout(null);
  };

  for (let key in systems) {
    let realSystemName = null;
    let systemType = null;

    if (systemsBreakout) {
      //eslint-disable-next-line
      for (const [type, list] of Object.entries(systemsBreakout)) {
        const found = list.find(h => h.system_id === systems[key]);
        if (found) {
          realSystemName = found.system_name;
          systemType = found.system_type;
        }
      }
    }
    sysData.push({
      key: key,
      value: systems[key],
      name: realSystemName,
      type: systemType
    });
  }

  sysData = sysData.map(data => ({
    key: data.key,
    value: data.value,
    name: data.name,
    type: data.type,
    actions: (
      <div style={{ textAlign: "center" }}>
        <Button small onClick={() => deleteSystems(data.key)}>
          Delete
        </Button>
        <span style={{ padding: "5px" }}></span>
        <Button
          small
          onClick={() => navigate(basePath + "/system/" + data.value)}
        >
          <Icon type="preview" />
        </Button>
      </div>
    )
  }));

  const tblSystemProps = {
    data: sysData,
    columns: [
      {
        Header: "System ID",
        accessor: "value"
      },
      {
        Header: "System Type",
        accessor: "type"
      },
      {
        Header: "System Name",
        accessor: "name"
      },
      {
        Header: "",
        accessor: "actions"
      }
    ],
    minRows: 0,
    showPagination: false,
    filterable: false
  };

  for (let key in systems) {
    sysDataMin.push({
      key: key,
      value: systems[key]
    });
  }

  sysDataMin = sysDataMin.map(data => ({
    key: data.key,
    value: data.value,
    actions: (
      <div style={{ textAlign: "center" }}>
        <Button small onClick={() => deleteSystems(data.key)}>
          Delete
        </Button>
        <span style={{ padding: "5px" }}></span>
        <Button
          small
          onClick={() => navigate(basePath + "/system/" + data.value)}
        >
          <Icon type="preview" />
        </Button>
      </div>
    )
  }));

  const tblSystemPropsMin = {
    data: sysDataMin,
    columns: [
      {
        Header: "System ID",
        accessor: "value"
      },
      {
        Header: "",
        accessor: "actions"
      }
    ],
    minRows: 0,
    showPagination: false,
    filterable: false
  };

  const addBtn = (
    <Button
      style={{ marginBottom: "1em" }}
      onClick={() => {
        setModal(true);
      }}
    >
      Edit Systems
    </Button>
  );

  const submitForm = e => {
    setCreateRunning(true);
    e.preventDefault();
    if (!validateRequiredFields()) {
      return setErrors(true);
    } else {
      setErrors(false);
    }
    const formSystems = [];
    for (var i = 0; i < systems.length; i++) {
      formSystems.push(systems[i]);
    }
    obj.body = JSON.stringify({
      status: "active",
      name: name,
      owner: owner,
      description: description,
      systems: formSystems
    });
    obj.headers["Content-Type"] = "application/json";

    obj.method = "POST";

    fetch(config.apiUrl + "/ssot/ous/", obj)
      // fetch("http://127.0.0.1:8000/", obj)
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          throw Error(response.message[0].message[0]);
        } else {
          setCreateRunning(false);
          navigate(basePath + "/collection/" + response.key);
        }
      })
      .catch(error => {
        setCreateRunning(false);
        setShowOverlay(true);
        setErrors(true);
        setMessage(error.toString());
        console.log(error);
      });

    return true;
  };

  const validateRequiredFields = () => {
    if (!owner || !name || !description) {
      return false;
    } else {
      return true;
    }
  };

  const tbl = <SortableTable {...tblSystemProps} />;
  const tblMin = <SortableTable {...tblSystemPropsMin} />;

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <style>
        {`.epic__section__671 {
            background-color: #f7f7f7;
            border-radius: 1em;
            margin-bottom: 30px;
            padding: 20px 1%;
            position: relative;
          }
        .epic__sectionContent__947 {
          margin-top: 0px;
        }
        .epic__table__5b1 .epic__thead__ce3 .epic__tr__da0 .epic__th__715.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__table__5b1 .epic__tbody__405 .epic__tr__da0 .epic__td__f82.epic__compactCell__6f5 {
          height: 56px;
          text-align: center;
        }
        .epic__container__d0a.epic__disabled__f8e {
            opacity: 1;
        }`}
      </style>
      <Spinner show={createRunning && !errors} overlay={true} />
      <Modal onClose={() => setShowOverlay(false)} show={showOverlay}>
        <p
          style={{
            backgroundColor: "white",
            textAlign: "center",
            color: "red"
          }}
        >
          {message}
        </p>
      </Modal>
      <form noValidate onSubmit={submitForm}>
        <Section title="Details">
          <TextInput
            required
            label="What do you call this systems collection?"
            onChange={e => setName(e.target.value)}
            onKeyPress={e =>
              e.key === "Enter" ? setName(e.target.value) : null
            }
            className="textInput"
            value={name}
            hasErrors={errors && !name}
            full
          />
          <div style={{ marginBottom: "30px" }}></div>
          <TextInput
            required
            label="What is the description for this systems collection?"
            onChange={e => setDescription(e.target.value)}
            onKeyPress={e =>
              e.key === "Enter" ? setName(e.target.value) : null
            }
            className="textInput"
            value={description}
            hasErrors={errors && !description}
            full
          />
          <div style={{ marginBottom: "30px" }}></div>
          <TextInput
            required
            label="Who is the owner of this systems collection? (Active Directory email or group name)"
            onChange={e => setOwner(e.target.value)}
            onKeyPress={e =>
              e.key === "Enter" ? setName(e.target.value) : null
            }
            className="textInput"
            value={owner}
            hasErrors={errors && !owner}
            full
          />
        </Section>
        <Section title="Systems">
          <p style={{ padding: "1em", fontStyle: "italic" }}>
            Provide a list of systems and systems collections to include in this
            Systems Collection
          </p>
          <br />
          <div style={{ margin: "30px" }}></div>
          <Modal
            onClose={() => setModal(false)}
            closeButton
            title=""
            show={modal}
          >
            <SystemSearch
              createNewSystems={editSystems}
              systems={systems}
              {...user}
            />
          </Modal>
          {addBtn}
          {systemsBreakout ? (
            sysData.length > 0 ? (
              tbl
            ) : (
              ""
            )
          ) : errorMessageBreakout ? (
            <div>
              <APIError
                error={errorMessageBreakout}
                title="Error retrieving system details"
                showTitle={true}
              />
              {sysDataMin.length > 0 ? (
                <div style={{ marginTop: "10px" }}>{tblMin}</div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <Spinner />
          )}
        </Section>
        <div
          style={{
            marginBottom: "5em",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button onClick={submitForm}>Submit</Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(CollectionRegistration);
