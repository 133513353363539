import React from "react";
import { TextInput, Section } from "@nike/epic-react-ui";

export const WaffleDetails = props => {
  const account = props.account;

  return (
    <div>
      {/* <PageSpinner show={isDeleting} overlay={true} /> */}
      <Section id={props.id} title="Account Details">
        <b>
          This data comes from Waffle Iron about this account and is therefore
          read only
        </b>
        <br />
        <br />
        <TextInput
          label="Account Number"
          className="readOnlyText"
          value={account.accountId}
          name="Account Number"
          full
          disabled={true}
        />
        <br />
        <TextInput
          label="Account Name"
          className="readOnlyText"
          value={account.name}
          name="Account Name"
          full
          disabled={true}
        />
        <br />
        <TextInput
          label="Account Type"
          className="readOnlyText"
          value={account.accountType}
          name="Account Type"
          full
          disabled={true}
        />
        <br />
        <TextInput
          label="Account Status"
          className="readOnlyText"
          value={account.status}
          name="Account Status"
          full
          disabled={true}
        />
        <br />
        <div>
          Link to:&nbsp;
          <a
            style={{ fontSize: "18px", margin: "1em" }}
            className="dashLink"
            target="_self"
            rel="noopener noreferrer"
            href={
              "https://ui.waffleiron-prod.nikecloud.com/account/" +
              account.accountId
            }
          >
            Waffle Iron Account
          </a>
        </div>
      </Section>
    </div>
  );
};
