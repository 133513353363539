import React from "react";
import { Tabs } from "@nike/epic-react-ui";
import { TaggingComplianceModalData } from "./TaggingComplianceModalData";
import { TaggingComplianceErrorData } from "./TaggingComplianceErrorData";

export const ComplianceDetails = props => {
  const displayDetails = () => {
    return (
      <div>
        <Tabs
          className="tabs"
          tabs={["Data", "Errors"]}
          selectedTabPanelClassName="activeTabPanel"
        >
          <Tabs.TabPanel className="tabPanel">
            <TaggingComplianceModalData {...props} />
          </Tabs.TabPanel>
          <Tabs.TabPanel className="tabPanel">
            <b>Validation Errors:</b>
            <TaggingComplianceErrorData {...props} />
          </Tabs.TabPanel>
        </Tabs>
      </div>
    );
  };

  return <div>{displayDetails()}</div>;
};
