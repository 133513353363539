import React, { useState, useEffect } from "react";
import { Section, Spinner, Select } from "@nike/epic-react-ui";
import APIError from "../components/APIError.js";
import config from "../config.js";

export const ServiceMetrics = props => {
  const key = props.data.key;
  const [metrics, setMetrics] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedHours, setSelectedHours] = useState({
    value: 12,
    label: "12 hours"
  });
  let hourList = [
    { value: 3, label: "3 hours" },
    { value: 6, label: "6 hours" },
    { value: 12, label: "12 hours" },
    { value: 24, label: "24 hours" }
  ];

  useEffect(() => {
    setMetrics(null);
    let url =
      config.apiUrl +
      "/metrics/tagging?system_id=" +
      // "http://127.0.0.1:8002/tagging?system_id=" +
      key +
      "&hours=" +
      selectedHours.value;

    fetch(url, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setErrorMessage(response);
          // throw Error(response.message);
        } else {
          const a = [];
          setErrorMessage(null);
          for (const [key, value] of Object.entries(response)) {
            // console.log(key + ":" + value);
            a.push({
              system_id: key,
              metric_title: value["metric_title"],
              metric_description: value["metric_description"],
              system_name: value["system_name"],
              value: value["value"]
            });
          }
          setMetrics(a);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(error);
      });
  }, [key, selectedHours, props.accessToken, props.data.key]);

  const showCards = metric => {
    return (
      <div
        className="metricCard"
        key={metric["system_id"]}
        style={{ height: "250px", width: "250px", marginLeft: "10px" }}
      >
        <h1 className="metricCardTitle" key="metric_title">
          {metric["metric_title"]}
        </h1>
        <h3 className="metricsCardSubtitle" key="metric_description">
          {metric["metric_description"]}
        </h3>
        <p className="metricCardText" key="value">
          {metric["value"]}
        </p>
      </div>
    );
  };

  return (
    <Section id={props.id} title="Service Metrics">
      <div>
        <h2>
          <b>Tagging Service Metrics</b>
        </h2>
        <br />
        <div style={{ width: "150px" }}>
          <Select
            className={"textInput"}
            options={hourList}
            value={selectedHours}
            onChange={selected => {
              setSelectedHours(selected);
              setErrorMessage(null);
            }}
          />
        </div>
        <div style={{ marginBottom: "30px" }}></div>
        {metrics ? (
          <div style={{ textAlign: "center", display: "flex" }}>
            {metrics.map(data => {
              //console.log(data);

              return showCards(data);
            })}
          </div>
        ) : !errorMessage ? (
          <Spinner />
        ) : (
          <APIError showTitle={true} error={errorMessage} />
        )}
      </div>
    </Section>
  );
};
