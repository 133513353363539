import {
  AuthEvents,
  selectLoginError,
  selectAccessToken
} from "@nike/oauth-client";
import { navigate } from "raviger";

import { notifyError } from "../notifications/actions.js";
import { setGlobalHeader } from "../util/http.js";

const events = new AuthEvents();

events.on("loginSuccess", (dispatch, getState) => {
  const state = getState().auth;

  if (state.previousLocation) {
    navigate(state.previousLocation.pathname || "/", true);
  }

  setGlobalHeader("Authorization", "Bearer " + selectAccessToken(getState()));
});

events.on("loginError", (dispatch, getState) => {
  const loginError = selectLoginError(getState());

  if (loginError) {
    notifyError({ message: loginError });
  }
});

events.on("refreshSuccess", (dispatch, getState) => {
  setGlobalHeader("Authorization", "Bearer " + selectAccessToken(getState()));
});

events.on("refreshError", (dispatch, getState) => {
  const loginError = selectLoginError(getState());

  if (loginError) {
    notifyError({ message: loginError });
  }
});

events.on("logoutError", () => {
  navigate("/");
});

export default events;
