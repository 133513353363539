import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import config from "../config.js";
import {
  hasAuthCheckFinished,
  selectIsLoggedIn,
  selectOpenIDToken,
  selectAccessToken,
  loginUser,
  selectEmail
} from "@nike/oauth-client";
import http from "../util/http.js";
import { Button, Icon, Spinner } from "@nike/epic-react-ui";
import Checkbox from "../components/Checkbox";
import { FilterableSortTable } from "../components/table/index.js";
import { navigate } from "raviger";
import APIError from "../components/APIError.js";

const SystemIndex = user => {
  const [apps, setApps] = useState([]);
  const [accts, setAccts] = useState([]);
  const [errorMessageApps, setErrorMessageApps] = useState(null);
  const [errorMessageAccts, setErrorMessageAccts] = useState(null);
  const [filter, setFilter] = useState(user.email);
  const [checked, setChecked] = useState(true);
  const [appsProcessed, setAppsProcessed] = useState(false);

  let basePath =
    window.location.href.indexOf("platforms.nike.com") !== -1
      ? "/cloudred"
      : "";

  useEffect(() => {
    var args = "";
    if (filter !== null) {
      args = "?owner=" + filter;
    }
    http
      .get(config.apiUrl + "/ssot/systems/" + args)
      // .get("http://127.0.0.1:8000/")
      // fetch("http://127.0.0.1:8000/")
      // .then(resources => resources.json())
      .then(response => {
        // console.log(response);
        if (response.status === "fail") {
          setErrorMessageApps(response);
        } else {
          setErrorMessageApps(null);
          setApps(response);
          setAppsProcessed(true);
        }
      })
      .catch(error => {
        setErrorMessageApps(error);
        console.log(error);
      });
  }, [user.accessToken, filter]);

  useEffect(() => {
    http
      .get(config.apiUrl + "/ssot/systems/account")
      // .get("http://127.0.0.1:8000/account")
      .then(response => {
        if (response.status === "fail") {
          setErrorMessageAccts(response);
        } else {
          setErrorMessageAccts(null);
          setAccts(response);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageAccts(error);
      });
  }, [user.accessToken]);

  const getAccountItem = (key, type, item) => {
    if (type !== "aws_account") return "N/A";

    for (var acct in accts) {
      if (accts[acct].tagging && accts[acct].tagging.tagGuid === key)
        return accts[acct][item];
    }
    return "N/A";
  };

  const props = {
    data: apps.map(data => ({
      Key: data.key,
      Type: data.type,
      Name: data.name,
      Owner: data.owner,
      AccountType: getAccountItem(data.key, data.type, "accountType"),
      AccountId: getAccountItem(data.key, data.type, "accountId")
    })),
    fields: ["Type", "AccountType", "AccountId"],
    columns: [
      {
        Header: "System ID",
        accessor: "Key"
      },
      {
        Header: "Name",
        accessor: "Name"
      },
      {
        Header: "Owner",
        accessor: "Owner"
      },
      {
        Header: "System Type",
        accessor: "Type"
      },
      {
        Header: "Account Type",
        accessor: "AccountType",
        Cell: props =>
          props.cell.value === "N/A" ? (
            <div
              style={{
                fontStyle: "italic",
                color: "gray"
              }}
            >
              {props.cell.value}
            </div>
          ) : (
            props.cell.value
          )
      },
      {
        Header: "Account Id",
        accessor: "AccountId",
        Cell: props =>
          props.cell.value === "N/A" ? (
            <div
              style={{
                fontStyle: "italic",
                color: "gray"
              }}
            >
              {props.cell.value}
            </div>
          ) : (
            props.cell.value
          )
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <a href={basePath + "/system/" + row.original.Key}>
              <Button
                onClick={() =>
                  navigate(basePath + "/system/" + row.original.Key)
                }
              >
                <Icon type="edit" />
              </Button>
            </a>
          </div>
        )
      }
    ]
  };
  const handleClick = () => {
    navigate(basePath + "/system/register");
  };

  const handleChecked = () => {
    setApps([]);
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  useEffect(() => {
    if (checked) {
      setFilter(user.email);
    } else {
      setFilter(null);
    }
  }, [user, checked]);

  const showTable = () => {
    return (
      <div>
        <FilterableSortTable {...props} />
      </div>
    );
  };

  return (
    <div style={{ margin: "60px 1em 1em" }}>
      <a href={basePath + "/system/register"}>
        <Button onClick={() => handleClick()} style={{ margin: "1em" }}>
          Register a system
        </Button>
      </a>
      Filter to Owned Systems:
      <Checkbox
        defaultChecked={true}
        onClick={() => handleChecked()}
        checked={checked}
      />
      {!errorMessageApps && !errorMessageAccts ? (
        appsProcessed === false || accts.length === 0 ? (
          <Spinner overlay={false} show={true} />
        ) : (
          showTable()
        )
      ) : (
        <APIError
          showTitle={true}
          error={errorMessageApps ? errorMessageApps : errorMessageAccts}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  idToken: selectOpenIDToken(state),
  accessToken: selectAccessToken(state),
  isLoggedIn: selectIsLoggedIn(state),
  authFinished: hasAuthCheckFinished(state),
  email: selectEmail(state)
});

export default connect(mapStateToProps, { loginUser })(SystemIndex);
