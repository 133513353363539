const MIN_VERIFIER_LENGTH = 43
const MAX_VERIFIER_LENGTH = 128
export const CODE_CHALLENGE_METHOD = 'S256'

const base64ToBase64Url = input => input.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')

const stringToBase64Url = input => base64ToBase64Url(btoa(input))

const dec2hex = dec => ('0' + dec.toString(16)).substr(-2)

const getRandomString = length => {
  let a = new Uint8Array(Math.ceil(length / 2))

  crypto.getRandomValues(a)

  let str = Array.from(a, dec2hex).join('')

  return str.slice(0, length)
}

export const makeVerifier = () => {
  let verifier = getRandomString(MIN_VERIFIER_LENGTH)

  return encodeURIComponent(verifier).slice(0, MAX_VERIFIER_LENGTH)
}

export const computeChallenge = async str => {
  let buffer = new TextEncoder().encode(str)
  const arrayBuffer = await crypto.subtle.digest('SHA-256', buffer)
  let hash = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
  let b64u = stringToBase64Url(hash)
  return b64u
}

const validateOptions = options => {
  if (!options.code) {
    throw new Error('A single use code must be specified to get a token')
  }

  if (!options.client_id) {
    throw new Error('A client_id must be specified to get a token')
  }

  if (!options.redirect_uri) {
    throw new Error('The redirect_uri passed to /authorize must also be passed to /token')
  }

  if (!options.code_verifier) {
    throw new Error('The code_verifier passed to /authorize must also be passed to /token')
  }
}

export const getToken = async options => {
  validateOptions(options)

  let params = new URLSearchParams()

  params.set('code', options.code)
  params.set('client_id', options.client_id)
  params.set('redirect_uri', options.redirect_uri)
  params.set('grant_type', 'authorization_code')
  params.set('code_verifier', options.code_verifier)

  let response = await fetch(options.token, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: params
  })

  return response.json()
}
