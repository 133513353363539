import React, { useState } from "react";
import config from "../config.js";
import {
  TextInput,
  ToggleSwitch,
  Tooltip,
  Section,
  Icon,
  Button,
  Modal,
  Spinner
} from "@nike/epic-react-ui";
import { SortableTable } from "../components/table/index.js";
import { boolean } from "./SystemData.js";

export const Metadata = props => {
  const systemid = props.data.key;
  let mdData = [];
  const [admin] = useState(props.system.is_admin);
  const [systemState] = useState(props.system.state);
  const [modal, setModal] = useState(false);
  const [moveModal, setMoveModal] = useState(false);
  const [metadataKey, setMetadataKey] = useState("");
  const [metadataValue, setMetadataValue] = useState("");
  const [metadataCategory, setMetadataCategory] = useState("General");
  const [metadataVisible, setMetadataVisible] = useState(false);
  const [formStatus, setFormStatus] = useState("");
  const [updateSpinner, setUpdateSpinner] = useState(false);
  const [deleteSpinner, setDeleteSpinner] = useState([]);
  const [errors, setErrors] = useState(false);
  const metadata = props.metadata;
  const setMetadata = props.setMetadata;
  const metadataConfig = props.metadataConfig;
  const setMetadataConfig = props.setMetadataConfig;
  const tags = props.tags;
  const setTags = props.setTags;

  // set boolean value for use in selective rendering to see if system is editable
  var editable = false;
  if (admin && systemState === "active") {
    editable = true;
  }

  const createNewMetadata = () => {
    if (!validateRequiredFields()) {
      return setErrors(true);
    } else {
      setErrors(false);
    }
    const sendMetadata = { ...metadata };
    sendMetadata[metadataKey] = metadataValue;
    const sendMetadataConfig = {
      [metadataKey]: {
        category: metadataCategory,
        visible: metadataVisible
      }
    };
    updateMetadata(sendMetadata, sendMetadataConfig);
  };

  const deleteMetadata = key => {
    setDeleteSpinner([key]);
    const sendMetadata = { ...metadata };
    delete sendMetadata[key];
    updateMetadata(sendMetadata);
  };

  const editMetadata = key => {
    setMetadataKey(key);
    setMetadataValue(metadata[key]);
    setMetadataCategory(metadataConfig[key].category);
    setMetadataVisible(metadataConfig[key].visible);
    setModal(true);
  };

  const moveMetadata = () => {
    const sendTags = { ...tags };
    sendTags[metadataKey] = metadataValue;
    if (metadataKey in tags) {
      setMoveModal(false);
      setFormStatus(
        <div
          className="epic-color-error system-info-message"
          style={{ width: "300px" }}
        >
          <Icon className="system-info-status" type="warning" />
          <div>Key already exists in tag set</div>
        </div>
      );
      setTimeout(() => {
        setFormStatus("");
      }, 4000);
      return;
    }

    updateTags(sendTags);
  };

  const moveMetadataModal = key => {
    setMetadataKey(key);
    setMetadataValue(metadata[key]);
    setMetadataCategory(metadataConfig[key].category);
    setMetadataVisible(metadataConfig[key].visible);
    setMoveModal(true);
  };

  // add tag and remove metadata
  const updateTags = sendTags => {
    setUpdateSpinner(true);
    fetch(config.apiUrl + "/ssot/systems/" + systemid, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify({
        tags: sendTags
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          setUpdateSpinner(false);
          setMoveModal(false);
          let matches = response.details[0].matchAll(/'(.*?)'/g);
          let errors = [];
          for (const error of matches) {
            errors.push(error[1]);
          }
          setFormStatus(
            <div
              className="epic-color-error system-info-message"
              style={{ width: "500px" }}
            >
              <Icon className="system-info-status" type="warning" />
              <div>
                {response.message}
                <ul style={{ textAlign: "left", margin: "10px 0px 0px 100px" }}>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 8000);
        } else if (!response.key) {
          throw Error("Failure in System API");
        } else {
          setTags(response.tags);
          deleteMetadata(metadataKey);
          setMoveModal(false);
        }
      })
      .catch(error => {
        setFormStatus(
          <div className="system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 6000);

        setUpdateSpinner(false);
        setMoveModal(false);
      });
  };

  for (var key in metadata) {
    let category = "";
    let visible = "";

    if (metadataConfig && metadataConfig[key]) {
      category = metadataConfig[key].category;
      visible = metadataConfig[key].visible.toString();
    }

    if (category !== "Tagging Recommendation") {
      mdData.push({
        key: key,
        value: metadata[key],
        category: category,
        visible: visible
      });
    }
  }

  const validateRequiredFields = () => {
    return metadataKey && metadataValue && metadataCategory;
  };

  const buttonState = data => {
    if (admin && systemState === "active") {
      return (
        <div style={{ textAlign: "center" }}>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => editMetadata(data.key)}
          >
            {/* <Icon type="edit" /> */}
            Edit
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => moveMetadataModal(data.key)}
          >
            Promote
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button
            small
            disabled={deleteSpinner.length > 0 ? true : updateSpinner}
            onClick={() => deleteMetadata(data.key)}
          >
            {/* <Icon type="delete" /> */}
            Delete
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <Button small disabled>
            {/* <Icon type="edit" /> */}
            Edit
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button small disabled>
            Promote
          </Button>
          <span style={{ padding: "5px" }}></span>
          <Button small disabled>
            {/* <Icon type="delete" /> */}
            Delete
          </Button>
        </div>
      );
    }
  };

  mdData = mdData.map(data => ({
    key: data.key,
    value: data.value,
    category: data.category,
    visible: data.visible,
    actions: buttonState(data)
  }));

  const tblProps = {
    data: mdData,
    columns: [
      {
        Header: "Key",
        accessor: "key"
      },
      {
        Header: "Value",
        accessor: "value"
      },
      {
        Header: "Category",
        accessor: "category"
      },
      {
        Header: "Visible",
        accessor: "visible"
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {deleteSpinner.includes(row.original.key) ? (
              <Spinner />
            ) : (
              row.original.actions
            )}
          </div>
        )
      }
    ],
    minRows: 0,
    showPagination: false,
    filterable: false
  };

  const updateMetadata = (sendMetadata, sendMetadataConfig) => {
    setUpdateSpinner(true);
    let body = sendMetadataConfig
      ? { metadata: sendMetadata, metadata_config: sendMetadataConfig }
      : { metadata: sendMetadata };
    fetch(config.apiUrl + "/ssot/systems/" + systemid, {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") {
          throw Error(response.message[0].message);
        } else if (!response.key) {
          throw Error("Failure in Systems API");
        } else {
          setMetadataKey("");
          setMetadataValue("");
          setMetadataCategory("General");
          setMetadataVisible(false);
          setUpdateSpinner(false);
          setDeleteSpinner([]);
          setModal(false);
          setMetadata(response.metadata);
          setMetadataConfig(response.metadata_config);
          setFormStatus(
            <div className="epic-color-success system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>{"Change saved."}</div>
            </div>
          );
          setTimeout(() => {
            setFormStatus("");
          }, 2000);
          setUpdateSpinner(false);
          setDeleteSpinner([]);
        }
      })
      .catch(error => {
        console.log(error);
        setFormStatus(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.toString()}</div>
          </div>
        );
        setTimeout(() => {
          setFormStatus("");
        }, 6000);

        setMetadataKey("");
        setMetadataValue("");
        setMetadataCategory("");
        setMetadataVisible(false);
        setUpdateSpinner(false);
        setDeleteSpinner([]);
        setModal(false);
      });
  };

  const addBtn = editable ? (
    <Button
      style={{ marginBottom: "1em" }}
      onClick={() => {
        setMetadataKey("");
        setMetadataValue("");
        setMetadataCategory("General");
        setMetadataVisible(false);
        setModal(true);
      }}
    >
      Add Metadata
    </Button>
  ) : (
    ""
  );
  //console.log(tblProps);
  const tbl =
    mdData.length > 0 ? (
      <SortableTable {...tblProps} />
    ) : (
      <p>No Metadata has been defined for this system</p>
    );

  return (
    <Section id={props.id} key={props.id} title="Metadata">
      <Modal
        onClose={() => {
          setModal(false);
          setErrors(false);
        }}
        closeButton
        title=""
        show={modal}
      >
        <div>
          <div key="inputs" style={{ marginBottom: "30px" }}>
            <TextInput
              key="metadataKey"
              label="Key"
              className="textInput"
              full
              value={metadataKey}
              onChange={e => setMetadataKey(e.target.value)}
              hasErrors={errors && !metadataKey}
            />
            <div key="keyspacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              key="metadataValue"
              label="Value"
              className="textInput"
              full
              value={metadataValue}
              onChange={e => setMetadataValue(e.target.value)}
              hasErrors={errors && !metadataValue}
            />
            <div key="valuespacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              key="metadataCategory"
              label="Category"
              className="textInput"
              full
              value={metadataCategory}
              onChange={e => setMetadataCategory(e.target.value)}
              hasErrors={errors && !metadataCategory}
            />
            <div key="categoryspacer" style={{ marginBottom: "30px" }}></div>
            <div style={{ margin: "0px 0px 3px 10px" }}>
              <Tooltip
                text="Whether non-owners can view this metadata"
                inverse={true}
              >
                Visible&nbsp;<Icon type="walkthrough" size="small"></Icon>
              </Tooltip>
            </div>
            <ToggleSwitch
              options={boolean}
              value={metadataVisible}
              name="visible"
              onChange={e => setMetadataVisible(e)}
            />
          </div>
          <div
            key="submit"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              disabled={updateSpinner}
              onClick={() => createNewMetadata()}
            >
              Submit
            </Button>
          </div>
          <div key="spacerBottom" style={{ margin: "30px" }}></div>
          {updateSpinner ? (
            <div>
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <Modal
        onClose={() => setMoveModal(false)}
        closeButton
        title=""
        show={moveModal}
      >
        <div>
          <div key="inputs" style={{ marginBottom: "30px" }}>
            <TextInput
              key="metadataKey"
              label="Key"
              className="textInput"
              full
              value={metadataKey}
              disabled
            />
            <div key="keyspacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              key="metadataValue"
              label="Value"
              className="textInput"
              full
              value={metadataValue}
              disabled
            />
            <div key="valuespacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              key="metadataCategory"
              label="Category"
              className="textInput"
              full
              value={metadataCategory}
              disabled
            />
            <div key="categoryspacer" style={{ marginBottom: "30px" }}></div>
            <TextInput
              key="metadataVisible"
              label="Visible"
              className="textInput"
              full
              value={metadataVisible}
              disabled
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              fontSize: "16px"
            }}
          >
            Move from Metadata to Tag Set?
          </div>
          <div
            key="submit"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button disabled={updateSpinner} onClick={() => moveMetadata()}>
              Submit
            </Button>
          </div>
          <div key="spacerBottom" style={{ margin: "30px" }}></div>
          {updateSpinner ? (
            <div>
              <Spinner />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      {formStatus}
      {addBtn}
      {tbl}
    </Section>
  );
};
