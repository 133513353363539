import React, { useState, useEffect } from "react";
import config from "../config.js";
import { Section, Container, Spinner } from "@nike/epic-react-ui";
import { formatdata } from "./SystemData.js";
import APIError from "../components/APIError.js";
import { ResourceTagTable } from "./SystemResourceTagTable.js";

export const ResourceTags = props => {
  const [tagHistory, setTagHistory] = useState(null);
  const [errorMessageTags, setErrorMessageTags] = useState(null);
  const [errorMessageHistory, setErrorMessageHistory] = useState(null);
  const [resAccount, setResAccount] = useState(null);

  const formattaghistory = taghistory => {
    for (var i in taghistory) {
      if (i > 0) {
        const oldTags = taghistory[i - 1].metadata.Tags;
        const newTags = taghistory[i].metadata.Tags;
        const deltas = [];
        for (var j in newTags) {
          if (!oldTags[j]) deltas.push(j);
          else if (oldTags[j] !== newTags[j]) deltas.push(j);
        }
        taghistory[i]["tag_deltas"] = deltas;
      }
    }

    return taghistory.reverse().map(data => (
      <Container key={data.sort}>
        {data.sort}
        <hr />
        {formatdata(data.metadata.Tags, data.tag_deltas)}
      </Container>
    ));
  };

  useEffect(() => {
    fetch(config.apiUrl + "/tag-history/history", {
      // fetch("http://127.0.0.1:8000/history", {
      headers: {
        Authorization: "Bearer " + props.accessToken,
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        id: props.resource.ID
      })
    })
      .then(response => response.json())
      .then(response => {
        if (!response.records) {
          console.log(response);
          if (response.code === "records_not_found") {
            setTagHistory(
              <div>No tagging history is available for this resource.</div>
            );
          } else {
            setErrorMessageHistory(response);
          }
        } else {
          // console.log(response);
          setTagHistory(formattaghistory(response.records));
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessageHistory(error);
      });
  }, [props.resource.ID, props.accessToken]);

  useEffect(() => {
    if ("ACCOUNT" in props.resource && !props.system.type.includes("azure")) {
      fetch(config.apiUrl + "/ssot/systems/account/" + props.resource.ACCOUNT, {
        // fetch("http://127.0.0.1:8000/account/" + props.resource.ACCOUNT, {
        headers: {
          Authorization: "Bearer " + props.accessToken
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.status === "fail") {
            setErrorMessageTags(response);
          } else if (!response.accountId) {
            throw Error("Failure in systems API");
          } else {
            setResAccount(response);
          }
        })
        .catch(error => {
          setErrorMessageTags(error);
          console.log(error);
        });
    } else {
      setResAccount({
        tagging: {
          accountTagging: {
            enabled: false,
            tagOverwrite: false
          },
          tagGuid: null
        }
      });
    }
  }, [props.resource, props.resource.ACCOUNT, props.accessToken]);

  return (
    <div>
      {!errorMessageTags ? (
        resAccount ? (
          <ResourceTagTable resAccount={resAccount} {...props} />
        ) : (
          <Spinner />
        )
      ) : (
        <APIError showTitle={true} error={errorMessageTags} />
      )}
      {!errorMessageHistory ? (
        tagHistory ? (
          <Section style={{ padding: "0px" }} collapsible title="History">
            {tagHistory}
          </Section>
        ) : (
          <Spinner />
        )
      ) : (
        <Section style={{ padding: "0px" }} collapsible title="History">
          <APIError showTitle={true} error={errorMessageHistory} />
        </Section>
      )}
    </div>
  );
};
