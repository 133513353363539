import React, { Fragment } from "react";

const Clouds = () => (
  <Fragment>
    <TopClouds />
    <BottomClouds />
  </Fragment>
);

const BottomClouds = () => (
  <span className="bottom-clouds">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="685"
      height="228"
      viewBox="0 0 685 228"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FEFEFE"
          fillOpacity=".26"
          d="M675.661 99.263c-21.995 37.572-8.32 68.1-8.32 68.1s-16.646-6.26-33.883 0c-17.241 6.265-27.346 19.57-27.346 19.57s-17.833-31.312-55.878-20.35c-38.041 10.957-45.174 41.485-45.174 41.485s-34.479-50.098-82.032-31.312c-30.655 12.11-45.748 35.278-52.605 50.216l26.082.028H684V88c-3.07 3.201-5.663 6.691-8.339 11.263zm-397.47 127.583c-9.27-10.183-23.194-19.959-41.987-18.743-12.19.788-20.86 8.623-27.013 18.897l69-.154z"
        />
        <path
          fill="#FEFEFE"
          fillOpacity=".26"
          d="M26.527 227.11H684.09L684 34.547s-30.795-12.96-59.537 27.295c-28.743 40.255-10.874 72.962-10.874 72.962s-21.751-6.708-44.276 0c-22.53 6.712-35.734 20.967-35.734 20.967s-23.304-33.547-73.02-21.803c-49.71 11.74-59.032 44.447-59.032 44.447s-45.055-53.675-107.196-33.548c-62.146 20.128-75.35 68.77-75.35 68.77s-24.082-11.739-48.937-7.547c-24.856 4.192-46.608 15.096-46.608 15.096s-23.676-44.84-73.36-42.243C.395 181.54 2.975 227.11 2.975 227.11h23.553z"
        />
        <path
          stroke="#FFF"
          strokeWidth="4"
          d="M683.9 2.282c-14.9 0-43.27 1.497-62.054 32.406-27.95 45.995-10.574 83.366-10.574 83.366s-21.152-7.664-43.057 0c-21.909 7.67-34.749 23.958-34.749 23.958s-22.662-38.331-71.008-24.912c-48.341 13.413-57.406 50.784-57.406 50.784s-43.814-61.329-104.243-38.331c-60.434 22.997-73.274 78.577-73.274 78.577s-23.419-13.414-47.59-8.624c-24.17 4.789-45.323 17.248-45.323 17.248s-11.007-48.266-71.339-48.266C2.952 168.488 2.805 227 2.805 227"
        />
      </g>
    </svg>
  </span>
);

const TopClouds = () => (
  <span className="top-clouds">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="205"
      height="116"
      viewBox="0 0 205 116"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FEFEFE"
          fillOpacity=".26"
          d="M1 1C.958 25.667.958 38 1 38c.063 0 6.913-14.414 6.913-14.414s35.805 19.862 75.669-12.648C84.066 10.543 88.097 7.191 96 1 96.051.96 64.385.96 1 1z"
        />
        <path
          fill="#FEFEFE"
          fillOpacity=".26"
          d="M1 1v83c3-2 4.015-2.79 5.916-4.083C49.46 51.012 46.504 17.113 46.504 17.113s61.607 33.844 112.834-7.379c3.551-2.858 6.63-5.81 9.662-8.734H1z"
        />
        <path
          stroke="#FFF"
          strokeWidth="4"
          d="M1 114c3.553 0 11.386-4.333 17.147-8.15 41.815-27.7 38.877-66.068 38.877-66.068S105.01 86.45 167.99 44.059C187 31.265 202.038 7 202.038.974"
        />
      </g>
    </svg>
  </span>
);

const Swoosh = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="80"
    height="29"
    viewBox="0 0 80 29"
  >
    <defs>
      <path id="a" d="M0 .137h79.879v28.796H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <path
        fill="#FFFFFE"
        d="M47.276 9.25c-3.969 1.105-7.36 2.016-10.119 2.763-2.778.76-5.072 1.388-6.905 1.893-4.289 1.15-6.822 1.853-7.615 2.06-.36.091-.746.18-1.191.284-.444.103-.907.189-1.408.278-.481.095-.964.161-1.445.209a10.39 10.39 0 0 1-1.399.094c-1.067 0-2.125-.142-3.127-.397-.992-.277-1.89-.68-2.692-1.258a6.234 6.234 0 0 1-1.89-2.234c-.472-.93-.727-2.044-.727-3.333 0-1.154.237-2.48.699-4.01.454-1.536 1.218-3.364 2.287-5.462-.406.345-.973.92-1.72 1.695a47.382 47.382 0 0 0-2.42 2.793 58.162 58.162 0 0 0-2.653 3.553 40.295 40.295 0 0 0-2.447 4 27.111 27.111 0 0 0-1.814 4.17C.216 17.744 0 19.079 0 20.37c0 1.234.198 2.371.604 3.42a7.394 7.394 0 0 0 1.823 2.72c.813.78 1.824 1.366 3.024 1.796 1.22.44 2.627.657 4.243.657 1.114 0 2.239-.109 3.38-.297 1.135-.19 2.25-.426 3.289-.718a45.474 45.474 0 0 0 2.977-.96 56.507 56.507 0 0 0 2.456-.94c.689-.302 1.256-.549 1.662-.756.435-.188.653-.291.672-.291L79.879.354c-7.01 1.918-13.199 3.605-18.555 5.06-5.367 1.46-10.042 2.73-14.048 3.835"
        mask="url(#b)"
      />
    </g>
  </svg>
);

const Unicorn = () => (
  <span className="unicorn">
    <svg
      width="197px"
      height="212px"
      viewBox="0 0 197 212"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>unicorn</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="unicorn"
          transform="translate(-16.000000, -11.000000)"
          stroke="#FFFFFF"
        >
          <g
            id="g2388"
            transform="translate(127.027548, 126.980765) rotate(-14.999992) translate(-127.027548, -126.980765) translate(24.027548, 23.980765)"
          >
            <path
              d="M92.4041749,42.9501542 C95.1714734,44.5211342 96.5988959,45.613282 96.5988959,45.613282 C154.54909,170.534892 169.414679,171.787402 167.452265,181.048955 C165.489851,190.310479 167.798077,211.614124 118.9456,203.790516 C114.413558,199.444213 111.340186,193.612523 111.340186,193.612523 C91.2601136,178.903463 71.3979952,164.059084 45.7393192,152.813499 M7.62474638,37.9508417 C33.4939261,29.8800944 54.4272032,30.8840613 69.4747022,34.3087347"
              id="path2450"
              strokeWidth="4.00000023"
              strokeLinecap="round"
            ></path>
            <path
              d="M91.7304517,85.8694593 C87.2933454,86.1628442 78.1423623,89.1969779 83.6242955,94.2607774 C88.3489856,100.137977 99.9999976,103.46185 106.198998,98.0433924 C105.621583,91.8306378 99.0810235,85.0920529 91.7304517,85.8694593 Z"
              id="path3244"
              strokeWidth="2.00000011"
            ></path>
            <path
              d="M160.525822,169.171369 C160.525822,169.171369 156.760825,168.415584 153.672027,171.460639 C150.839698,174.252867 150.715006,181.979711 153.729667,183.673836 C156.744326,185.367961 158.865892,184.498408 160.585589,181.836894 C162.3053,179.175393 161.095349,170.730579 154.455571,174.033388"
              id="path3246"
              strokeWidth="2.00000011"
              strokeLinecap="round"
            ></path>
            <path
              d="M88.8353851,48.2231785 C104.978761,28.2127387 88.2160747,6.05872672 89.339671,0.00410809058 C77.4172874,4.91401528 66.2165854,32.8720159 69.5649935,46.8622024"
              id="path3268"
              strokeWidth="4.00000023"
              strokeLinecap="round"
            ></path>
            <path
              d="M111.806286,71.1672022 L117.691597,67.5023335 C117.691597,67.5023335 123.277771,66.2233054 125.085029,65.0526462 C126.892287,63.8819729 129.462218,60.1725686 129.462218,60.1725686 C129.462218,60.1725686 135.059643,58.7356687 136.855651,57.5672077 C138.651658,56.3987466 141.232839,52.8428174 141.232839,52.8428174 C141.232839,52.8428174 146.841726,51.2235591 148.626272,50.0581449 C150.410817,48.8927309 153.003446,45.5130662 153.003446,45.5130662 C153.003446,45.5130662 158.623612,43.6808684 160.396877,42.5196979 C162.170143,41.3585272 164.774066,38.1833012 164.774066,38.1833012 C164.774066,38.1833012 170.40464,36.0991239 172.167499,34.9439497 C173.930358,33.7887757 176.544688,30.85355 176.544688,30.85355 C176.544688,30.85355 182.183495,28.4664156 183.93812,27.3199234 C185.692745,26.1734171 188.315309,23.5237988 188.315309,23.5237988 C188.315309,23.5237988 193.957722,20.7654779 195.708742,19.6318829 C197.459762,18.498288 200.085931,16.1940338 200.085931,16.1940338 L205.97124,12.5291652 L199.703395,15.3253297 L193.435534,18.1214944 C193.435534,18.1214944 187.5311,20.2804127 185.659566,21.1447062 C183.788016,22.0089859 180.899827,23.7138374 180.899827,23.7138374 C180.899827,23.7138374 174.99648,25.4971725 173.123845,26.3787461 C171.251225,27.2603198 168.364121,29.3061666 168.364121,29.3061666 C168.364121,29.3061666 162.467031,30.7830386 160.588153,31.676814 C158.709259,32.5705754 155.828413,34.8984957 155.828413,34.8984957 C155.828413,34.8984957 149.940163,36.1205082 148.052431,37.0231462 C146.1647,37.9257842 143.292692,40.490825 143.292692,40.490825 C143.292692,40.490825 137.41447,41.4975048 135.516725,42.4067794 C133.618964,43.3160539 130.756986,46.083168 130.756986,46.083168 C130.756986,46.083168 124.8892,46.9054998 122.981018,47.8198247 C121.072821,48.7341637 118.221279,51.6754972 118.221279,51.6754972 C118.221279,51.6754972 112.363884,52.3382043 110.445296,53.2564668 C108.526724,54.174743 105.685573,57.2678264 105.685573,57.2678264"
              id="path3334"
              strokeWidth="4.00000023"
              strokeLinecap="round"
            ></path>
            <path
              d="M45.1896389,152.69119 C5.31736488,147.331264 0.906047515,119.997279 0.906047515,119.997279"
              id="path3340"
              strokeWidth="4.00000023"
              strokeLinecap="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default () => (
  <div className="hero">
    <Clouds className="clouds" />
    <Unicorn />
    <div className="message-area">
      <h1 className="hero-title">CloudRed</h1>
      <h3 className="hero-subtitle">Be Brief. Be Right. Be Unicorn.</h3>
      <p className="hero-swoosh">
        <Swoosh className="swoosh" />
      </p>
    </div>
  </div>
);
