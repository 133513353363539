import React from "react";
import config from "../config";
import HomePage from "../home/HomePage";
import IndexHome from "../home/IndexHome";
import UtilitiesHome from "../home/UtilitiesHome";
import SystemIndex from "../system/SystemIndex";
import CollectionIndex from "../systems_collection/CollectionIndex";
import SystemRegistration from "../system/SystemRegistration";
import SystemScreen from "../system/SystemScreen";
import CollectionRegistration from "../systems_collection/CollectionRegistration";
import CollectionScreen from "../systems_collection/CollectionScreen";
import CollectionDashboard from "../systems_collection/CollectionDashboard";
import CollectionDynamicDashboard from "../systems_collection/CollectionDynamicDashboard";
import ManagementScreen from "../management/ManagementScreen";
import TaggingCompliance from "../tagging_compliance/TaggingCompliance";
import TaggingComplianceDetails from "../tagging_compliance/TaggingComplianceDetails";
import TaggingRules from "../tagging_rules/TaggingRulesSummary";
import Search from "../search/SearchOutline";
import AccountIndex from "../account/AccountIndex";
import AccountScreen from "../account/AccountScreen";
import TaggingCheck from "../tagging_check/TaggingCheck";

export const routesNav = [
  {
    text: "Indexes",
    path: "/indexes",
    menuItems: [
      { path: "/system/index", text: "System Index" },
      { path: "/account/index", text: "Account Index" },
      { path: "/collection/index", text: "Systems Collection Index" },
      { path: "/system/register", text: "Register a new System" },
      { path: "/collection/register", text: "Register a new System Collection" }
    ]
  },
  { path: "/management/systems", text: "Systems Management" },
  { path: "/compliance/", text: "Tagging Compliance" },
  {
    text: "Utilities",
    path: "/utilities",
    menuItems: [
      { path: "/search/", text: "Search" },
      { path: "/check", text: "Tagging Check" }
    ]
  },
  { path: "/tagging/explorer", text: "Tagging Rules" },
  {
    path: "https://docs.platforms.nike.com/cloudred-gui/",
    text: "Docs",
    external: true
  },
  { path: config.appSlackUrl, external: true, text: "#cloudred-discussion" }
];

const routes = {
  "/": () => <HomePage />,
  "/indexes": () => <IndexHome />,
  "/system/index": () => <SystemIndex />,
  "/account/index": () => <AccountIndex />,
  "/account/:account": obj => <AccountScreen data={obj} />,
  "/collection/index": () => <CollectionIndex />,
  "/system/register": () => <SystemRegistration />,
  "/system/:key": obj => <SystemScreen data={obj} />,
  "/collection/register": () => <CollectionRegistration />,
  "/collection/:key": obj => <CollectionScreen data={obj} />,
  "/collection/dashboard/:key": obj => <CollectionDashboard data={obj} />,
  "/collection/dynamic/:key": obj => <CollectionDynamicDashboard data={obj} />,
  "/compliance": obj => <TaggingCompliance data={obj} />,
  "/compliance/:filter?": obj => <TaggingCompliance data={obj} />,
  "/compliance/:filter/:key": obj => <TaggingComplianceDetails data={obj} />,
  "/management/systems": () => <ManagementScreen />,
  "/tagging/explorer": () => <TaggingRules />,
  "/search/:term?": obj => <Search term={obj} />,
  "/utilities": () => <UtilitiesHome />,
  "/check": () => <TaggingCheck />
};

export default routes;
