import React, { useState } from "react";
import config from "../config.js";
import { Section, Button, Icon, Spinner, Modal } from "@nike/epic-react-ui";
import { navigate } from "raviger";

export const Settings = props => {
  const systemid = props.data.key;
  const [admin] = useState(props.system.is_admin);
  const [message, setMessage] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [systemState] = useState(props.system.state);
  const [isCollapsed] = useState(true);

  // set boolean value for use in selective rendering to see if system is editable
  var editable = false;
  if (admin && systemState === "active") {
    editable = true;
  }

  const handleClick = e => {
    setIsDeleting(true);
    e.preventDefault();
    fetch(config.apiUrl + "/ssot/systems/" + systemid, {
      headers: {
        Authorization: "Bearer " + props.accessToken
      },
      method: "DELETE"
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "fail") throw Error(response.message);
        else {
          setDeleteMessage(
            <div className="epic-color-success system-success-message">
              <Icon className="system-info-status" type="checkmark" />
              <div>System deleted successfully!</div>
            </div>
          );
          setTimeout(() => {
            navigate("/system/index");
          }, 3000);
        }
      })
      .catch(error => {
        setIsDeleting(false);
        setMessage(
          <div className="epic-color-error system-info-message">
            <Icon className="system-info-status" type="warning" />
            <div>{error.message}</div>
          </div>
        );
        setTimeout(() => {
          setMessage("");
        }, 6000);
        console.log(error);
      });
  };

  const deleteSystem = (
    <div style={{ textAlign: "center" }}>
      <Button
        onClick={() => setDeleteModal(true)}
        style={{ margin: "1em" }}
        theme={{ primary: "red", secondary: "white" }}
        disabled={!editable}
      >
        Delete System
      </Button>
    </div>
  );

  return (
    <div>
      {/* <PageSpinner show={isDeleting} overlay={true} /> */}
      <Section id={props.id} title="Settings" collapsible={isCollapsed}>
        {message}
        {deleteSystem}
        <Modal
          onClose={() => setDeleteModal(false)}
          closeButton
          title=""
          show={deleteModal}
        >
          <div style={{ textAlign: "Center", padding: "20px" }}>
            {deleteMessage}
            This will de-register the system from CloudRed and suspend services.
            No active resources will be affected by this. Press "Confirm" to
            continue.
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                onClick={e => handleClick(e)}
                style={{ margin: "1em" }}
                theme={{ primary: "red", secondary: "white" }}
                disabled={!isDeleting ? (admin ? false : true) : true}
              >
                Confirm
              </Button>
            </div>
            {isDeleting ? (
              <div>
                <Spinner />
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>
      </Section>
    </div>
  );
};
