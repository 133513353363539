const FIVE_MINUTES = 1000 * 60 * 5
const tokenBase = {
  get isExpired() {
    return this.expiresAt * 1000 < Date.now() + FIVE_MINUTES
  },
  get shouldRefresh() {
    return this.expiresAt * 1000 < Date.now() + FIVE_MINUTES
  }
}

export const makeToken = token => Object.assign(Object.create(tokenBase), token)

const base64UrlToBase64 = b64u => b64u.replace(/-/g, '+').replace(/_/g, '/')

const base64UrlToString = b64u => {
  let b64 = base64UrlToBase64(b64u)

  switch (b64.length % 4) {
    case 0:
      break
    case 2:
      b64 += '=='
      break
    case 3:
      b64 += '='
      break
    default:
      throw new Error('Not a valid Base64Url')
  }

  let utf8 = atob(b64)

  try {
    return decodeURIComponent(escape(utf8))
  } catch (error) {
    return utf8
  }
}

export const decodeToken = token => {
  if (!token || (!token.access_token && !token.id_token)) return null

  const accessValues = token.access_token ? token.access_token.split('.') : null
  const openIdValues = token.id_token ? token.id_token.split('.') : null

  try {
    if (accessValues && accessValues.length < 2) throw new Error('No payload found')
    if (openIdValues && openIdValues.length < 2) throw new Error('No payload found')

    const accessClaims = accessValues ? JSON.parse(base64UrlToString(accessValues[1])) : null
    const openIdClaims = openIdValues ? JSON.parse(base64UrlToString(openIdValues[1])) : null

    let decodedToken = {
      accessToken: token.access_token,
      openIdToken: token.id_token,
      expiresIn: token.expires_in
    }

    if (accessClaims) {
      decodedToken = Object.assign(decodedToken, {
        expiresAt: accessClaims.exp,
        username: accessClaims.sub,
        groups: accessClaims.groups || [],
        claims: accessClaims,
        scope: accessClaims.scp
      })
    }

    if (openIdClaims) {
      decodedToken = Object.assign(decodedToken, {
        email: openIdClaims.email,
        name: openIdClaims.name
      })

      if (!decodedToken.expiresAt) {
        decodedToken = Object.assign(decodedToken, {
          expiresAt: openIdClaims.exp
        })
      }

      if (!decodedToken.username) {
        decodedToken = Object.assign(decodedToken, {
          username: openIdClaims.sub
        })
      }
    }

    return makeToken(decodedToken)
  } catch (error) {
    throw new Error(`Malformed token. ${error.message}`)
  }
}
