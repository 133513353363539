export default class AuthEvents {
  constructor() {
    this._events = {}
  }

  on(eventName, fn) {
    if (!this._events[eventName]) this._events[eventName] = []
    this._events[eventName].push(fn)
  }

  emit(eventName, ...args) {
    if (this._events[eventName]) {
      this._events[eventName].forEach(fn => {
        fn(...args)
      })
    }
  }
}
